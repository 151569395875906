import styles from "./styles.module.scss";
import file from "../../../../assets/images/file_icon.svg";
import watchVideoIcon from "../../../../assets/images/watch_video_icon.svg";
import cn from "classnames";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import useHandleModal from "../../../../hooks/dom/useHandleModal";
import VideoPlayerModal from "../../../../components/Modals/VideoPlayerModal/VideoPlayerModal";
import { HTTPS_REGEX } from "../../../../constants/preLinks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
   returnTemporaryAnswerFileLinks,
} from "../../../../utils/questionsHelper";
import { isMobileDevice } from "../../../../utils/usersHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import ReactPlayer from "react-player";

function getFileNameFromURL(url) {
   if (!url?.length) return;

   const parts = url?.split("/");
   const filenameWithParams = parts[parts.length - 1];
   const filenameParts = filenameWithParams.split("?");
   return filenameParts[0];
}

const getFileExtFromName = (name) => {
   if (!name) return ""

   const parts = name?.split(".");
   return parts[parts.length - 1] || "";
};

const FileAnswer = ({ questionId, fileLink, isVideo, courseId }) => {
   const playerModal = useHandleModal();
   const openVideoModal = () => playerModal.open();
   const userId = useSelector((state) => state?.user?.info?.id);
   const [fileUrls, setFileUrls] = useState([]);

   const t = useFindTranslationsByString();

   const getUpdatedUrlLink = async () => {
      const fileName = getFileNameFromURL(fileLink);
      const links = fileLink?.split(",");

      const updatedLinks = await returnTemporaryAnswerFileLinks(
         userId,
         links,
         courseId
      );

      setFileUrls(updatedLinks);
   };

   useEffect(() => {
      if (fileLink && userId) {
         getUpdatedUrlLink();
      }
   }, [fileLink, userId]);

   return (
      <div
         className={cn(
            styles.file_answer_wrapper,
            isVideo ? styles.video_file_wrapper : ""
         )}
      >
         {fileUrls?.length === 0 && !isVideo && (
            <div className={styles.no_file}>{t("No file was added!")}</div>
         )}

         {fileUrls?.map((item, index) => (
            <div
               className={cn(
                  styles.file_item,
                  isVideo ? styles.video_file_item : ""
               )}
               key={index}
            >
               <div
                  className={cn(
                     styles.file_title,
                     isVideo ? styles.video_title : ""
                  )}
               >
                  {item?.fileUrl && <img src={file} alt="" />}
                  <CustomTooltip
                     text={getFileNameFromURL(item?.fileUrl)}
                     limit={isMobileDevice() ? 20 : 35}
                     id={questionId}
                  />
                  .
                  <span className={styles.gray}>
                     {getFileExtFromName(getFileNameFromURL(item?.fileUrl))}
                  </span>
               </div>

               <div className={styles.f_wrapper}>
                  {isVideo ? (
                     item?.fileUrl ? (
                        <div
                           onClick={openVideoModal}
                           className={styles.open_file_btn}
                        >
                           <img src={watchVideoIcon} alt="Play icon" />
                           {t("Watch")}
                        </div>
                     ) : (
                        <div className={styles.no_file}>
                           {t("No video was added by an admin!")}
                        </div>
                     )
                  ) : HTTPS_REGEX.test(item?.fileUrl) ? (
                     <a
                        className={styles.open_file_btn}
                        target="_blank"
                        href={item?.fileUrl}
                     >
                        {t("Open file")}
                     </a>
                  ) : (
                     <div className={styles.no_file}>
                        {t("No file was added!")}
                     </div>
                  )}

                  <VideoPlayerModal
                     isOpen={playerModal.isActive}
                     onClose={playerModal.close}
                     url={item?.fileUrl}
                  />
               </div>
               <div className={styles.f_wrapper_mob}>
                  {isVideo ? (
                     item?.fileUrl ? (
                        <div className={styles.video}>
                           <ReactPlayer
                              url={item?.fileUrl}
                              controls
                              width="100%"
                              height="100%"
                           />
                        </div>
                     ) : (
                        <div className={styles.no_file}>
                           {t("No video was added by an admin!")}
                        </div>
                     )
                  ) : HTTPS_REGEX.test(item?.fileUrl) ? (
                     <a
                        className={styles.open_file_btn}
                        target="_blank"
                        href={item?.fileUrl}
                     >
                        {t("Open file")}
                     </a>
                  ) : (
                     <div className={styles.no_file}>
                        {t("No file was added!")}
                     </div>
                  )}
               </div>
            </div>
         ))}
      </div>
   );
};

export default FileAnswer;
