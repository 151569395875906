import { useEffect, useState } from "react";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import useHandleModal from "../dom/useHandleModal";
import CourseApi from "../../utils/api/CourseApi";
import FolderApi from "../../utils/api/FolderApi";
import { mapAgenciesWithNewLinks, returnTemporaryCompaniesLinks } from "../../utils/usersHelper";
import { useSelector } from "react-redux";

const useDuplicateAgencies = () => {
   const [currentCourseDuplicateModalOpened, setCurrentCourseDuplicateModalOpened] = useState({});
   const [currentFolderDuplicateModalOpened, setCurrentFolderDuplicateModalOpened] = useState({});
   const [selectedAgencies, setSelectedAgencies] = useState([]);
   const [isAgenciesDuplicateLoading, setIsAgenciesDuplicateLoading] = useState(false);
   const userId = useSelector((state) => state?.user?.info?.id);

   const duplicateModal = useHandleModal();
   const duplicateFolderModal = useHandleModal();

   const addAccessToTheCourse = async (courseId) => {
      await new CourseApi().addOneUserToCourse(
         userId,
         courseId,
         {
            status: 1,
         }
      );
   };

   // Course duplication
   const onDuplicateAgencies = async (courseId) => {
      try {
         setIsAgenciesDuplicateLoading(true);
          const duplicateRequests = selectedAgencies.map(agency =>
              new CourseApi().duplicateCourse(courseId ? courseId : currentCourseDuplicateModalOpened?.id, agency?.branches?.[0]?.id)
          );
          const responses = await Promise.allSettled(duplicateRequests);

          const allRequestsSuccessful = responses.every(response => response?.value?.success);
          if (allRequestsSuccessful) {
            const createdCourseId = responses[0]?.value?.success?.data?.course?.success?.data?.course?.id;

            if(createdCourseId) {
               await addAccessToTheCourse(createdCourseId)
            }

            toast(<ToastMsg text={responses[0]?.value?.success?.message} />);
            setSelectedAgencies([]);
            duplicateModal.close();
          } else {
            toast(<ToastMsg text={responses[0]?.value?.error?.message || "Something went wrong"} isError />);
          }
          
          setIsAgenciesDuplicateLoading(false);
      } catch (error) {
          console.error(error);
          setIsAgenciesDuplicateLoading(false);
      }
  };

   // Folders duplication
   const onDuplicateFolder = async () => {
      try {
         setIsAgenciesDuplicateLoading(true);
          const duplicateRequests = selectedAgencies.map(agency =>
              new FolderApi().duplicateFolder(currentFolderDuplicateModalOpened?.id, agency?.branches[0]?.id)
          );
  
          const responses = await Promise.allSettled(duplicateRequests);

          const allRequestsSuccessful = responses.every(response => response?.value?.success);
          if (allRequestsSuccessful) {            
            toast(<ToastMsg text={responses[0]?.value?.success?.message} />);
            setSelectedAgencies([]);
            duplicateFolderModal.close();
          } else {
            toast(<ToastMsg text={responses[0]?.value?.error?.message || "Something went wrong"} isError />);
          }
          
          setIsAgenciesDuplicateLoading(false);
      } catch (error) {
          console.error(error);
          setIsAgenciesDuplicateLoading(false);
      }
  };

  const onOpenDuplicateModal = (course) => {
      duplicateModal.open();
      setCurrentCourseDuplicateModalOpened(course)
   }

  const onOpenDuplicateFolderModal = (folder) => {
      duplicateFolderModal.open();
      setCurrentFolderDuplicateModalOpened(folder)
   }

   return {
      onDuplicateAgencies,
      onDuplicateFolder,
      onOpenDuplicateFolderModal,
      duplicateFolderModal,
      onOpenDuplicateModal,
      duplicateModal,
      selectedAgencies,
      setSelectedAgencies,
      isAgenciesDuplicateLoading,
      setIsAgenciesDuplicateLoading
   };
};

export default useDuplicateAgencies;
