import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import cross from "../../assets/images/symbols/cross_grey.svg";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

const SearchInput = ({ placeholder, searchedValue, setSearchedValue, withBorder = false }) => {
   const handleClearSearch = () => {
      setSearchedValue("");
   };

   const t = useFindTranslationsByString();

   return (
      <div className={cn(styles.search_wrapper, withBorder && styles.withBorder)}>
         <div className={styles.search}>
            <input
               placeholder={placeholder || t("Search")}
               value={searchedValue}
               onChange={(e) => setSearchedValue(e.target.value)}
            />
            {searchedValue?.length > 0 && (
               <button className={styles.clear_button} onClick={handleClearSearch}>
                  <img src={cross} alt="Clear" />
               </button>
            )}
         </div>
      </div>
   );
};

export default SearchInput;
