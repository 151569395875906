import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import RightsSubheader from "./RightsSubheader/RightsSubheader";
import styles from "./styles.module.scss";
import RolesBlock from "./RolesBlock/RolesBlock";
import SettingItems from "./SettingItems/SettingItems";
import Roles from "../../utils/api/Roles";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import { ROLE_ID_GUEST, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, pageStaticPermissionsInfo, allCustomPermissions } from "../../constants/user";
import { useSelector } from "react-redux";
import { ROLE_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";

const UsersRights = () => {
   const [roles, setRoles] = useState([]);
   const [roleLoading, setRoleLoading] = useState(false);
   const [activeRole, setActiveRole] = useState(null);
   const [inputsData, setInputsData] = useState({});

   const [isUpdateLoading, setIsUpdateLoading] = useState(false);

   const companyId = useSelector(state => state.sidebar.companyId);
   const companyBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);
   const userId = useSelector((state) => state?.user?.info?.id);

   const [updatedProgress, setUpdatedProgress] = useState(0);
   
   const getRoles = async () => {
      setRoleLoading(true);
      const res = await new Roles().getRoles(companyId, ROLE_EXTRA_GROUP_ONE);

      if (res?.success?.data) {
         const excludedRoleIds = [ROLE_ID_GUEST, ROLE_ID_STUDENT];
         const filteredRoles = res?.success?.data?.filter(role => !excludedRoleIds?.includes(role?.id));

         setRoles(filteredRoles);
      }
      setRoleLoading(false);
   };

   const chunkArray = (array, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const updateRolePermissionsInChunks = async () => {
      setIsUpdateLoading(true);
    
      const permissionsData = generatePermissionData();
      const permissionChunks = chunkArray(permissionsData, 20); 
      
      const allUpdatePromises = [];
      let i = -1;
    
      try {
        for (const chunk of permissionChunks) {
          i++;
    
          const data = {
            permission_data: chunk,
          };
    
          const newProgress = ((i + 1) / permissionChunks.length) * 100;

          if(permissionChunks?.length > 1) {
            setUpdatedProgress(newProgress.toFixed());
          }

          try {
            const res = await new Roles().updateRolePermissions(activeRole.id, data);
    
            if (res?.success?.message && newProgress === 100) {
              toast(<ToastMsg text={res?.success?.message} />);
            } else if (res?.error?.message) {
              toast(<ToastMsg text={res?.error?.message} isError />);
            }
    
    
            allUpdatePromises.push(res);

            setInputsData((prevData) => {
               const updatedData = {};
               Object.keys(prevData).forEach((key) => {
                 updatedData[key] = {
                   ...prevData[key],
                   status: 'old', 
                 };
               });
               return updatedData;
             });
          } catch (error) {
            console.error(`Error updating permissions in chunk: ${error}`);
          }
        }
    
        await Promise.all(allUpdatePromises);
        getRoles();
      } catch (error) {
        console.error(`Error updating role permissions: ${error}`);
      } finally {
        setIsUpdateLoading(false); 
        setUpdatedProgress(0);
      }
    };
    
   const onSave = () => {
      updateRolePermissionsInChunks();
   };

   const handleRoleClick = (role) => {
      setActiveRole(role);
   };

   useEffect(() => {
      if(companyBranchId) {
         getRoles();
      }
   }, [companyBranchId, userId]);

   useEffect(() => {
      if (activeRole) {
          const updatedInputsData = { ...inputsData };
  
          const permissionInfo = activeRole?.essential ? pageStaticPermissionsInfo : allCustomPermissions;
  
          activeRole.permissions.forEach(permission => {
              permissionInfo.forEach(staticPermission => {
                  if (staticPermission.permission_ids.includes(permission.id)) {
                      updatedInputsData[staticPermission.label] = {
                          value: true,
                          status: 'old', 
                      };
                  }
              });
          });
  
          // Set false for permissions not included in the activeRole
          permissionInfo.forEach(staticPermission => {
              if (!activeRole.permissions.some(permission => staticPermission.permission_ids.includes(permission.id))) {
                  updatedInputsData[staticPermission.label] = {
                      value: false,
                      status: 'old', 
                  };
              }
          });
  
          setInputsData(updatedInputsData);
      }
  }, [activeRole]);
  
  const generatePermissionData = (isCopy = false) => {
   const permissionDataMap = new Map(); 
   const permissionInfo = activeRole?.essential ? pageStaticPermissionsInfo : allCustomPermissions;

   // Loop through inputsData to generate the required permission data
   Object.keys(inputsData).forEach(label => {
     const inputValue = inputsData[label];

     // Check if the status is "new" and handle the corresponding add/remove logic
     if (inputValue?.status === 'new' && !isCopy) {
       const isNewPermission = inputValue?.value === true;

       // Find the corresponding permission info based on the label
       const permission = permissionInfo.find(p => p.label === label);

       if (permission) {
         permission.permission_ids.forEach(id => {
           permissionDataMap.set(id, { permission_id: id, add: isNewPermission });
         });
       }
     }
     
     // include all permissions - not only new one
     if(isCopy) {
         const permission = permissionInfo.find(p => p.label === label);
         const isNewPermission = inputValue?.value === true;

         if (permission) {
         permission.permission_ids.forEach(id => {
            permissionDataMap.set(id, { permission_id: id, add: isNewPermission });
         });
         }
      }
   });

   // Convert Map back to an array for the final result
   return Array.from(permissionDataMap.values());
};

   return (
      <MainLayout>
          {roleLoading && 
            <div className="default_loader_wrapper">
               <SecondLoader />
            </div>
         }
         <div className={styles.users_rights}>
            <div className={styles.roles_block}>
               <RolesBlock
                  roles={roles}
                  getRoles={getRoles}
                  activeRole={activeRole}
                  handleRoleClick={handleRoleClick}
                  generatePermissionData={generatePermissionData}
               />
            </div>
            <div className={styles.content}>
               <RightsSubheader 
                  isUpdateLoading={isUpdateLoading}
                  updatedProgress={updatedProgress}
                  onSave={onSave}
                  inputsData={inputsData}
               />
               {activeRole && 
                  <SettingItems
                     inputsData={inputsData}
                     setInputsData={setInputsData}
                     activeRole={activeRole}
                  />
               }
            </div>
         </div>
      </MainLayout>
   );
};

export default UsersRights;
