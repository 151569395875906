import React, {useEffect, useRef, useState} from 'react';
import styles from './styles.module.scss'
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import ToastMsg from '../../../components/ToastMsg/ToastMsg';
import LessonApi from '../../../utils/api/LessonApi';
import Loader from '../../../components/Loader/Loader';
import avatar from "../../../assets/images/avatar.svg";
import saveIcon from "../../../assets/images/icon_save.svg";
import versionIcon from "../../../assets/images/versions_icon.svg";
import icon_hide_show_menu from "../../../assets/images/icon_hide_show_menu.svg";
import upload_icon from "../../../assets/images/upload.svg";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import moment from 'moment';
import axios from 'axios';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';
import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import cn from 'classnames';
import { MAX_VIDEO_SIZE, MAX_UPLOADING_FILES_AT_ONCE, LOADING_TEXT, CLIENT_CLOSED_REQUEST_CODE,
    INITIAL_EDITOR_TEXT } from '../../../constants/courses';
import { addAllowFullScreenToIframes, decodeSanitizedContent, encodeSanitizedContent, renderPDF, replacePastedInEditorLinks, returnUpdatedContent } from '../../../utils/coursesHelper';
import Button from '../../../components/Button/Button';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useHandleModal from "../../../hooks/dom/useHandleModal";
import UploadEditorFilesModal from '../../../components/Modals/UploadEditorFilesModal/UploadEditorFilesModal';
import { formatFileSize } from "../../../utils/coursesHelper";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useDragging from '../../../hooks/useDragging';
import FilePlaceholderImg from '../../../components/FilePlaceholderImg/FilePlaceholderImg';

import { useDispatch } from 'react-redux';
import { setConfirmModalNeeded } from '../../../store/actions/sidebar';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const REACT_APP_TINYMCE_API_KEYS = ["28ivzel5cci1pspyjkf1e9b3crv98huv6cwv8lz9fnnykyrv","qgfs46n6u0f22cllzcap4mk9qpnds4xrez9h1lhhkjc8l2oa","a9kh29evleparz3snt95p33o17r0o9vluqlgdfybu8cow7x8","0lrj3gnbq041w3tqerluzsh6ncp84u969rzvtak64c2cehrz","kgv8fl22iwvg34gt9fqputmuhwvvupbcc03h6j26au5lkhww","sobypzocerqf2ezlhej3skll487925cdvck058d80flmt2ds","brpepsup0jc8xul68jsv0kr43thtjrn4yk3vbpfs897a51nr"]

const TinymceBlock = ({ toggleLessonsSidebar, isLessonsSidebarOpen, onClickElement, activeElement, fetchModulesData, isVersionsOpen, setIsVersionsOpen, changedLessonFlag, setChangedLessonFlag, isSaved, setIsSaved, editLanguage, defaultCourseLanguage, course }) => {
    const editorRef = useRef(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [currentDefaultLesson, setCurrentDefaultLesson] = useState(null);
    const [initialContent, setInitialContent] = useState(INITIAL_EDITOR_TEXT);
    const [isLessonLoading, setIsLessonLoading] = useState(true);

    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [showMaxFilesMessage, setShowMaxFilesMessage] = useState(true);
    const [lessonWasReceived, setLessonWasReceived] = useState(false);
    const [selectedVersionContent, setSelectedVersionContent] = useState('');
    const [currentVersionContent, setCurrentVersionContent] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [renameInput, setRenameInput] = useState(activeElement?.name || '');
    const [isRename, setIsRename] = useState(false);
    const lessonNameInputRef = useRef(null);
    const [versions, setVersions] = useState([]);
    const [uploadingFilesData, setUploadingFilesData] = useState([]);

    useEffect(()=> {
      setRenameInput(activeElement?.name)
    }, [activeElement])

    const t = useFindTranslationsByString();

    const dispatch = useDispatch();
    const params = useParams();
    const userId = useSelector(state => state?.user?.info?.id);
    const chosenLanguage = useSelector(state => state?.sidebar?.chosenLanguage);

    const uploadModal = useHandleModal();
    const confirmStopModal = useHandleModal();

    const renameLesson = () => {
      setIsRename(true);
      lessonNameInputRef?.current?.focus();
      onCloseLessonOptions();
   }

   const updateLessonName = async () => {
      if(renameInput.length === 0) return;
      if(renameInput === activeElement?.name) return;

      const res = await new LessonApi().updateLesson(currentLesson?.id, {
            new_name: renameInput.trim(),
            new_language: editLanguage?.code
      });

      if(res?.success?.message) {
         toast(<ToastMsg text={`${res?.success?.message} (${editLanguage?.name} version)`} />);
         onClickElement(res?.success?.data?.lesson)
         fetchModulesData(editLanguage?.code);
      }

      if(res?.error?.message) {
         setRenameInput('');
         return toast(<ToastMsg text={`${res?.error?.message} ${editLanguage?.name} version`} isError/>);
      }
   }

   const showRenameInput = () => {
      setIsRename(true);
   }

   const onFocusOutName = () => {
      setIsRename(false);
      updateLessonName();
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setIsRename(false);
        updateLessonName();
      }
    };

    const onOpenVersions = () => {
      if(editorRef?.current) {
         setCurrentVersionContent(editorRef.current.getContent())
      }
      if(editorRef.current) editorRef.current.mode.set("readonly");
      setIsVersionsOpen(true);
    }

    // Content before you click on version btn enev not saved
    const setCurrentVersion = () => {
      setActiveIndex(null);
      if(editorRef.current) {
         editorRef.current.setContent(currentVersionContent);
       }
    }

    const onCloseVersions = () => {
       if(editorRef.current) editorRef.current.mode.set('design');
      setCurrentVersion();
      setIsVersionsOpen(false);
    }

    const renderUploadedVideosInEditor = (src) => {
      const videoHtml = `<video class="amazon_added_file" src="${src}" volume="0.3" controls></video>`;
      editorRef.current.execCommand('mceInsertContent', false, videoHtml);
    }

    const renderUploadedImageInEditor = (src) => {
      const imgHtml = `<img class="amazon_added_file" style="max-width: 100%; max-height: 100%; object-fit: cover;" src="${src}"/>`;
      editorRef.current.execCommand('mceInsertContent', false, imgHtml);
    }

    const renderUploadedAudioInEditor = (src) => {
      const audioHtml = `<audio controls style="max-width: 100%;">
                          <source src="${src}" type="audio/mpeg">
                          Your browser does not support the audio element.
                        </audio>`;
      editorRef.current.execCommand('mceInsertContent', false, audioHtml);
    };

    const renderUploadedFile = (src) => {
      const fileLink = `<a href="${src}" style="color: white;">Link to file</a>`;

      editorRef.current.execCommand('mceInsertContent', false, fileLink);
    };

    const onDisplayVersion = async (version, idx) => {
      setActiveIndex(idx);
      if(editorRef.current) {
            const decodedContent = decodeSanitizedContent(version.lessonData?.content);
            const displayedContent = addAllowFullScreenToIframes(decodedContent);
            let changedContent = await returnUpdatedContent(displayedContent, userId, params?.id);

            editorRef.current.setContent(changedContent || displayedContent);
            setSelectedVersionContent(changedContent)
         }
    }

    const onRestoreVersion = () => {
      if(editorRef.current) editorRef.current.mode.set('design');
      setIsVersionsOpen(false);
      setActiveIndex(null);
    }

    const fetchLesson = async () => {
      try {
        setLessonWasReceived(false);
        setIsLessonLoading(true);

        const res = await new LessonApi().getOneLesson(activeElement.id, true, null, editLanguage?.code);
        if (res?.success?.data) {
          const decodedContent = decodeSanitizedContent(res?.success?.data?.content);
          const displayedContent = addAllowFullScreenToIframes(decodedContent);

           const changedContent = await returnUpdatedContent(displayedContent, userId, params?.id);

           setCurrentLesson( {
            ...res?.success?.data,
            content: changedContent || displayedContent
           });

           setLessonWasReceived(true);
        }
      } catch (error) {
        console.log('Error occurred during API request', error);
      } finally {
         setIsLessonLoading(false);
      }
    };

    const fetchDefaultLesson = async () => {
      try {
        setIsLessonLoading(true);
        const res = await new LessonApi().getOneLesson(activeElement.id, true, null, defaultCourseLanguage);
        if (res?.success?.data) {
          const decodedContent = decodeSanitizedContent(res?.success?.data?.content);
          const displayedContent = addAllowFullScreenToIframes(decodedContent);

           const changedContent = await returnUpdatedContent(displayedContent, userId, params?.id);

           setCurrentDefaultLesson( {
            ...res?.success?.data,
            content: changedContent || displayedContent
           });
        }
      } catch (error) {
        console.log('Error occurred during API request', error);
      } 
    };

    const removeDataSheetsValue = (html) => {
      const cleanedHtml = html.replace(/data-sheets-value="[^"]*"/g, '');
      return cleanedHtml;
    };

    const updateLesson = async () => {
      if (!activeElement || !activeElement.id) {
        console.error('Invalid active element');
        return;
      }
      const content = removeDataSheetsValue(editorRef.current.getContent());
      const encodedContent = encodeSanitizedContent(content);
   
      setIsUpdateLoading(true);
      setChangedLessonFlag('still')

      const newContentObj = {
         new_content: encodedContent,
         new_language: editLanguage?.code
      }

      try {
        const res = await new LessonApi().updateLesson(activeElement.id, newContentObj, true);

        if(res.success) {
            const decodedContent = decodeSanitizedContent(res?.success?.data?.lesson?.content);
            const displayedContent = addAllowFullScreenToIframes(decodedContent);

            const changedContent = await returnUpdatedContent(displayedContent, userId, params?.id);

            setCurrentLesson({
               ...res?.success?.data?.lesson,
               content: changedContent
            });

            toast(<ToastMsg text={`${res?.success?.message} (${editLanguage?.name} version)`} />);
        } else {
            setChangedLessonFlag('changed');
        }

        if(res?.error?.message) {
            return toast(<ToastMsg text={res.error.message} isError />)
        }
      } catch (error) {
          console.log('Error occurred during API request', error);
          setChangedLessonFlag('changed');
      } finally {
         setIsUpdateLoading(false);
      }
    };

    const renderCurrentEditorContent = () => {
      if(currentLesson?.content && editorRef.current) {
         editorRef.current.setContent(currentLesson?.content);
      }  
    }

    const getLessonVersions = async () => {
      if(!currentLesson?.id) return;
      
      const res = await new LessonApi().getLessonVersions(currentLesson?.id);
      if(res?.success?.data) {
         setVersions(res.success.data?.previousLessons); 
      }
    }

   const transformedVersions = versions?.map(item => {
      const transformedLoggedAt = moment(item.loggedAt).format('DD.MM.YY HH:mm');
      return { ...item, loggedAt: transformedLoggedAt };
    });

    const stopUploadingFile = (fileToStop) => {
      setUploadingFilesData(prevData => prevData.filter(item => fileToStop?.title !== item?.title));
    };
    
   const handleAutoSubmit = async (file, remove) => {
      const filteredFiles = uploadingFilesData?.filter(item => item?.progress !== 100 && !item?.errorMsg);

      if(filteredFiles?.length > MAX_UPLOADING_FILES_AT_ONCE && showMaxFilesMessage) {
         setShowMaxFilesMessage(false);
         setUploadingFilesData(prevData => {
            return prevData?.filter(item => item?.title !== file?.name)
        });

        return toast(<ToastMsg text={`Maximum ${MAX_UPLOADING_FILES_AT_ONCE} files at once`} isError />);
      }

      if (file?.size > MAX_VIDEO_SIZE) {
        setUploadingFilesData(prevData => {
            const updatedData = prevData?.map(item => {
                if (item?.title === file?.name) {
                    return { ...item, errorMsg: 'File size exceeds maximum allowed size.' };
                }
                return item;
            });
            return updatedData;
        });
        return

      }

      if (!['image/', 'video/', 'application/', 'audio/', 'text/csv', 'text/plain'].some(type => file?.type?.startsWith(type))) {
         setUploadingFilesData(prevData => {
            const updatedData = prevData?.map(item => {
                if (item?.title === file?.name) {
                    return { ...item, errorMsg: 'Unsupported file type.' };
                }
                return item;
            });
            return updatedData;
        });

        return;
       }

      const dataToGetUrl = [{
                              lesson_id: activeElement?.id,
                              filename: file.name
                           }]
         
      const res = await new LessonApi().getPresignedUploadUrls(dataToGetUrl);

      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
       }

      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
      const amazonFileUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;

      const cancelTokenSource = axios.CancelToken.source();

      const resUpload = await axios({
         method: 'PUT',
         url: uploadUrl,
         headers: {
           "Content-Type": file?.type,
         },
         data: file,
         onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return; 
            
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

            setUploadingFilesData(prevData => {
               const updatedData = prevData.map(item => {
                  if(!prevData?.some(prevItem => prevItem.title === file?.name)) {
                     cancelTokenSource.cancel(CLIENT_CLOSED_REQUEST_CODE);
                  }

                   if (item.title === file.name) {
                       return { ...item, progress };
                   }
                   return item;
               });

               if(!prevData.length) {
                  cancelTokenSource.cancel(CLIENT_CLOSED_REQUEST_CODE);
               }

               return updatedData;
           });
          },
          cancelToken: cancelTokenSource.token 
       }).catch(err => {
         if(err?.message !== CLIENT_CLOSED_REQUEST_CODE) toast(<ToastMsg text={err?.message || 'Something went wrong'} isError />)
       });

       if(resUpload) {
         setUploadingFilesData(prevData => {
            const updatedData = prevData?.map(item => {
                if (item?.title === file?.name) {
                    return { ...item, uploadedFileLink: amazonFileUrl, progress: 100 };
                }
                return item;
            });
            return updatedData;
        });
         if (file.type.startsWith('image/')) {
            renderUploadedImageInEditor(amazonFileUrl);
          } else if (file.type.startsWith('video/')) {
            renderUploadedVideosInEditor(amazonFileUrl);
          } else if (file.type === 'application/pdf') {
           renderPDF(amazonFileUrl, editorRef, setIsUpdateLoading, activeElement, file);
          } else if (file.type.startsWith('audio/')) {
           renderUploadedAudioInEditor(amazonFileUrl);
          } else {
           renderUploadedFile(amazonFileUrl);
          }
       }
    }

    const handleChangeStatus = ({ meta, file, remove }, status, event) => {
      if(
         status === 'done' && 
         !uploadingFilesData?.find(item => item?.title === file?.name)
      ) {
         setUploadingFilesData([...uploadingFilesData, { title: file?.name, ext: file?.type, fileSize: file?.size, file }])
         handleAutoSubmit(file, remove);
      }
    }

      const mostUploadedCurrentFile = uploadingFilesData?.find(file => {
         return file.progress && file.progress !== 100 
      });

      const filteredFiles = uploadingFilesData?.filter(item => !item?.errorMsg && item.progress !== 100)

      useEffect(()=> {
         if(filteredFiles?.length) {
            dispatch(setConfirmModalNeeded(true));
         }  else {
            dispatch(setConfirmModalNeeded(false));
         }
      }, [uploadingFilesData])
  
    useEffect(() => {
      getLessonVersions();

      return ()=> {
         setVersions([]);
      }
    }, [currentLesson]);

    useEffect(() => {
      if (activeElement?.id) {
         fetchLesson();
         fetchDefaultLesson();
      }
    }, [activeElement?.id, editLanguage]);

    useEffect(() => {
      if (currentLesson?.content && editorRef.current) {
         renderCurrentEditorContent();
      }
    }, [currentLesson, editLanguage]);

    useEffect(()=> {
      if(isLessonLoading) {
         setInitialContent(LOADING_TEXT)
      } 
    }, [isLessonLoading])

    const openUploadModal = () => {
      uploadModal.open();
      //  Remove files that has bees already uploaded
      setUploadingFilesData(uploadingFilesData?.filter(item => item?.progress !== 100));
    }

    const handleDragOver = (e) => {
       e.preventDefault();
       e.stopPropagation(); 
       if(uploadModal.isActive) return;
       openUploadModal();
    };

    const { position, isDragging, handleMouseMove, handleMouseDown, handleMouseUp } = useDragging();

    let keyIdx = Math.floor(Math.random() * REACT_APP_TINYMCE_API_KEYS.length);
    let randomApiKey = REACT_APP_TINYMCE_API_KEYS[keyIdx];

    return (
        <div 
            className={styles.tinymceBlock} 
            style={{
               marginRight: `${isVersionsOpen ? '250px' : '0rem'}`
            }}
        >
            {isVersionsOpen ? (
               <> 
                  <div className={styles.versions_menu}>
                     <div className={styles.version_top}>
                        <span>{t("Version history")}</span>
                        <p className={styles.version_close} onClick={onCloseVersions}></p>
                     </div>
                     <div 
                        className={`${styles.current_version} ${activeIndex === null ? styles.active_version : ''}`}
                        onClick={setCurrentVersion}
                     >
                        <p>{t("Current version")}</p>

                        <div className={styles.user_info}>
                           <span className={styles.avatar_wrap}>
                                 <img className={styles.avatar_wrap_image} src={avatar} alt="" width="40" height="40"/>
                           </span>
                           <span>{t("User")}</span>
                        </div>
                     </div>
                     {transformedVersions.map((version, idx) => (
                        <div
                           key={idx}
                           className={`${styles.version} ${activeIndex === idx ? styles.active_version : ''}`}
                           onClick={() => onDisplayVersion(version, idx)}
                        >
                           <div className={styles.date}>
                             {version.loggedAt}
                           </div>
                           <div className={styles.user_info}>
                              <span className={styles.avatar_wrap}>
                                 <img className={styles.avatar_wrap_image} src={avatar} alt="" width="40" height="40"/>
                              </span>
                              <span>Super Admin</span>
                           </div>
                        </div>
                        ))}
                  </div>

                  <div className={styles.version_subheader}>
                     <span className={styles.version_subheader_left}>
                        <span className={styles.author}>Super Admin</span>
                        <span>{transformedVersions[activeIndex]?.loggedAt}</span>
                        <span className={styles.only_preview}>*{t("Preview")}</span>
                     </span>
                    
                     <div className={styles.restore_btn}>
                        <Button 
                           title={t('Restore')}
                           onClick={onRestoreVersion}
                           image={versionIcon}
                        />
                     </div>
                  </div>   
               </>
            ) : (
               <div className={styles.sub_header}>
                  <div className={styles.sub_header_left} onDoubleClick={showRenameInput}>
                  <button onClick={toggleLessonsSidebar} className={cn(
                        styles.toggle_sidebar, 
                     )}>
                           <img className={!isLessonsSidebarOpen ? styles.show_menu : ''} src={icon_hide_show_menu} alt="" /> 
                        </button>

                     <CustomTooltip id={activeElement.id} maxWidth={"500px"} position="bottom" isWidthMoreThanParent text={activeElement?.name} limit={58}/>

                     {isRename && <input 
                        key={currentLesson?.id}
                        type="text" 
                        className={styles.name_input}
                        ref={lessonNameInputRef} 
                        onBlur={onFocusOutName} 
                        autoFocus={true} 
                        onKeyDown={handleKeyDown}
                        value={renameInput}
                        onChange={(e)=> setRenameInput(e.target.value)}
                     />}
                  </div>
                  <div className={styles.sub_header_right}>
                     <Button
                        title={t('Save changes')}
                        onClick={updateLesson}
                        image={saveIcon}
                        disabled={changedLessonFlag !== 'changed'}
                        isBlack={changedLessonFlag !== 'changed'}
                     />

                     <Button 
                        title={t('Versions')}
                        onClick={onOpenVersions}
                        disabled={!!mostUploadedCurrentFile || isLessonLoading}
                        image={versionIcon}
                        isBlack
                     />
                  </div>
              </div>
            )}

         {isUpdateLoading && <div className='default_loader_wrapper'><Loader /></div>} 

            <div 
               className={cn(styles.editor_wrapper, isVersionsOpen ? 'version_open_editor_wrapper' : '')}
            >                  
               {!isVersionsOpen &&
                 <div
                     onClick={openUploadModal}
                     className={cn(styles.upload_modal_btn, 'noselect')}
                  >
                     <img src={upload_icon} alt="" />
                     <span>{t("Upload files")}</span>
                  </div>
                }

                  {mostUploadedCurrentFile && !uploadModal.isActive && 
                     <div
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{ left: `${position.x}px`, top: `${position.y}px` }}
                        className={cn(styles.upload_tooltip, 'noselect', isDragging ? styles.dragging : '')}
                     >
                       <div className={styles.upload_tooltip_inside}>
                       <div className={styles.file_placeholder_wrapper}>
                           <FilePlaceholderImg file={mostUploadedCurrentFile}/>
                        </div>
                        <div className={styles.uploading_name}>
                           <CustomTooltip 
                                 maxWidth={"250px"} 
                                 id={mostUploadedCurrentFile?.title}
                                 text={mostUploadedCurrentFile?.title || "-"}
                                 limit={18}
                              />
                        </div>
                           <div className={styles.file_size}>
                              {formatFileSize(mostUploadedCurrentFile?.fileSize)}
                           </div>
                           <img
                              className={styles.cross}
                              src={cross}
                              onClick={(e)=> {
                                 e.stopPropagation();
                                 confirmStopModal.open();
                              }}
                              alt="Cross icon"
                           ></img>
                           <ProgressLine dashIsNotNeeded progress={mostUploadedCurrentFile?.progress} />
                       </div>
                       <span className={styles.show_all} onClick={openUploadModal} >{t("Show all")}</span>
                     </div>
                  }
               <Editor
                  // apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  apiKey={randomApiKey}
                  onEditorChange={()=> {
                     const currContent = editorRef?.current?.getContent();
                     if(currContent === (`<p>${LOADING_TEXT}</p>`) && !isLessonLoading) {
                        // if lesson content was get before editor redered we reassign content
                        // this will prevent from missing content if editor was rendered after get content request.
                        renderCurrentEditorContent();
                     } else
                         if(currentLesson?.content && currentLesson?.content !== currContent && !isSaved && lessonWasReceived) {
                        setChangedLessonFlag('changed');
                     } else {
                        setChangedLessonFlag('still');
                     }
                  }}
                  onInit={(evt, editor) => {
                     editorRef.current = editor;
                     editor?.on('dragover', handleDragOver);

                     // Don't allow dragging images to prevent collisions with opening upload modal
                     editor.on('NodeChange', function() {
                        const images = editor.dom.select('img');
                        images?.forEach(function(img) {
                            img?.addEventListener('dragstart', function(e) {
                                // Prevent dragging of images
                                e.preventDefault();
                            });
                        });
                    });
                   }}
                  initialValue={initialContent}
                  init={{
                     setup: function(editor) {
                        editor?.on('paste', function(event) {
                            const pastedContent = event.clipboardData.getData('text/html') || event.clipboardData.getData('text/plain');
                            if(editorRef?.current) {
                              replacePastedInEditorLinks(pastedContent, editorRef);
                            }
            
                            event.preventDefault();
                        });
                    },
                     skin: "oxide-dark",
                     content_css: "dark",
                     language: chosenLanguage?.displayed_code,
                     paste_data_images: false, // dragging images and pasting directly to editor
                     menubar: true,
                     plugins: [
                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                           'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                           'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                     ],
                     toolbar: 'undo redo | blocks | ' +
                           'bold italic forecolor | alignleft aligncenter ' +
                           'alignright alignjustify | bullist numlist outdent indent | ' +
                           'removeformat | help', 
                     content_style: `
                           body { 
                             background-color: #212224;
                           }
                           code, pre {
                              background-color: rgb(109, 115, 123; 
                           }
                         `,
                  }}
               />
            </div>

            <UploadEditorFilesModal 
               isOpen={uploadModal.isActive}
               onClose={uploadModal.close}
               handleChangeStatus={handleChangeStatus}
               filesList={uploadingFilesData}
               maxWidth={'460px'}
               maxHeight={uploadingFilesData?.length > 0 ? '635px' :  '363px'}
               stopUploadingFile={stopUploadingFile}
            />

            <ConfirmModal
               confirmButtonText={t('Confirm')}
               onClose={confirmStopModal.close}
               isOpen={confirmStopModal.isActive}
               onConfirm={()=> {
                  stopUploadingFile(mostUploadedCurrentFile);
                  confirmStopModal.close();
               }}
               maxHeight={'300px'}
               title={t("Are you sure?")}
            />

        </div>
    );
};

export default TinymceBlock;