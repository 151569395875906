import React, {useEffect, useState} from 'react';
import {localStorageGet, localStorageRemove, localStorageSet} from "../../utils/localStorage";
import {useNavigate} from "react-router-dom";
import {pathList} from "../../routes/path";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import UserApi from "../../utils/api/UserApi";
import {useDispatch, useSelector} from "react-redux";
import {setInfo} from "../../store/actions/user";
import shortLogo from "../../assets/images/favicon.png";
import infoIcon from "../../assets/images/info_icon.svg";

import cn from 'classnames';
import styles from './styles.module.scss'
import { setCompanyInfo, setCompanyIcon, setCompanyName, setCompanyLanguages, setInfoLoading, setUserAsAdmin } from '../../store/actions/sidebar';
import {  PART_OF_MENTOR_NAME, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from '../../constants/user';
import { toast } from "react-toastify";
import ToastMsg from '../../components/ToastMsg/ToastMsg';
import InfoModal from "../../components/Modals/InfoModal/InfoModal";

import useUserRole from '../../hooks/useUserRole';
import BranchApi from '../../utils/api/BranchApi';
import { isMobileDevice, returnTemporaryCompanyLink, returnTemporaryLink } from '../../utils/usersHelper';
import useFindTranslationsByString from '../../hooks/useFindTranslationsByString';
import useHandleModal from '../../hooks/dom/useHandleModal';
import Roles from '../../utils/api/Roles';

const MainLayout = ({children, darkBg}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSideBarFixed = useSelector(state => state.sidebar.isFixed);
    const userInfo = useSelector(state => state.user.info);
    const currentCompanyId = useSelector(state => state.sidebar.companyId);
    const availableLanguages = useSelector((state) => state.sidebar.availableLanguages);
    const { userRoleId, userRoleCompany, userRoleName } = useUserRole(currentCompanyId);
  
    const [userDataError, setUserDataError] = useState(false);

    const t = useFindTranslationsByString();
    const mobileAvailableOnlyInStudentModeModal = useHandleModal();
 
    const returnFirstCompanyBranch = async (companyId) => {
        const res = await new BranchApi().getCompanyBranches(companyId);
        if (res?.success?.data) { 
         return res?.success?.data[0] || {id: 1}
        }
     }

     const changeCurrentCompany = async (company) => {
         const data = {
            new_current_company_id: company?.id
         }

         const res = await new UserApi().changeCurrentSuperAdminCompany(data);
     }

     const getRolePermission = async () => {

         const res = await new Roles().getYourPermissions();
         if(res?.success?.data) {
            return res?.success?.data;
         } else {
            return [];
         }
     }

    const fetchUserData = async () => {
      const res = await new UserApi().getYourUserData();

      if(!availableLanguages?.length) {
         dispatch(setInfoLoading(true));
      }
   
        if (res?.error?.message) {
            setUserDataError(true);
            return toast(<ToastMsg isError text={res.error.message || 'Something went wrong'} />);
        }

        if (res?.success?.data) {
         const restrictedAvatarLink = await returnTemporaryLink(res?.success?.data);

         const yourRole = res.success.data.userBranchRoles?.find(r => + r?.branch?.company?.id === + res.success.data?.currentCompany?.id)

         const rolesPermissionRes = await getRolePermission();

            dispatch(setInfo({
               ...res.success.data,
               avatar: restrictedAvatarLink?.[0]?.fileUrl || null,
               permissions: rolesPermissionRes[yourRole?.branch?.id] || []
            }));

            let company;
            let branch;

            if (res.success.data.currentCompany) {
               company = res.success.data.currentCompany;
               branch = await returnFirstCompanyBranch(company?.id);
            } else {
                changeCurrentCompany(res.success.data.userBranchRoles[0]?.branch?.company)
                company = res.success.data.userBranchRoles[0]?.branch?.company;
                branch = res.success.data.userBranchRoles[0]?.branch || { id: 1 };
            }

            const updatedCompanyIcon = await returnTemporaryCompanyLink(res?.success?.data?.id, company);

            const dataAlreadyExists = currentCompanyId && res?.success?.data?.currentCompany && +currentCompanyId === res?.success?.data?.currentCompany?.id;

            if(dataAlreadyExists) {
               if(updatedCompanyIcon) {
                  dispatch(setCompanyLanguages(company?.availableLanguages));
                  dispatch(setCompanyInfo(company.id, company.name, updatedCompanyIcon?.[0]?.fileUrl, branch?.id));
                  dispatch(setInfoLoading(false));
               }
               return;
            }

            const companyIcon = updatedCompanyIcon?.[0]?.fileUrl;
            const logo = company?.companyLogo?.iconLogo ? companyIcon : shortLogo;
            
            dispatch(setCompanyLanguages(company?.availableLanguages));
            dispatch(setCompanyInfo(company.id, company.name, logo, branch?.id));
        }

        dispatch(setInfoLoading(false));
    }

      const MOBILE_MODAL_SHOWN_KEY = 'mobileModalShown';

      useEffect(() => {
         if(userRoleId && userRoleId !== ROLE_ID_STUDENT) {
            const mobileModalShown = localStorage.getItem(MOBILE_MODAL_SHOWN_KEY);

            if (isMobileDevice() && !mobileModalShown) {
               mobileAvailableOnlyInStudentModeModal.open();
               dispatch(setUserAsAdmin(true));
               localStorage.setItem(MOBILE_MODAL_SHOWN_KEY, 'true');
            } else if (isMobileDevice() && mobileModalShown){
               dispatch(setUserAsAdmin(true));
            }
         }
      }, [userRoleId]);


    useEffect(() => {
        const token = localStorageGet("token", null);

        if (!!token) {
           fetchUserData();
        } else {
            localStorageRemove("token")

            if (![pathList.login.path, pathList.signup.path]?.some(item => location.pathname?.includes(item))) {
                navigate(pathList.login.path);
            }
        }
    }, [])

    const disableCopying = userRoleCompany?.companySecurity?.contentAndContextMenuDisabled  && (userRoleId === ROLE_ID_STUDENT 
                                   || !userRoleName?.includes(PART_OF_MENTOR_NAME))

    return (
        <div className={cn(styles.mainLayout, disableCopying ? "noselect" : "")}>
            <SideBar />
            <div className={cn(styles.content, isSideBarFixed && styles.sidebar_fixed)}>
                <Header/>

                <div className={cn(styles.children, darkBg && styles.darkBg)}>
                    {userDataError ? <div className={styles.error}>Something went wrong!</div> : children}
                </div>

                <InfoModal
                  customPadding={"24px"}
                  onClose={() => {
                     mobileAvailableOnlyInStudentModeModal.close();
                  }}
                  isOpen={mobileAvailableOnlyInStudentModeModal.isActive}
                  maxHeight={"290px"}
                  subtextColor={"#939495"}
                  maxWidth={"327px"}
                  title={t("Access as a student only")}
                  text={t('Platform administration on mobile devices is temporarily unavailable. You can use the platform only in student mode.')}
                  icon={infoIcon}
                  isBlackLayout
               />
                
            </div>

        </div>
    );
};

export default MainLayout;