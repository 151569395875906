import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";

import UserApi from "../../utils/api/UserApi";
import { useSelector } from "react-redux";
import { TABS_USER_PROFILE_ADMIN_VIEW, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, PART_OF_ADMIN_NAME, PART_OF_CURATOR_NAME, PART_OF_MENTOR_NAME } from "../../constants/user";
import { sortRoles } from '../../utils/usersHelper';
import { transformRoleText } from '../../utils/rolesHelper';

import cn from "classnames";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import ProfileLeftPanel from "./ProfileLeftPanel/ProfileLeftPanel";
import ProfileRight from "./ProfileRight/ProfileRight";
import NoAccessToThePage from "../../components/NoAccessToThePage/NoAccessToThePage";
import { returnTemporaryLink } from "../../utils/usersHelper";
import { USER_EXTRA_GROUP_TWO, USER_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";
import useGetCoursesOfUserOrTeam from "../../hooks/api/useGetCoursesOfUserOrTeam.js";
import useGetCoursesCount from "../../hooks/api/useGetCoursesCount.js";
import useIntersectionObserver from "../../hooks/useIntersectionObserver.js";
import useUserRole from "../../hooks/useUserRole.js";

const initialChartData = [
   ["Status", "percentage"],
   ["Completed", 0],
   ["Not started", 0],
   ["In progress", 0]
];

const UserProfileAdminView = () => {
   const params = useParams();
   const companyId = useSelector((state) => state.sidebar.companyId);
   const companyName = useSelector((state) => state.sidebar.companyName);
   const userInfo = useSelector((state) => state.user.info);

   const { coursePassingInfo } = useGetCoursesCount(companyId, params?.id)

   const [activeTab, setActiveTab] = useState(TABS_USER_PROFILE_ADMIN_VIEW[0]);
   const [chartData, setChartData] = useState(initialChartData);
   const [teamsTableData, setTeamsTableData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [coursesProgress, setCoursesProgress] = useState([]);
   const { userRoleId, userRoleName } = useUserRole(companyId)

   const [searchedValue, setSearchedValue] = useState();
   const [user, setUser] = useState([]);

   useEffect(()=> {
      setChartData([
         ["Status", "percentage"],
         ["Completed courses", coursePassingInfo?.completed],
         ["Not started courses",coursePassingInfo?.notStarted || 1],
         ["Ongoing courses", coursePassingInfo?.ongoing]
      ])
   }, [coursePassingInfo])

   const fetchUser = async () => {
      setIsLoading(true);
      
      const res = await new UserApi().getUser(params.id, USER_EXTRA_GROUP_TWO);
      if (res?.success?.data) {
         const avatarLink = await returnTemporaryLink(res?.success?.data);
         
         setUser({
            ...res?.success?.data,
            position: res?.success?.data?.positions?.map(item => item?.name)?.join(', '),
            department: res?.success?.data?.departments?.map(item => item?.name)?.join(', '),
            avatar: avatarLink?.[0]?.fileUrl
         });
      }

      setIsLoading(false);
   };

   const isTeams = false;
   const isExcludedCourses = true;
   const isYourAvailableCourses = true;

   const { courseList: allCoursesApartFromUsers, isCoursesLoading, refetchCourses, totalCourses, setCurrentPage, currentPage, clearAllCourseFilters, searchedValue: allCoursesSearchedValue, setSearchedValue: setAllCoursesSearchedValue, selectAll, setSelectAll } = useGetCoursesOfUserOrTeam(companyId, params?.id, isTeams, isExcludedCourses, isYourAvailableCourses);

   const lastCourseRef = useIntersectionObserver(
      () => {
        if (!isCoursesLoading && allCoursesApartFromUsers?.length < totalCourses) {
          if(allCoursesApartFromUsers?.length === 0) {
            setCurrentPage(1);
          } else {
            setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [isCoursesLoading, totalCourses]
    );

    const excludeUserCourses = false;

    const { courseList: allUsersCourses, isCoursesLoading: isUserCoursesLoading, refetchCourses: refetchUserCourses, totalCourses: totalUserCourses, setCurrentPage: setCurrentUserCoursePage, searchedValue: userCoursesSearchedValue, setSearchedValue: setUserCoursesSearchedValue, fetchCourses: getAllUsersCourses } = useGetCoursesOfUserOrTeam(companyId, params?.id, isTeams, excludeUserCourses, isYourAvailableCourses);

    const lastUserCourseRef = useIntersectionObserver(
       () => {
         if (!isUserCoursesLoading && allUsersCourses?.length < totalUserCourses) {
           if(allUsersCourses?.length === 0) {
            setCurrentUserCoursePage(1);
           } else {
            setCurrentUserCoursePage((prev) => prev + 1);
           }
         }
       },
       [isUserCoursesLoading, totalUserCourses]
     );

     const mapUserCourses = (course) => {
       // Needed for defining status on the course for each user. 
       const theCourse = user?.userCourses?.find(usersCourse => usersCourse?.course?.id === course?.id);
       const statusOnCourse = theCourse ? theCourse.status : 1; 
       const isAsStudent = theCourse ? theCourse.isAdminStudent : false; 

       const userRole = user?.userBranchRoles
         ?.filter(role => role?.branch?.company?.id === +companyId)
         ?.sort(sortRoles)?.[0];
         
      return {
         id: course?.id,
         folder: course?.folder || "-",
         name: course?.name || '-',
         was: "-",
         progress: course?.progress,
         status: statusOnCourse,
         image: course?.image,
         status_on_course: statusOnCourse,
         is_as_student: isAsStudent,
         role_id: userRole?.role?.id,
         role: isAsStudent ? 'As student' : transformRoleText(userRole?.role?.name, companyName)?.trim()
      };
   }

   const initTableDataTeams= () => {
      setTeamsTableData(
         user?.teams?.map((team) => {
            return {
               id: team?.id,
               team: team?.name || '-',
               department: team?.department || '-',
               position: team?.position || '-',
            };
         })
      );
   };

   const handleTabChange = (tab) => {
      setActiveTab(tab);
   };

   useEffect(() => {
      fetchUser();
   }, []);

   useEffect(() => {
      if(user?.teams?.length) {
         initTableDataTeams();
      }
   }, [user]);

   useEffect(() => {
      setSearchedValue("");
   }, [activeTab]);

   const getUserProgressOnCourse = async () => {
      if(!allUsersCourses?.length) return;

      const lastTenCourses = allUsersCourses.slice(-10);

      const data = {
         progress_data: lastTenCourses?.map(course => ({
             user_id: params?.id,
             course_id: course?.id
         }))
     };
     
      const res = await new UserApi().getUserProgressOnCourses(data);
      const courses = res?.success?.data?.filter(res => res?.success?.data)?.map(item => item?.success?.data);

      if(courses?.length) {
         setCoursesProgress([...coursesProgress, ...courses]);
      }
    }
   useEffect(()=> {
      if(allUsersCourses?.length && params?.id) {
         getUserProgressOnCourse();
      }
   }, [allUsersCourses, params])

   const updatedUserCourses = allUsersCourses?.map(mapUserCourses);

   const mappedUserCoursesList = updatedUserCourses?.map(course => {
      const matchingData = coursesProgress.find(data => data?.userCourseProgress?.course?.id === course?.id);
  
      if (matchingData) {
          return {
              ...course,
              progress: matchingData?.userCourseProgress?.progress || 0,
              result: matchingData?.userCourseResult?.result ? matchingData?.userCourseResult?.result : null
          };
      } else {
          return {
              ...course,
              progress: null,
              result: null
          };
      }
  });

  const isDefaultRoleWithExtraRights = (userRoleName?.includes(PART_OF_ADMIN_NAME) || userRoleName?.includes(PART_OF_CURATOR_NAME) || userRoleName?.includes(PART_OF_MENTOR_NAME));

  const hasPermissionToGetUser = userInfo?.permissions?.length > 1 && !isDefaultRoleWithExtraRights
                                 ? userInfo?.permissions?.some(p => p?.id === 468) : true;
  
  if(userRoleId && (userRoleId === ROLE_ID_STUDENT || !hasPermissionToGetUser)) {
    return <NoAccessToThePage />
  }
 
   return (
      <MainLayout darkBg>
         <main className={styles.profile_page}>
            <ProfileLeftPanel
               refetchUser={fetchUser}
               user={user}
               chartData={chartData}
            />
            <ProfileRight
               searchedValue={searchedValue}
               setSearchedValue={setSearchedValue}
               activeTab={activeTab}
               handleTabChange={handleTabChange}
               user={user}
               totalUserCourses={totalUserCourses}
               isLoading={isLoading}
               courseTableData={mappedUserCoursesList}
               fetchUser={fetchUser}
               teamsTableData={teamsTableData}
               lastCourseRef={lastCourseRef}
               allCoursesApartFromUsers={allCoursesApartFromUsers}
               refetchAllCoursesCourses={refetchCourses}
               allCoursesSearchedValue={allCoursesSearchedValue}
               setAllCoursesSearchedValue={setAllCoursesSearchedValue}
               isAllCoursesLoading={isCoursesLoading}
               selectAll={selectAll}
               setSelectAll={setSelectAll}
               userCoursesSearchedValue={userCoursesSearchedValue}
               setUserCoursesSearchedValue={setUserCoursesSearchedValue}
               lastUserCourseRef={lastUserCourseRef}
               refetchUserCourses={refetchUserCourses}
               getAllUsersCourses={getAllUsersCourses}
               isUserCoursesLoading={isUserCoursesLoading}
            />
         </main>
      </MainLayout>
   );
};

export default UserProfileAdminView;
