import moment from "moment";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import PieChart from "../../../../components/PieChart/PieChart";

import styles from "./styles.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobileDevice } from "../../../../utils/usersHelper";
import { parseIsoDurationStr } from "../../../../utils/coursesHelper";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";

import cross from "../../../../assets/images/symbols/cross_grey.svg";
import menuIcon from "../../../../assets/images/menu/list_icon.svg";

const LeftPanel = ({
   chartData,
   isTheory,
   setIsTheory,
   testData,
   isShowContent,
   setIsShowContent,
   prevLessonName
}) => {
   const formattedDate = testData
      ? moment(testData.completedAt).format("DD.MM.YY HH:mm")
      : null;
   const timeSpent = parseIsoDurationStr(testData?.timeSpent || "");

   const t = useFindTranslationsByString();
   const theCourse = testData?.lesson?.module?.course;
   const isSidebarOpen = useSelector((state) => state.sidebar.isOpenMobile);

   return (
      <div
         className={cn(
            styles.left_panel,
            isShowContent && isMobileDevice() ? styles.no_height : ""
         )}
      >
         <div
            onClick={() => {
               setIsShowContent(!isShowContent);
               setIsTheory(false);
            }}
            className={cn(
               styles.left_header_btn,
               !isShowContent ? styles.cross : ""
            )}
         >
            {isShowContent && 
               <button>Results</button>
            }
          

            {!isShowContent &&
               <img src={cross} alt="" />
            }
         </div>

         <div
            className={cn(
               styles.tab_header_title,
               isSidebarOpen ? styles.less_z_index : ""
            )}
         >
            {isTheory && testData?.lesson?.name && (
               <div>{prevLessonName}</div>
            )}
            {!isTheory && testData?.lesson?.name && (
               <CustomTooltip
                  text={testData?.lesson?.name || ""}
                  id={`${testData?.lesson?.id}_mob`}
                  maxWidth={"290px"}
                  limit={22}
               />
            )}
         </div>

         {(!isShowContent || !isMobileDevice()) && (
            <>
               <div className={styles.is_theory_btns}>
                  <button
                     className={cn(
                        styles.is_theory_btn,
                        !isTheory ? styles.active_theory : ""
                     )}
                     onClick={() => setIsTheory(false)}
                  >
                     {" "}
                     {t("On check")}
                  </button>
                  <button
                     className={cn(
                        styles.is_theory_btn,
                        isTheory ? styles.active_theory : ""
                     )}
                     onClick={() => {
                       if(isMobileDevice()) setIsShowContent(true);
                        setIsTheory(true);
                     }}
                  >
                     {" "}
                     {t("Theory")}
                  </button>
                  <div className={styles.active_indicator}>
                     {!isTheory ? t("On check") : t("Theory")}
                  </div>
               </div>
               <div className={styles.chart_container}>
                  <PieChart chartData={chartData} />
               </div>

               <div className={styles.mob_res_wrapper}>
                  <div className={styles.res_item}>
                     <div className={cn(styles.res_left, styles.res_left_true)}>
                        {t("True")}
                     </div>
                     <div
                        className={cn(styles.res_right, styles.res_right_true)}
                     >
                        {chartData[1][1] ? chartData[1][1]?.toFixed() : "0"}%
                     </div>
                  </div>
                  <div className={styles.res_item}>
                     <div
                        className={cn(styles.res_left, styles.res_left_partly)}
                     >
                        {t("Partially")}
                     </div>
                     <div
                        className={cn(
                           styles.res_right,
                           styles.res_right_partly
                        )}
                     >
                        {chartData[3][1] ? chartData[3][1]?.toFixed() : "0"}%
                     </div>
                  </div>
                  <div className={styles.res_item}>
                     <div
                        className={cn(styles.res_left, styles.res_left_false)}
                     >
                        {t("False")}
                     </div>
                     <div
                        className={cn(styles.res_right, styles.res_right_false)}
                     >
                        {chartData[2][1] ? chartData[2][1]?.toFixed() : "0"}%
                     </div>
                  </div>
               </div>

               <div className={styles.test_info}>
                  <div className={styles.test_info_item}>
                     <span className={styles.grey_text}>{t("Course")}:</span>
                     <Link
                        to={`/courses/course/${theCourse?.id}/default_lang/${theCourse?.defaultLanguage || "en_US"}/${theCourse?.availableLanguages?.length > 1}`}
                     >
                        <CustomTooltip
                           text={testData?.lesson?.module?.course?.name || ""}
                           id={testData?.id}
                           limit={22}
                        />
                     </Link>
                  </div>
                  <div className={styles.test_info_item}>
                     <span className={styles.grey_text}>{t("Task")}:</span>

                     <CustomTooltip
                        text={testData?.lesson?.name || ""}
                        id={testData?.lesson?.id}
                        maxWidth={"290px"}
                        limit={22}
                     />
                  </div>
                  <div className={styles.test_info_item}>
                     <span className={styles.grey_text}>{t("Attempt")}:</span>
                     <div> {testData?.passAttemptsCounters?.length || 1} </div>
                  </div>
                  <div className={styles.test_info_item}>
                     <span className={styles.grey_text}>{t("Run time")}:</span>
                     <div>{timeSpent}</div>
                  </div>
                  <div className={styles.test_info_item}>
                     <span className={styles.grey_text}>{t("Done")}:</span>
                     <div>{formattedDate || ""}</div>
                  </div>
               </div>
            </>
         )}
      </div>
   );
};

export default LeftPanel;
