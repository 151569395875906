import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ModalLayout from '../../../layouts/ModalLayout/ModalLayout';
import searchIcon from '../../../assets/images/symbols/Search.svg';
import avatarPlaceholder from '../../../assets/images/avatar.svg';
import notFound from '../../../assets/images/symbols/nothing_found.svg';
import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import { ACCESS_TABS, STUDENTS_TAB, TEAM_TAB } from '../../../constants/courses';
import { FixedSizeList as List } from 'react-window';

import cn from 'classnames';
import { transformRoleText } from '../../../utils/rolesHelper';
import { ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN } from '../../../constants/user';
import ChooseAdminAsStudentModal from '../ChooseAdminAsStudentModal/ChooseAdminAsStudentModal';
import useHandleModal from '../../../hooks/dom/useHandleModal';
import { useSelector } from 'react-redux';
import TabButton from '../../TabButton/TabButton';
import StepIndicator from '../../StepIndicator/StepIndicator';
import Loader from '../../Loader/Loader';
import useFindTranslations from '../../../hooks/useFindTranlsations';
import useGetDepartments from '../../../hooks/api/departmentsAndPostitions/useGetDepartments';
import useGetPositions from '../../../hooks/api/departmentsAndPostitions/useGetPositions';
import EmailsInput from '../../EmailsInput/EmailsInput';
import DropdownWithSearch from "../../DropdownWithSearch/DropdownWithSearch";

const SelectUsersAndTeamsModal = ({
  title,
  isAddAccess,
  isOpen,
  onClose,
  onConfirm,
  setSelectedUsers,
  selectedUsers,
  usersList,
  setSelectedTeamList,
  selectedTeamList,
  teamList,
  lastUserRef,
  searchedValue,
  setSearchedValue,
  isUsersLoading,
  selectAllUsers,
  setSelectAllUsers,
  selectAllTeams,
  setSelectAllTeams,
  lastTeamRef,
  teamSearchedValue,
  setTeamSearchedValue,
  teamsLoading,
  changeAdminAsStudentStatus,
  isCourseAccessPage,
  emails,
  setEmails,
  progress,
  onCancelRequests,
  selectedDepartment,
  setSelectedDepartment,
  selectedPosition,
  setSelectedPosition,
}) => {
  const [search, setSearch] = useState('');
  const [clickedId, setClickedId] = useState(null);
  const [selectedMode, setSelectedMode] = useState(STUDENTS_TAB);
  
  const currentCompanyId = useSelector(state => state.sidebar.companyId);
  const currentCompanyName = useSelector(state => state.sidebar.companyName);

  const t = useFindTranslations();

  const confirmModal = useHandleModal();

const toggleSelectAllUsers = () => {
   setSelectAllUsers(!selectAllUsers);
   if(selectAllUsers) setSelectedUsers([])
};

useEffect(()=> {
   if(isUsersLoading) return;

   if (selectAllUsers) {
      setSelectedUsers(usersList);
   } else {
      setSelectedUsers(selectedUsers);
   }
}, [selectAllUsers, isUsersLoading])

  const toggleSelectAllTeams = () => {
   setSelectAllTeams(!selectAllTeams);
   if(selectAllTeams) setSelectedTeamList([]);
};

const changeStatus = (asWho) => {
   confirmModal.close();
   changeAdminAsStudentStatus(clickedId, asWho);

   const userToMark = usersList?.find((item) => item?.id === clickedId);

   // Check if the user is not already in selectedUsers before adding
   if (userToMark && !selectedUsers.some((user) => user.id === clickedId)) {
      setSelectedUsers([...selectedUsers, userToMark]);
   }
 };

 const {
   data: departments,
   lastItemRef: lastFilterDepartmentRef,
   searchedValue: filterSearchedValueDep,
   setSearchedValue: setFilterSearchedValueDep,
} = useGetDepartments({
   companyId: currentCompanyId,
});

const {
   data: positions,
   lastItemRef: lastFilterPositionRef,
   searchedValue: filterSearchedValuePos,
   setSearchedValue: setFilterSearchedValuePos,
   refetch: refetchFilterPositions,
} = useGetPositions({
   companyId: currentCompanyId,
});

useEffect(()=> {
   if(teamsLoading) return;

   if (selectAllTeams) {
      setSelectedTeamList(teamList);
   } else {
      setSelectedTeamList(selectedUsers);
   }
}, [selectAllTeams, teamsLoading])
  
  const onSelectDepartment = (department) => {
    setSelectedDepartment(department);
  };

  const onSelectPosition = (position) => {
    setSelectedPosition(position);
  };

  const onSelectFilters = (user) => {
    if (selectedUsers?.find((item) => item?.id === user?.id))
      setSelectedUsers(selectedUsers?.filter((item) => item?.id !== user?.id));
    else setSelectedUsers([...selectedUsers, user]);
  };

  const onSelectTeamsFilters = (team) => {
    if (selectedTeamList?.find((item) => item?.id === team?.id))
      setSelectedTeamList(selectedTeamList?.filter((item) => item?.id !== team?.id));
    else setSelectedTeamList([...selectedTeamList, team]);
  };

  const closeModal = () => {
    if(onCancelRequests) onCancelRequests();

    onClose();
    setSelectAllUsers(false);
    setSearch('');
    if(setSearchedValue) setSearchedValue('')
    setSelectedDepartment(null);
    setSelectedPosition(null);
    setSelectedUsers([]);
    setSelectedTeamList([]);
    setSelectAllTeams(false);
  };

  if(selectedMode === TEAM_TAB) {
   return (
      <ModalLayout 
         isOpen={isOpen} 
         onClose={closeModal}
         maxHeight={'670px'}
         maxWidth={'450px'}
         withCross
         customPadding={"33px 40px"}
         noBlur
      >
      <p className={styles.title}>
         {title ? title : 
             `${t?.select} ${selectedMode === STUDENTS_TAB ? t?.returnTranslation('Users') : t?.returnTranslation('Teams')}`
         }
      </p>

      <div className={styles.tabs}>
         {ACCESS_TABS?.map((item, key) =>
           <TabButton 
               item={item} 
               key={key} 
               mode={selectedMode} 
               setMode={setSelectedMode}
            />)}
      </div>

      <p className={styles.selected}>{t?.selected}: <span>{selectedUsers?.length + selectedTeamList?.length}</span></p>
      <div className={styles.search}>
        <img src={searchIcon} alt={''} />
        <input placeholder={t?.search} value={teamSearchedValue} onChange={(event) => setTeamSearchedValue(event?.target?.value)} />
      </div>

      {teamList?.length > 0 && 
         <div className={cn(styles.selectAllButton, styles.list_item)} onClick={toggleSelectAllTeams}>
            <Checkbox isChecked={selectAllTeams} isGrey />
            <span>{t?.selectAll}</span>
         </div>
      }

      <div className={'default_loader_wrapper'}>
         {isUsersLoading && <Loader size={"small"} />}
      </div>   

      {!!teamList?.length ? (
        <div className={cn(styles.list, styles.team_list)}>
          {teamList?.map((item, key) => (
            <div ref={key === teamList?.length - 1 ? lastTeamRef : null} key={key} onClick={() => onSelectTeamsFilters(item)} className={cn(styles.list_item, styles.team_item)}>
              <Checkbox isChecked={selectedTeamList?.some((sItem) => sItem?.id === item?.id)} isGrey />
              {item?.name}
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.notFound}>
          <img src={notFound} alt={''} />
          {selectedMode === STUDENTS_TAB ? t?.thereAreNoUsers : t?.thereAreNoData}
        </div>
      )}

       <div className={styles.btns}>
         <Button
               isBlack
               title={t?.cancel}
               onClick={closeModal}
            />

            <Button
               disabled={selectedTeamList.length === 0 && selectedTeamList.length || isUsersLoading}
               title={isAddAccess 
                     ?  
                  t?.create 
                     : 
                  `${progress ? `${t?.returnTranslation("Loading")}... ${progress}%` : `${t?.returnTranslation('Confirm')}`}`
               }
               onClick={onConfirm ? () => {
                  onConfirm().then(()=> {
                     closeModal();
                  });
               } : onClose}
            />
      </div>
      {isAddAccess && 
         <StepIndicator step={2} />
      }
    </ModalLayout>
   )
  }


 const UserItem = ({ index, style, data }) => {
   const item = usersList[index];
 
   return (
     <div ref={index === usersList?.length - 1 ? lastUserRef : null} style={style} className={styles.flexed} key={index}>
       <div onClick={() => onSelectFilters(item)} className={styles.list_item}>
         <Checkbox isChecked={selectedUsers?.some((sItem) => sItem?.id === item?.id)} isGrey />
         <img src={`${item.avatar ? item.avatar : avatarPlaceholder}`} alt="" />
         <div className={styles.user_info}>
           <span>
             {!item?.firstName && !item?.lastName && !item?.email && '-'}
             {item?.firstName} {item?.lastName}
           </span>
           <div className={styles.role}>
             {transformRoleText(item?.userBranchRoles?.[0]?.role?.name || "-", currentCompanyName)}
           </div>
         </div>
         {item?.userBranchRoles[0]?.role?.id !== ROLE_ID_STUDENT &&
           item?.userBranchRoles[0]?.role?.id !== ROLE_ID_SUPER_ADMIN &&
           isCourseAccessPage && (
             <span
               onClick={() => {
                 confirmModal.open();
                 setClickedId(item?.id);
               }}
               className={cn(styles.switch, item?.isAsStudent ? styles.marked_as_student : "")}
             >
               {item?.isAsStudent ? t?.markedAsStudent : t?.addAsStudent}
             </span>
           )}
       </div>
     </div>
   );
 };
 
  return (
    <ModalLayout 
      isOpen={isOpen} 
      onClose={closeModal} 
      maxHeight={selectedMode === STUDENTS_TAB ? '690px' : '650px'}
      maxWidth={'520px'} 
      withCross 
      customPadding={"33px 40px"}
      noBlur
    >
      <p className={styles.title}>
      {title ? title : 
             `${selectedMode === STUDENTS_TAB ? t?.users : t?.teams}`
         }
      </p>

      <div className={styles.tabs}>
            {ACCESS_TABS?.map((item, key) =>
               <TabButton 
                  item={item} 
                  key={key} 
                  mode={selectedMode} 
                  setMode={setSelectedMode}
               />
            )}
      </div>

      <p className={styles.selected}>{t?.selected}: <span>{selectedUsers?.length + selectedTeamList?.length}</span></p>
      <div className={styles.search}>
        <img src={searchIcon} alt={''} />
        <input placeholder={t?.search} value={searchedValue} onChange={(event) => setSearchedValue(event?.target?.value)} />
      </div>

      <div className={styles.dropdowns}>
        <div className={styles.dropdown}>
            <DropdownWithSearch
                isMulti={false}
                inputClassnames={styles.input}
                simpleVariant={true}
                searchNeeded
                data={departments}
                field={"name"}
                placeholder={selectedDepartment?.name || t?.selectByDepartment}
                value={selectedDepartment?.name}
                onChange={onSelectDepartment}
                searchValue={filterSearchedValueDep}
                setSearchValue={setFilterSearchedValueDep}
                selectedItems={selectedDepartment}
                setSelectedItems={onSelectDepartment}
                lastItemRef={lastFilterDepartmentRef}
                onClear={() => onSelectDepartment('')}
            />
        </div>
        <div className={styles.dropdown}>
            <DropdownWithSearch
                isMulti={false}
                inputClassnames={styles.input}
                simpleVariant={true}
                searchNeeded
                data={positions}
                field={"name"}
                placeholder={selectedPosition?.name || t?.selectByPosition}
                value={selectedPosition?.name}
                onChange={onSelectPosition}
                searchValue={filterSearchedValuePos}
                setSearchValue={setFilterSearchedValuePos}
                selectedItems={selectedDepartment}
                setSelectedItems={onSelectPosition}
                lastItemRef={lastFilterPositionRef}
                onClear={() => onSelectPosition('')}
            />
        </div>
      </div>

      {isCourseAccessPage && 
         <EmailsInput
            placeholder={t?.returnTranslation("Search by emails")} 
            setEmails={setEmails}
            emails={emails}
         />
      }

      <div className={'default_loader_wrapper'}>
         {isUsersLoading && <Loader size={"small"} />}
      </div>


      {usersList?.length > 0 && 
         <div className={cn(styles.selectAllButton, styles.list_item)} onClick={toggleSelectAllUsers}>
            <Checkbox isChecked={selectAllUsers} isGrey />
            <span>{t?.returnTranslation("Select all")}</span>
         </div>
      }

      {!!usersList?.length ? (
         <List
            height={!isCourseAccessPage ? 240 : 195} 
            itemCount={usersList.length}
            itemSize={60} 
            width="100%"
         > 
              {({ index, style, data }) => (
                  <UserItem
                     index={index}  
                     style={style}  
                     data={data}    
                  />
               )}
         </List>
      ) : (
         <div className={styles.notFound}>
            <img src={notFound} alt={''} />
            {selectedMode === STUDENTS_TAB ? t?.thereAreNoUsers : t?.thereAreNoData}
         </div>
      )}

       <div className={styles.btns}>
         <Button
            isBlack
            title={t?.cancel}
            onClick={closeModal}
         />
         <Button
            disabled={selectedTeamList.length === 0 && selectedTeamList.length || isUsersLoading}
            title={isAddAccess 
                     ?  
                  t?.create 
                     : 
                  `${progress ? `${t?.returnTranslation("Loading")}... ${progress}%` : `${t?.returnTranslation('Confirm')}`}`
               }
            onClick={onConfirm ? () => {
               onConfirm().then(()=> {
                  closeModal();
               });
            } : onClose}
         />
       </div>
          {isAddAccess && 
            <StepIndicator step={2} />
          }

         <ChooseAdminAsStudentModal
            onAdmin={() => changeStatus("admin")}
            onStudent={() => changeStatus("student")}
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            title={t?.returnTranslation("Adding an admin")}
            subtitle={t?.returnTranslation("You can add an admin as student or as admin")}
         />
     
    </ModalLayout>
  );
};

export default SelectUsersAndTeamsModal;
