import React, { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import UserApi from "../../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import RenameModal from "../RenameModal/RenameModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";

import { useSelector } from 'react-redux';

const ChangeUserDataModal = ({ data, onClose, isOpen, refetchData }) => {
   const initialState = { name: "", surname: "", email: "" };
   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();

   const [isDisabled, setIsDisabled] = useState(false);
   

   const [user, setUser] = useState(initialState);
   const [selectedDepartment, setSelectedDepartment] = useState('');
   const [selectedPosition, setSelectedPosition] = useState('');

   const currentCompanyId = useSelector((state) => state.sidebar.companyId);

   const t = useFindTranslationsByString();

   const initUserData = () => {
      setUser(prev => ({
         name: data?.firstName || "",
         surname: data?.lastName || "",
         email: data?.email || ""
      }))
      setSelectedDepartment(data?.department);
      setSelectedPosition(data?.position);
   }

   const onOpenDepartmentModal = () => {
      newDepartmentModal.open();
      setSelectedDepartment("");
   };

   const onOpenPositionModal = () => {
      newPositionModal.open();
      setSelectedPosition("");
   };

   const onSelectDepartment = (department) => {
      setSelectedDepartment(department);
      setSelectedPosition(null);
   };

   const onSelectPosition = (position) => {
      setSelectedPosition(position);
   };

   const closeModal = () => {
      onClose();
      initUserData();
   };

   const handleStudentInputChange = (field, value) => {
      const updatedUser = { ...user };
      updatedUser[field] = value;
      setUser(updatedUser);
   };

   const onSave = async () => {
      if(user.name?.length === 0) return toast(<ToastMsg text={"First name cannot be empty"} isError/>)

         setIsDisabled(true);

      const dataToSend = {
         // ...(user.email !== data?.email && user.email?.length ? {new_email: user.email?.trim()} : {}),
         ...(user.name !== data?.firstName ? {new_first_name: user.name} : {}),
         ...(user.surname !== data?.lastName ? {new_last_name: user.surname} : {}),
         ...(selectedDepartment?.name !== data?.departments?.[0]?.name ? { new_department: selectedDepartment?.id } : {}),
         ...( selectedPosition?.id 
            ? { new_position: selectedPosition?.id } 
            : selectedDepartment?.positions?.length > 0 
              ? { new_position: selectedDepartment.positions[0].id } 
              : {} )
      };

      const res = await new UserApi().updateUser(data?.id, dataToSend);

      if(user.email !== data?.email && user.email?.length) {
         const resChangeEmail = await new UserApi().updateUserEmail(data?.id, {new_email: user.email?.trim()});

         if(resChangeEmail?.success?.message) {
            refetchData();
            toast(<ToastMsg text={resChangeEmail?.success?.message} />)
            closeModal();
            setIsDisabled(false);
            return;
         }
      }

      if(res?.success?.message) {
         refetchData();
         toast(<ToastMsg text={res?.success?.message || 'Successfully updaated'} />)
         closeModal();
      }

      setIsDisabled(false);

      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError/>)
      }
   }

   const {
      data: departments,
      lastItemRef: lastDepartmentRef,
      refetch: refetchDepartments,
      searchedValue: searchedValueDep,
      setSearchedValue: setSearchedValueDep,
   } = useGetDepartments({
      companyId: currentCompanyId,
   });

   useEffect(()=> {
    initUserData();
   }, [data])

   const filteredDepartments = data?.departments?.filter(d => +d?.company?.id === +currentCompanyId);
   const filteredPositions = data?.positions?.filter(p => +p?.department?.company?.id === +currentCompanyId);
   
   return (
      <ModalLayout
         onClose={closeModal}
         isOpen={isOpen}
         maxHeight={"535px"}
         maxWidth={"416px"}
         customBgColor={"#212224"}
         withCross
         visibleOverflow
      >
         <div className={styles.create_user_wrapper}>
            <div className={styles.title}>{t("Change user info")}</div>
            <div className={styles.inputs_wrapper}>
               <Input
                   customClass={styles.inputWrapper}
                  value={user?.name}
                  placeholder={t("Name")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("name", e.target.value)
                  }
               />
               <Input
                   customClass={styles.inputWrapper}

                  value={user?.surname}
                  placeholder={t("Surname")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("surname", e.target.value)
                  }
               />
               <Input
                   customClass={styles.inputWrapper}

                  value={user?.email}
                  placeholder={t("Email")}
                  onChangeValue={(e) =>
                     handleStudentInputChange("email", e.target.value)
                  }
               />

            <PaginatedDropdown
                  searchNeeded
                  data={departments}
                  field={"name"}
                  value={selectedDepartment?.name}
                  placeholder={
                     filteredDepartments?.length > 0
                        ? filteredDepartments
                             ?.map((item) => item?.name)
                             ?.join(", ")
                        : t("Department * Optionally")
                  }
                  onChange={onSelectDepartment}
                  maxHeight={"220px"}
                  onOpenCreateModal={onOpenDepartmentModal}
                  searchValue={setSearchedValueDep}
                  setSearchValue={searchedValueDep}
                  lastItemRef={lastDepartmentRef}
               />

            <PaginatedDropdown
                  searchNeeded
                  data={
                     selectedDepartment?.id
                        ? selectedDepartment?.positions || []
                        : departments?.find(
                           (d) => +d?.id === +filteredPositions?.[0]?.department?.id)?.positions || []
                  }
                  maxHeight={"180px"}
                  field={"name"}
                  value={selectedPosition?.name}
                  placeholder={
                     selectedDepartment?.id
                        ? selectedDepartment?.positions?.[0]?.name || 'No position in selected department'
                        : filteredPositions?.length > 0
                        ? filteredPositions
                             ?.map((item) => item?.name)
                             ?.join(", ") 
                        : t("Position * Optionally")
                  }
                  onChange={onSelectPosition}
                  disabled={!selectedDepartment?.id && !data?.departments?.length}
               />
            </div>
            <div className={styles.btns}>
               <Button title={t("Cancel")} onClick={closeModal} isBlack/>
               <Button title={t("Confirm")} disabled={isDisabled} onClick={onSave} />
            </div>
         </div>
      </ModalLayout>
   );
};

export default ChangeUserDataModal;
