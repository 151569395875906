import Base from "./Base";

export default class Roles extends Base {
    getRoles(companyID, serializationGroup) {
        return super.get(`api/v1/role?limit=100&filter[company]=${companyID}`, null, null, serializationGroup);
    }

    createRole(data) {
        return super.post(`api/v1/role`, data);
    }

    deleteRole(roleID) {
        return super.delete(`api/v1/role/${roleID}`);
    }

    updateRole(roleID, data) {
        return super.put(`api/v1/role/${roleID}`, data);
    }

    getRolePermissions(roleID) {
      return super.get(`api/v1/role/${roleID}/permission`);
    }

    getYourPermissions() {
      return super.get(`api/v1/permission/get-your-permissions`);
    }

    updateRolePermissions(roleID, data) {
      return super.put(`api/v1/role/${roleID}/permissions`, data);
    }

    getCoursesWhereYouAreCurator(companyId) {
      return super.get(`api/v1/course?limit=300&order=desc&filter[courses_where_you_are_curator]=1&filter[company]=${companyId}`);
    }

    getTeamsWhereYouAreMentor(companyId, serializationGroup) {
      return super.get(`api/v1/team?limit=300&order=desc&filter[teams_where_you_are_mentor]=1&filter[company]=${companyId}`, null, null, serializationGroup);
    }

    getYourTeams(serializationGroup) {
      return super.get(`api/v1/team/get-your-teams?limit=300&order=desc`, null, null, serializationGroup);
    }
}