import {localStorageGet, localStorageRemove} from "../localStorage";
import axios from "axios";

const NO_CONTENT_CODE = 204;

export default class Base {
    constructor() {
        this.url = process.env.REACT_APP_API || window.location.origin;
        this.userImpersonatedId = localStorageGet("impersonateId");
    }

    getToken() {
        return localStorageGet("token", "");
    }

    getHeaders(serializationGroup, languageHeader) {
      const requestHeaders = { Authorization: `Bearer ${this.getToken()}` };
  
      if (serializationGroup) {
         requestHeaders['serialization-group'] = serializationGroup;
      }
  
      if (languageHeader) {
         requestHeaders['Accept-course-language'] = languageHeader;
      }

      if(this.userImpersonatedId) {
         // Enter someone's account on super admin.
         requestHeaders['X-User-ID'] = this.userImpersonatedId;
      }
  
      return { headers: requestHeaders };
  }
  

    getUrl(path) {
        return `${this.url}/${path}`;
    }

    errorCatch(error) {
        const code = error?.response?.data?.error?.code || error?.response?.status;
        const message = error?.response?.data?.error?.message || error?.response?.data?.message;

        switch (code) {
            case 401:
                localStorageRemove("token");
                window.location.pathname = "/login";

                return {error: {message: error?.response?.data?.error?.message || error?.message || ''}};
            default:
                return {error: {message: error?.response?.data?.error?.message || error?.message || ''}};
        }
    }

    get(url, config, signal, serializationGroup, languageHeader) {
        return axios
            .get(this.getUrl(url), {...this.getHeaders(serializationGroup, languageHeader), ...config, signal})
            .then((res) => {
               if(res?.status === NO_CONTENT_CODE) {
                     return {
                        success: {
                           data: [],
                           code: NO_CONTENT_CODE,
                           message: "No content"
                        }
                     } 
               } else {
                  return res.data;
               }
            })
            .catch(this.errorCatch);
    }

    post(url, data, config, languageHeader, serializationGroup) {
        return axios
            .post(this.getUrl(url), data, {...this.getHeaders(serializationGroup), ...config})
            .then((res) => res.data)
            .catch((error) => ({error: {message: error?.response?.data?.error?.message || error?.message || ''}}));
    }

    patch(url, data, config) {
        return axios
            .patch(this.getUrl(url), data, {...this.getHeaders(), ...config})
            .then((res) => res.data)
            .catch((error) => ({error: {message: error?.response?.data?.error?.message || error?.message || ''}}));
    }

    put(url, data, config, serializationGroup = '') {
        return axios
            .put(this.getUrl(url), data, {...this.getHeaders(serializationGroup), ...config})
            .then((res) => res.data)
            .catch((error) => ({error: {message: error?.response?.data?.error?.message || error?.message || ''}}));
    }

    delete(url, data, config) {
      return axios
        .delete(this.getUrl(url), {
          ...config,
          ...this.getHeaders(),
          data: data, 
        })
        .then((res) => res.data)
        .catch((error) => ({
          error: {
            message: error?.response?.data?.error?.message || error?.message || '',
          },
        }));
    }
    
    postThirdParty(url, data, config) {
        return axios
            .post(url, data, config)
            .then((res) => res.data)
            .catch((error) => ({error: {message: error?.response?.data?.error?.message || error?.message || ''}}));
    }
}