import React, { useState, useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import plus from "../../../assets/images/symbols/plus.svg";

import Button from "../../../components/Button/Button";
import FilterRadio from "../../../components/FilterRadio/FilterRadio";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { CSVLink } from "react-csv";
import CreateTeamModal from "../../../components/Modals/CreateTeamModal/CreateTeamModal";
import SelectUserModal from "../../../components/Modals/SelectUserModal/SelectUserModal";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, BIGGER_DATA_LIMIT } from "../../../constants/user";

import useUserRole from "../../../hooks/useUserRole";
import useGetActiveUsersOfCompany from "../../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const TeamsSubheader = ({
   searchedValue,
   setSearchedValue,
   activeTeamsFilter,
   setActiveTeamsFilter,
   refetchData,
   csvData,
   refetchTeams,
   fetchTeams,
   allDataLoaded,
   isDataLoading,
   t
}) => {
    const tNew = useFindTranslationsByString();
   const createTeamModal = useHandleModal();
   const selectUserModal = useHandleModal();

   const [selectedDepartment, setSelectedDepartment] = useState();
   const [selectedPosition, setSelectedPosition] = useState();
   
   const csvLinkRef = useRef(null);

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleName } = useUserRole(currentCompanyId)

   // Remove selected users while adding them to a team.
   const onRemove = (id) => setSelectedUserList(selectedUserList?.filter(item => item?.id !== id));

   const [selectedUserList, setSelectedUserList] = useState([]);

   const handleFilterClick = (title) => {
      setActiveTeamsFilter(title);
   };

   const { setCurrentPage: setCurrentStudentsPage,
            userList: studentsList, 
            setUserList: setStudentsList, 
            searchedValue: studentsSearchedValue, 
            isUsersLoading: isStudentsLoading, 
            totalUsers:totalStudents, 
            setSearchedValue:setStudentsSearchedValue
         } = useGetActiveUsersOfCompany(currentCompanyId, true, false, selectedDepartment?.id, selectedPosition?.id);

   const lastStudentRef = useIntersectionObserver(
      () => {
        if (!isStudentsLoading && studentsList?.length < totalStudents) {
          if(studentsList?.length === 0) {
            setCurrentStudentsPage(1);
          } else {
            setCurrentStudentsPage((prev) => prev + 1);
          }
        }
      },
      [isStudentsLoading, totalStudents]
    );

   return (
      <div className={styles.subheader}>
         <FilterRadio
            noCount
            title={t?.all}
            active={activeTeamsFilter === "All"}
            onClick={() => handleFilterClick("All")}
         />
         <FilterRadio
            noCount
            title={t.department}
            active={activeTeamsFilter === "Department"}
            onClick={() => handleFilterClick("Department")}
         />
         <FilterRadio
            noCount
            title={t.position}
            active={activeTeamsFilter === "Position"}
            onClick={() => handleFilterClick("Position")}
         />
          <div
              className={styles.unspecifiedFilter}>
              <FilterRadio
                  noCount
                  title={tNew("Unspecified")}
                  active={activeTeamsFilter === "Undefined"}
                  onClick={() => handleFilterClick("Undefined")}
              />
              <div className={styles.hint}>
                  {tNew("None of the department and position are assigned")}
              </div>
          </div>

          <div className={styles.line}></div>
          <div className={styles.search_wrapper}>
              <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>

            <Button 
               onClick={()=> fetchTeams(1, BIGGER_DATA_LIMIT).then(()=> csvLinkRef.current.link.click())}
               title={t?.exportToCSV} 
               isBlack
               image={csvImg}
               maxWidth={'180px'}
               disabled={isDataLoading}
               className={styles.actions_button}
            />

            <CSVLink ref={csvLinkRef} style={{ display: "none" }} data={csvData}/>

            {!userRoleName?.includes(PART_OF_MENTOR_NAME) && 
               <Button
                   className={styles.actions_button}
                   title={t?.addTeam}
                   image={plus}
                   maxWidth={"180px"}
                   onClick={createTeamModal.open}
               />
            }
         </div>

            <CreateTeamModal
               onClose={createTeamModal.close}
               isOpen={createTeamModal.isActive}
               fetchTeams={refetchTeams}
               course={{ branch: { id: 1 } }}
               selectUserModal={selectUserModal}
               selectedUsers={selectedUserList}
               setSelectedUsers={setSelectedUserList}
               onRemove={onRemove}
            />

            <SelectUserModal
                onClose={selectUserModal.close}
                isOpen={selectUserModal.isActive}
                setSelectedUsers={setSelectedUserList}
                selectedUsers={selectedUserList}
                usersList={studentsList}
                lastUserRef={lastStudentRef}
                userSearchValue={studentsSearchedValue}
                setUserSearchValue={setStudentsSearchedValue}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
                selectedPosition={selectedPosition}
                setSelectedPosition={setSelectedPosition}
            />
      </div>
   );
};

export default TeamsSubheader;
