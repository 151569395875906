import React from "react";

import avatarPlaceholder from "../../../assets/images/avatar.svg";

import SearchInput from "../../../components/SearchInput/SearchInput";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";

import styles from "./styles.module.scss";
import cn from "classnames";
import ListLoader from "../../../components/ListLoader/ListLoader";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import StatisticsUsersFilter from "../StatisticsUsersFilter/StatisticsUsersFilter";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const UsersPanel = ({
   statisticsUser,
   changeActiveUser,
   userList,
   isUsersLoading,
   lastUserRef,
   searchedValue,
   setSearchedValue,
   isSideBarFixed,
   selectedDepartment,
   setSelectedDepartment,
   setSelectedCourse,
   selectedCourse,
   lastAllCourseRef,
   coursesManager,
   triggerUsersFetch
}) => {

   const t = useFindTranslationsByString();

   return (
      <div
         className={cn(
            styles.users_panel,
            isSideBarFixed ? styles.smaller_panel : ""
         )}
      >
         <div className={styles.header}>
            <div className={styles.title}>{t("All users")}</div>
            <div className={styles.filters}>
               <SearchInput
                  searchedValue={searchedValue}
                  setSearchedValue={setSearchedValue}
                  withBorder
               />

               <StatisticsUsersFilter
                  lastAllCourseRef={lastAllCourseRef}
                  activeCourseFilter={selectedCourse}
                  setActiveCourseFilter={setSelectedCourse}
                  onConfirm={triggerUsersFetch}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                  coursesManager={coursesManager}
                  selectedCourse={selectedCourse}
                  setSelectedCourse={setSelectedCourse}
               />
            </div>
         </div>

         <div
            className={cn(
               styles.user_list_item,
               styles.user_list_item_chosen,
               styles.active_user
            )}
         >
            <img src={statisticsUser?.avatar || avatarPlaceholder} alt="" />
            <CustomTooltip
               id={`${statisticsUser?.id}name`}
               text={statisticsUser?.name}
               position={'bottom'}
               limit={30}
            />
         </div>
         <div className={styles.users_list}>
            {userList?.map((user) => (
               <div
                  onClick={() =>
                     statisticsUser?.id !== user?.id && changeActiveUser(user)
                  }
                  ref={lastUserRef}
                  key={user?.id}
                  className={cn(
                     styles.user_list_item,
                     statisticsUser?.id === user?.id
                        ? styles.active_user_in_list
                        : ""
                  )}
               >
                  <img src={user.avatar || avatarPlaceholder} alt="" />
                  <CustomTooltip
                     id={`${user?.id}name`}
                     text={user.name}
                     limit={30}
                     position={'bottom'}
                  />
               </div>
            ))}

            {isUsersLoading && <ListLoader />}
            {!isUsersLoading && userList?.length === 0 && (
               <EmptyBlock />
            )}
         </div>
      </div>
   );
};

export default UsersPanel;
