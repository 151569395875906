import React, { useRef, useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import CertificateTemplate from "../../CertificateTemplate/CertificateTemplate";
import EmptyBlock from "../../EmptyBlock/EmptyBlock";
import moment from "moment";
import Button from "../../Button/Button";
import pdfIcon from "../../../assets/images/pdf_icon.svg";
import downloadIcon from "../../../assets/images/upload.svg";
import courses_img from "../../../assets/images/menu/courses.svg";
import pngIcon from "../../../assets/images/png_icon.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../../Loader/Loader";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";

const Certificates = ({
   certificates,
   orientation,
   certificateLoading,
   openCertificateModal,
   lastItemRef,
}) => {
   const [loading, setLoading] = useState(false);
   const [currentTemplate, setCurrentTemplate] = useState({});
   const certificateRef = useRef(null);
   const t = useFindTranslationsByString();

   const dropdownRef = useRef();
   const openDropRef = useRef();

   const [isOpen, setIsOpen] = useState(false);
   const onClose = () => setIsOpen(false);

   useOutsideClick(dropdownRef, onClose, openDropRef);
   
   const downloadPdf = () => {
      setLoading(true);
      const pdf = new jsPDF({
         format: [
            certificateRef.current.offsetWidth,
            certificateRef.current.offsetHeight,
         ],
      });

      const pageWidth = pdf.internal.pageSize.width;
      const pageHeight = pdf.internal.pageSize.height;

      html2canvas(certificateRef.current).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");
         pdf.addImage(imgData, "JPEG", 0, -3, pageWidth, pageHeight / 2);
         pdf.save("download.pdf");
         setLoading(false);
      });
   };

   const downloadPng = () => {
      setLoading(true);
      html2canvas(certificateRef.current, {
         width: certificateRef.current.offsetWidth,
      }).then((canvas) => {
         const imgData = canvas.toDataURL("image/png");
         const downloadLink = document.createElement("a");
         downloadLink.href = imgData;
         downloadLink.download = "download.png";
         downloadLink.click();
         setLoading(false);
      });
   };

   const handleMouseEnter = (item) => {
      setCurrentTemplate(item);
   };

   const handleMouseLeave = () => {
      setCurrentTemplate({});
   };

   const openMobDropdown = () => {
      setIsOpen(true);
   };

   if (orientation === "line") {
      return (
         <div className={cn(styles.lineOriented)}>
            {certificates?.map((item, index) => (
               <div
                  ref={
                     lastItemRef && certificates?.length - 1 === index
                        ? lastItemRef
                        : null
                  }
                  className={cn(styles.certificate_wrapper)}
                  key={`first${item.id}`}
               >
                  <div className={styles.certificate}>
                     <CertificateTemplate
                        onClick={() => openCertificateModal(item)}
                        size="5"
                        data={item}
                     />
                  </div>

                  <div className={styles.right_block}>
                     <div className={styles.first}>
                        <div className={styles.courseName}>
                           {item?.course?.name}
                        </div>
                        {!!item?.course?.tags?.length && (
                           <div className={styles.row}>
                              {item?.course?.tags?.map((item, key) => (
                                 <span key={key}>{item}</span>
                              ))}
                           </div>
                        )}
                        <div className={styles.date}>
                           <div className={styles.label}>{t("Date")}:</div>
                           <span>
                              {moment(item?.dateCompleted).format("DD/MM/YY")}
                           </span>
                        </div>
                     </div>

                     <div
                        className={styles.second}
                        onMouseEnter={() => handleMouseEnter(item)}
                        onMouseLeave={handleMouseLeave}
                     >
                        <Button
                           title={t("Download as PDF")}
                           image={pdfIcon}
                           onClick={downloadPdf}
                           isBlack
                           disabled={loading}
                        />
                        <Button
                           title={t("Download as PNG")}
                           image={pngIcon}
                           onClick={downloadPng}
                           isBlack
                           disabled={loading}
                        />
                     </div>
                  </div>
               </div>
            ))}

            {/* FOR PDF AND PNG */}
            {currentTemplate?.id && (
               <div className={styles.hide}>
                  <CertificateTemplate
                     size="15"
                     data={certificates.find(
                        (cer) => cer.id === currentTemplate?.id
                     )}
                     certificateRef={certificateRef}
                  />
               </div>
            )}

            {!certificateLoading && !certificates?.length && (
               <div className={styles.empty}>
                  <EmptyBlock key={"cert"} />
               </div>
            )}

            {loading && (
               <div className="default_loader_wrapper">
                  <Loader size={"small"} />
               </div>
            )}
         </div>
      );
   }

   return (
      <div className={cn(!certificateLoading && !certificates?.length ? styles.empty : styles.certificatesWrapper)}>
             {/* FOR PDF AND PNG */}
             {currentTemplate?.id && (
                     <div className={styles.hide}>
                        <CertificateTemplate
                           size="15"
                           data={certificates.find(
                              (cer) => cer.id === currentTemplate?.id
                           )}
                           certificateRef={certificateRef}
                        />
                     </div>
                  )}

         {certificates?.map((item, index) => (
            <div
               ref={
                  lastItemRef && certificates?.length - 1 === index
                     ? lastItemRef
                     : null
               }
               className={cn(styles.certificate_item)}
               key={`first${item?.id}`}
            >
               <CertificateTemplate
                  onClick={() => openCertificateModal(item)}
                  size="5"
                  data={item}
               />

               <div className={styles.under_cert}>
                  <div className={styles.courseNameMob}>
                     <img src={courses_img} alt="" />
                     <div>{item?.course?.name}</div>
                  </div>

                  <div className={styles.date_mob}>
                     <div className={styles.d_left}>
                        <div className={styles.label}>
                           {t("Date of issue")}:
                        </div>
                        <span>
                           {moment(item?.dateCompleted).format("DD/MM/YY")}
                        </span>
                     </div>

                     <div className={styles.d_right}>
                        <div
                            onClick={() => handleMouseEnter(item)}
                            className={styles.download_as} ref={openDropRef}>
                           <Button
                              title={t("Download as")}
                              image={downloadIcon}
                              onClick={openMobDropdown}
                              isBlack
                           />
                        </div>

                        {isOpen && currentTemplate?.id === item?.id && (
                           <div
                              ref={dropdownRef}
                              className={styles.btns_wrapper}
                           >
                              <Button
                                 title={t("Download as PDF")}
                                 image={pdfIcon}
                                 onClick={downloadPdf}
                                 isBlack
                                 disabled={loading}
                                 isHoverPurple
                              />

                              <Button
                                 title={t("Download as PNG")}
                                 image={pngIcon}
                                 onClick={downloadPng}
                                 disabled={loading}
                                 isHoverPurple
                                 isBlack    
                              />
                           </div>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         ))}
         {!certificateLoading && !certificates?.length && (
            <div className={styles.empty}>
               <EmptyBlock key={"cert"} />
            </div>
         )}
      </div>
   );
};

export default Certificates;
