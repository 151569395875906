import { useState } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const EmailsInput = ({ setEmails, emails, placeholder }) => {
   const [emailsInputValue, setEmailsInputValue] = useState('');

   
   const handleEmailChange = (event) => {
      const newValue = event.target.value;
      setEmailsInputValue(newValue);

      const matches = newValue.match(
         /(?:[^,\s";]+@[^,\s";]+\.[^\s",;]+)|(?:".+?")/g
      );

      const emailArray = (matches || [])
         .map((email) => email.replace(/^"|"$/g, "").trim())
         .filter((email) =>
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
         )
         .filter((value, index, self) => self.indexOf(value) === index);

     if(emailArray?.length) {
       setEmails(emailArray);
     }

      if(newValue?.length > 0 && !emailArray?.length) {
         setEmails(emailsInputValue);
      }
   };

   return (
      <div className={cn(styles.search_emails, styles.search)}>
         <input
            placeholder={placeholder}
            value={emailsInputValue}
            onChange={handleEmailChange}
         />
      </div>
   );
};

export default EmailsInput;
