import React, { forwardRef } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";
import cross from "../../assets/images/symbols/cross_grey.svg";

const Input = forwardRef(
   (
      {
         placeholder,
         max = null,
         value = "",
         onChangeValue,
         withCounter,
         disabled = false,
         customClass,
         withHeader,
         title,
         isWhiteBlack = false,
         onClick = null,
         isDefaultColor,
         autoFocus,
         isError,
         onKeyDown,
         type,
         icon,
         maxHeightFrontEl,
         crossNeed,
         inputWrapperStyle = {}
      },
      ref
   ) => {
      return (
         <>
            {withHeader && (
               <div className={styles.header}>
                  {title}
                  {max && (
                     <span>
                        {value?.length}/{max?.toString()}
                     </span>
                  )}
               </div>
            )}

            <div
               onClick={onClick}
               style={{ maxHeight: maxHeightFrontEl ? maxHeightFrontEl : '', ...inputWrapperStyle }}
               className={cn(
                  styles.inputWrapper,
                  isWhiteBlack && styles.inputWrapper_isWhiteBlack,
                  customClass && customClass,
                  isDefaultColor && styles.defaultColorActive,
                  isError ? styles.error : '',
               )}
            >
                 { icon && 
                     <img className={styles.icon} src={icon} alt="" />
                  }
               <input
                  type={type}
                  ref={ref}
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  maxLength={max}
                  value={value}
                  onChange={onChangeValue}
                  disabled={disabled}
                  onKeyDown={onKeyDown}
               />
               {withCounter && (
                  <span>
                     {value?.length}/{max?.toString()}
                  </span>
               )}
               
               {crossNeed && value?.length > 0 && (
                  <img 
                     className={styles.cross}
                     onClick={()=> onChangeValue({target:{value: ''}})} 
                     src={cross} 
                     alt="" />
               )}
            </div>
         </>
      );
   }
);

export default Input;
