import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import RightsSubheader from "../RightsSubheader/RightsSubheader";
import SwitchItem from "../SwitchItem/SwitchItem";
import {
   usersRightsData,
   usersRightsDataFeedback,
   usersRightsDataSettings,
   pageStaticPermissionsInfoCustomRoles,
} from "../../../constants/user";
import Roles from "../../../utils/api/Roles";
import useFindTranslationByString from "../../../hooks/useFindTranslationsByString";
import cn from "classnames";

const SettingItems = ({ activeRole, inputsData, setInputsData }) => {

   const t = useFindTranslationByString();
   
   const handleChangeValue = (name) => {
      setInputsData((prevData) => ({
         ...prevData,
         [name]: {
            value: !prevData[name]?.value, 
            status: 'new',  
         },
      }));
   };
   
   const renderDefaultRolesItems = () => {
      return (
         <>
            <h2 className={styles.settings_title}>{t("Main settings")}</h2>

            {usersRightsData.map((item) => (
               <SwitchItem
                  key={item.name}
                  value={inputsData[item.name]?.value}
                  changeValue={() => handleChangeValue(item.name)}
                  title={t(item.title)}
                  text={t(item.text)}
               />
            ))}
            <h2 className={styles.settings_title}>Settings</h2>
            {usersRightsDataSettings.map((item) => (
               <SwitchItem
                  key={item.name}
                  value={inputsData[item.name]?.value}
                  changeValue={() => handleChangeValue(item.name)}
                  title={t(item.title)}
                  text={t(item.text)}
               />
            ))}
         </>
      );
   };

   return (
      <div className={styles.main_settings}>
         {activeRole?.essential && renderDefaultRolesItems()}

         {activeRole?.id &&
            !activeRole?.essential &&
            pageStaticPermissionsInfoCustomRoles.map((parentItem, idx) => (
               <div
                  className={cn(
                     styles.wrapper,
                     idx === 0 ? styles.no_margin_top : ""
                  )}
                  key={parentItem?.name}
               >
                  <h2 className={cn(styles.settings_title_custom)}>
                     {t(parentItem?.name)}
                  </h2>

                  {parentItem?.items?.map((item) => (
                     <SwitchItem
                        key={item.name}
                        value={inputsData[item.label]?.value}
                        changeValue={() => handleChangeValue(item.label)}
                        title={t(item.name)}
                        text={t(item.text)}
                     />
                  ))}
               </div>
            ))}
      </div>
   );
};

export default SettingItems;
