import styles from "./styles.module.scss";
import cn from "classnames";

const DraggingArrows = ({ isDragging, hideByDefault, className = "" }) => {
  return (
    <div
      className={cn(
        `${styles.header_arrows} ${isDragging ? styles.dragging : ""} ${!isDragging && hideByDefault ? styles.hide : ""}`,
        className,
      )}
    >
      <div className={styles.header_arrow_up} />
      <div className={styles.header_arrow_down} />
    </div>
  );
};

export default DraggingArrows;
