import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import styles from './styles.module.scss'
import Input from "../../../components/Input/Input";
import TextArea from "../../../components/TextArea/TextArea";
import sertificateImg from '../../../assets/images/sertificate.svg'
import Switch from "../../../components/Switch/Switch";
import Checkbox from "../../../components/Checkbox/Checkbox";
import img from "../../../assets/images/course_thumbnail.png";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import cross from '../../../assets/images/symbols/cross_grey.svg'
import deleteIcon from '../../../assets/images/symbols/delete.svg'
import deleteImg from '../../../assets/images/symbols/delete.svg'
import useHandleModal from '../../../hooks/dom/useHandleModal';
import CreateSpecialUserModal from '../../../components/Modals/CreateSpecialUserModal/CreateSpecialUserModal';
import { PART_OF_ADMIN_NAME, ROLE_ID_SUPER_ADMIN } from '../../../constants/user';
import Dropzone from 'react-dropzone-uploader';
import { MAX_IMAGE_SIZE, SUBMENU_CERTIFICATE } from '../../../constants/courses';
import { COURSE_LANGUAGES_LIST } from '../../../constants/languages';
import CourseApi from '../../../utils/api/CourseApi';
import axios from 'axios';
import { toast } from 'react-toastify';
import ToastMsg from '../../../components/ToastMsg/ToastMsg';
import Loader from '../../../components/Loader/Loader';
import SelectEntities from '../../../components/SelectEntities/SelectEntities';
import cn from 'classnames';
import Button from '../../../components/Button/Button';
import arrow from "../../../assets/images/symbols/Arrow - Down.svg";
import statusActiveImg from "../../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from '../../../assets/images/paused_status.svg'
import CourseLanguageStatusModal from "../../../components/Modals/CourseLanguageStatusModal/CourseLanguageStatusModal";

import arrowUp from "../../../assets/images/arrow_u.svg";
import { ReactComponent as ArrowUp } from "../../../assets/images/arrow_u.svg";
import arrowDown from "../../../assets/images/arrow_d.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/arrow_d.svg";
import useUserRole from '../../../hooks/useUserRole';
import { returnTemporaryLink } from '../../../utils/usersHelper';
import PaginatedDropdown from '../../../components/PaginatedDropdown/PaginatedDropdown';
import ConfirmModal from '../../../components/Modals/ConfirmModal/ConfirmModal';
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const BasicSettingsTab = ({data, courseImage, setCourseImage, setData, onSaveImage, onDeleteImage, tagsInputRef, refetchData, selectedCuratorsList, setSelectedCuratorsList, specialUsers, agenciesList, selectedAgencies, setSelectedAgencies, setActiveTab, course, lastSpecialUserRef,specialUsersSearchedValue, setSpecialUsersSearchedValue, lastAgencyRef, fetchCourse, courseId
}) => {
    const [isLimitReached, setIsTagLimitReached] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isDelitingLanguageContent, setIsDelitingLanguageContent] = useState(false);
    const [currentTagValue, setCurrentTagValue] = useState('');
    const [authorAvatar, setAuthorAvatar] = useState(null);
    const [clickedLanguage, setClickedLanguage] = useState(null);

    const passingScoreRef = useRef(null);
    const t = useFindTranslationsByString();

    const createSpecialUserModal = useHandleModal();
    const handleStatusModal = useHandleModal();
    const confirmModal = useHandleModal();

    const companyId = useSelector(state => state.sidebar.companyId);
    const userInfo = useSelector(state => state.user.info);
    const { userRoleId, userRoleName } = useUserRole(companyId);

    const onSelectMentor = (curators) => !selectedCuratorsList?.includes(curators) && setSelectedCuratorsList([...selectedCuratorsList, curators]);
    const onRemoveMentor = (curators) => setSelectedCuratorsList(selectedCuratorsList?.filter(item => item !== curators));

    const onCloseStatusModal = () => handleStatusModal.close();
    const onOpenStatusModal = (item) => {
       setClickedLanguage(item);
       handleStatusModal.open();
    };

    const onOpenConfirmModal = (item) => {
      confirmModal?.open();
      setClickedLanguage(item);
    }

    const fetchAuthorAvatar = async() => {
      if(!course?.author?.id) {
         setAuthorAvatar(null);
         return;
      }

      const restrictedAvatarLink = await returnTemporaryLink({id: course?.author?.id, avatar: course?.author?.avatar});

      setAuthorAvatar(restrictedAvatarLink?.[0]?.fileUrl || null);
   }

   useEffect(()=> {
      fetchAuthorAvatar();
   }, [course])

    const handleAutoSubmit = async (file, remove) => {
      if (file?.size > MAX_IMAGE_SIZE) {
         remove();
         return toast(
            <ToastMsg
               text={"File size exceeds the maximum allowed size of 5 MB."}
               isError
            />
         );
      }

      setIsImageLoading(true);

      const dataToGetUrl = [
         {
            course_id: course?.id,
            filename: file.name,
         },
      ];

      const res = await new CourseApi().getPresignedFilesUploadUrls(
         dataToGetUrl
      );

      if(res?.error?.message) {
        remove();
        return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
      const amazonUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;
      const amazonFileName = res?.success?.data?.[0]?.success?.data?.filename;
       
      const resUpload = await axios({
         method: "PUT",
         url: uploadUrl,
         headers: {
            "Content-Type": file.type,
         },
         data: file,
      }).catch(err => {
         remove();
         toast(<ToastMsg text={err?.message || 'Something went wrong'} isError />)
       });


      if (resUpload) {
         setCourseImage(amazonUrl);
         onSaveImage(amazonFileName);
      }

      setIsImageLoading(false);
   };

   const uploadImage = ({ meta, file, remove }, status) => {
      if (status === "done") {
         remove();
         handleAutoSubmit(file, remove, meta);
      }
    };

    const onTagsAdd = (e) => {
      const trimmedTag = currentTagValue.trim();
      const updatedTagsArray = [...data.new_tags, trimmedTag].slice(0, 16);

      if(e.keyCode === 13 && currentTagValue.length) {
         if (updatedTagsArray.length >= 16) {
            setIsTagLimitReached(true);
          } else {
            if (hasDuplicates(updatedTagsArray)) {
               return toast(<ToastMsg  text={'The course cannot have two identical tags'} isError />);
             }
            setData({ ...data, new_tags: updatedTagsArray });
            setIsTagLimitReached(false);
            saveTags(updatedTagsArray);
          }

          setCurrentTagValue('');
      }
    };

    const deleteTag = (e, tagToDeleteIdx) => {
      e.stopPropagation();
      const updatedTagsArray = data?.new_tags?.filter((tag, idx) => idx !== tagToDeleteIdx);
      setData({ ...data, new_tags: updatedTagsArray });

      saveTags(updatedTagsArray)
    };

    const deleteLanguageVersion = async () => {
      setIsDelitingLanguageContent(true);
      const res = await new CourseApi().deleteCourseLanguageContent(courseId, clickedLanguage?.code);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message}/>)
      }

      if(res?.error?.message) {
         toast(<ToastMsg isError text={res?.error?.message}/>)
      }

      confirmModal.close();

      setIsDelitingLanguageContent(false);
    }

    const hasDuplicates = (array) => new Set(array).size !== array.length;

    const saveTags = async (tags) => {
      const res = await new CourseApi().editCourseSetting(course?.id, {
         new_tags: tags?.length === 0 ? null : tags 
      });

      if (res?.error?.message) return toast(<ToastMsg text={res?.error?.message} isError/>)
    }

    const defineIcon = (status) => {
      if (status === 1) {
         return statusActiveImg;
       } else if (status === 0) {
         return statusPausedImg;
       }
   }

    const avatarAuthorLink = authorAvatar ? authorAvatar : avatarPlaceholder;

    const langs_list = COURSE_LANGUAGES_LIST?.filter(lang => course?.defaultLanguage !== lang?.code);

    const updatedLangsList = langs_list?.map(lang => {
      const existsInAvailableLanguages = course?.availableLanguages?.includes(lang?.code);
      return existsInAvailableLanguages ? { ...lang, status: 1 } : {...lang, status: 0};
    });

    const defaultLanguage = COURSE_LANGUAGES_LIST?.find(lang => lang?.code === course?.defaultLanguage)?.name || '';


    const handleIncrement = (e, field) => {
        e.stopPropagation();
        // Increment the count if it is less than 100
        if (data?.[field] < 100) {
            setData({
                ...data,
                [field]: Math.min(data?.[field] + 1, 100),
            });
        }
    };

    const handleDecrement = (e, field) => {
        e.stopPropagation();

        // Decrement the count if it is greater than 0
        if (data?.[field] > 0) {
            setData({
                ...data,
                [field]: Math.max(data?.[field] - 1, 0)
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp') {
            handleIncrement();
            e.preventDefault(); // Prevents scrolling
        } else if (e.key === 'ArrowDown') {
            handleDecrement();
            e.preventDefault(); // Prevents scrolling
        }
    };

    const hasPermissionToAssignCurators = userInfo?.permissions?.some(p => p?.id === 285);


    return (
        <div translate="no" className={styles.basicSettingsTab}>
            {isImageLoading &&
               <div className='default_loader_wrapper'>
                  <Loader />
               </div>
            }
            <CreateSpecialUserModal
               onClose={createSpecialUserModal.close}
               isOpen={createSpecialUserModal.isActive}
               refetchData={refetchData}
            />

            <div translate="yes"  className={styles.image_block}>
                <img src={courseImage ? courseImage : img} alt={''} className={styles.image}/>
                <div>
                    <p className={styles.image_block_title}>{t("Thumbnail image")}</p>
                    <p className={styles.image_size}>{t("Recomended")}: 900 x 450 px</p>

                    <div className={styles.image_actions}>
                        <Dropzone
                              onChangeStatus={uploadImage}
                              maxFiles={1}
                              multiple={false}
                              accept="image/jpeg, image/png, image/gif"
                              inputContent={t("Replace image")}
                              classNames={{
                                 inputLabel: styles.custom_input_label,
                                 input: styles.dropzoneInput
                              }}
                           />
                        <div className={styles.delete_image} onClick={onDeleteImage}>
                           <img src={deleteImg} alt={''}/>
                           <span>{t("Delete image")}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.block}>
                <Input
                    placeholder={`${t("Enter a title")}...`}
                    max={40}
                    maxHeightFrontEl={44}
                    withHeader
                    title={t('Title')}
                    value={data?.new_name}
                    onChangeValue={(event) => setData({...data, new_name: event?.target?.value})}
                />
            </div>
            <div className={cn(styles.block, styles.description)}>
                <TextArea
                    max={200}
                    placeholder={t('Enter a Description')}
                    withHeader
                    title={t('Description')}
                    height={87}
                    textareaClassname={styles.textarea}
                    value={data?.new_description}
                    onChangeValue={(event) => setData({...data, new_description: event?.target?.value})}
                />
            </div>

            <div className={styles.block}>
               <span className={styles.subtitle}>{t("Available language")}</span>
               <div className={styles.langs_list}>
                  <div className={styles.default_lang}>
                     {defaultLanguage}
                     {defaultLanguage && <span> {`(${t("Main")})`}</span>}
                  </div>
                  {updatedLangsList?.map(lang =>
                     <div key={lang?.id} className={cn(styles.langs_list_item)}>
                        <span>{lang?.name}</span>
                        <div className={styles.lang_right}>
                           <div
                              className={styles.actions}
                              onClick={() => onOpenStatusModal(lang)}
                           >
                              <img src={defineIcon(lang?.status)} alt="icon" />
                              <img src={arrow} alt="" />
                           </div>
                           <Button
                              image={deleteIcon}
                              title={t("Delete")}
                              onClick={()=> onOpenConfirmModal(lang)}
                              small isBlack
                           />
                        </div>
                     </div>
                  )}
               </div>
            </div>

            <div className={styles.tags}>
                <div className={styles.tags_header}>
                    <span>{t("Course tags")}</span>
                    <span>{data?.new_tags?.length}/15</span>
                </div>

                 <div className={styles.tags_wrapper} onBlur={()=> setCurrentTagValue('')} onClick={()=> tagsInputRef.current.focus()}>
                     {data?.new_tags?.map((tag, idx) =>
                        <span key={idx} className={styles.tag_item}>
                           <span>{tag}</span>
                           <img onClick={(e)=> deleteTag(e, idx)} src={cross} alt="" />
                        </span>
                     )}

                     <input
                        value={currentTagValue}
                        onChange={(e)=> setCurrentTagValue(e.target.value)}
                        onKeyDown={onTagsAdd}
                        ref={tagsInputRef}
                        type="text"
                     />
                    <img className={styles.close_input} onClick={(e) => {
                      e.stopPropagation();
                      tagsInputRef.current.blur();
                    }} src={cross} alt="" />
                  </div>
            </div>

          {(userRoleName?.includes(PART_OF_ADMIN_NAME) || hasPermissionToAssignCurators ) &&
             <div className={styles.add_mentor_block}>
               <PaginatedDropdown
                  searchValue={specialUsersSearchedValue}
                  setSearchValue={setSpecialUsersSearchedValue}
                  lastItemRef={lastSpecialUserRef}
                  onChange={onSelectMentor}
                  data={specialUsers}
                  placeholder={t('Select special user')}
                  field={'name'}
                  createBtnText={t('Create a special user')}
                  onOpenCreateModal={createSpecialUserModal.open}
                  isUsers
               />
                  {
                     !!selectedCuratorsList.length
                     &&
                        <p className={styles.rightBlock_title}>{t("Selected")}:</p>
                  }
               {!!selectedCuratorsList?.length &&
                  <div className={styles.selected_mentors_list}>
                     {selectedCuratorsList?.map((item, key) =>
                        <div key={key}>
                           {item?.name}
                           <img src={cross} alt={''} width={12} height={12} onClick={() => onRemoveMentor(item)}/>
                        </div>)
                     }
                  </div>
               }
         </div>
          }

          <div className={styles.course_author}>
            <div className={styles.course_author_left}>
               <div className={styles.avatar_wrap_image}>
                  <img src={avatarAuthorLink} alt="avatar" />
               </div>
               <div>
                  <p className={styles.name}>{course?.author?.firstName || 'No creator' + (course?.author?.lastName || '')}</p>
                  <p className={styles.role}></p>
               </div>
            </div>
            <p className={cn(styles.gray, styles.creator_label)}>{t("Creator")}</p>
          </div>

          {/* FOR FUTURE <div className={styles.table_wrapper}>
               <CourseModifiedTable
                     data={[
                        {name: 'Jerry Heil 1', id: 1, last_modified: '27/12/2023 11:30', role: 'Admin'},
                        {name: 'Jerry Heil 2', id: 1, last_modified: '27/12/2023 11:30', role: 'Admin'},
                        {name: 'Alona Alona 1', id: 1, last_modified: '27/12/2023 11:30', role: 'Admin'},
                        {name: 'Alona Alona 2', id: 1, last_modified: '27/12/2023 11:30', role: 'Admin'},
                     ]}
                     columns={COURSE_MODIFIED_COLUMNS}
               />
         </div> */}

            <div translate="yes" className={styles.pass_sequence_block}>
               <h2 className={styles.is_linear_title}>{t("Pass sequence")}</h2>
               <p className={styles.is_linear_text}>{t("Linear mode gives students access to the lesson in sequential order. Nonlinear mode - to all lessons at once")}.</p>

               <div className={styles.is_linear_btns}>
                  <button className={cn(
                           styles.is_linear_btn,
                           data?.new_is_linear && styles.active_linear
                        )}
                        onClick={() => setData({
                           ...data,
                           new_is_linear: 1
                        })}
                        > {t("Linear")}
                  </button>
                  <button
                     className={cn(
                        styles.is_linear_btn,
                        !data?.new_is_linear && styles.active_linear
                     )}
                     onClick={() => setData({
                        ...data,
                        new_is_linear: 0,
                        new_is_progressive_validation_lock: 0
                     })}
                     > {t("Non linear")}
                  </button>
                  <div className={styles.active_indicator}>
                     {data?.new_is_linear ? t('Linear') : t('Non linear')}
                  </div>
                </div>
            </div>

            {!!data?.new_is_linear &&
               <div translate="yes"  className={styles.validation_lock_block}>
                    <Switch
                       title={t('Progressive Validation Lock')}
                       onChange={() => setData({
                          ...data,
                          new_is_progressive_validation_lock: +(!data?.new_is_progressive_validation_lock)
                       })}
                       checked={data?.new_is_progressive_validation_lock}
                    />
                    <p className={styles.validation_lock_text}>{t("When 'Progressive Validation Lock' is set to 'yes,' it prohibits students from accessing the next test until the previous one they attempted has been marked as 'checked.'")}</p>
              </div>
            }

         <div translate="yes"  className={cn(styles.block, styles.passing_score)}>
            <p className={styles.is_linear_title}>{t("Average grade")}</p>
            <p className={styles.is_linear_text}>
              {t("Set the score for all passed tests that students must achieve in order to pass the course")}
            </p>
            <div
               onClick={() => passingScoreRef.current.focus()}
               className={cn(styles.passing_number_wrapper, 'noselect')}
            >
                <div className={styles.arrow_wrapper}>
                    <img alt={"arrow_up"} onClick={(e) => handleIncrement(e,"new_percentage_to_receive_certificate")} src={arrowUp} className={styles.up_arrow}></img>
                    <img alt={"arrow_down"} onClick={(e) => handleDecrement(e,"new_percentage_to_receive_certificate")} src={arrowDown} className={styles.down_arrow}></img>
                </div>

                <input
                  ref={passingScoreRef}
                  className={styles.styled_number_input}
                  min="0"
                  type="number"
                  value={data?.new_percentage_to_receive_certificate ?? ""}
                  max="100"
                  onChange={(e) => setData({
                     ...data,
                     new_percentage_to_receive_certificate: e.target.value
                 })}
               />

               <span>
                  <p>%</p> <p>/</p> 100%{" "}
               </span>
            </div>
         </div>

            <div translate="yes" className={cn(styles.block, styles.passing_score)}>
                <p className={styles.is_linear_title}>{t("Pass attempts limit")}</p>
                <p className={styles.is_linear_text}>
                    {t("Set the amount of attempts for all tests. When user fail last attempt he or she could continue the course only in case admin set the test to status 'on check'")}
                </p>
                <div className={cn(styles.passing_number_wrapper, styles.attempts_number_wrapper, 'noselect')}>
                    <div className={styles.arrow_wrapper}>
                        <img src={arrowUp} className={styles.up_arrow} alt="Increase" onClick={(e) => handleIncrement(e, "new_global_test_try_count")} />
                        <img src={arrowDown} className={styles.down_arrow} alt="Decrease" onClick={(e) => handleDecrement(e, "new_global_test_try_count")} />
                    </div>
                    <input
                        onKeyDown={handleKeyDown}
                        className={styles.styled_number_input}
                        value={data?.new_global_test_try_count ?? ""}
                        onChange={(e) => {
                            const value = e.target.value;
                            // Check if the value is a number and in the range 0-100
                            const numericValue = Number(value);
                            if (/^\d*$/.test(value) && (numericValue >= 0 && numericValue <= 100)) {
                                setData({
                                    ...data,
                                    new_global_test_try_count: numericValue
                                });
                            }
                        }}
                    />
                </div>

            </div>

            <div translate="yes" className={styles.certificateBlock}>
                <div className={styles.certificate}>
                    <img src={sertificateImg} alt={''}/>
                    <div>
                        <div className={styles.certificate_switch}>
                            <Switch
                                title={t('Issue certificate')}
                                onChange={() => setData({
                                    ...data,
                                    new_is_issue_certificate: +(!data?.new_is_issue_certificate)
                                })}
                                checked={data?.new_is_issue_certificate}
                            />
                        </div>
                        <p className={styles.certificate_text}>{t("Turn on this feature to provide official acknowledgment of achievements and qualifications for yourself or your users")}.</p>
                    </div>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox
                        title={t('Detailed course completion')}
                        setIsChecked={(value) => setData({...data, new_is_detailed_course_completion: +value})}
                        isChecked={data?.new_is_detailed_course_completion}/>
                        <span className={cn(styles.certificate_text, styles.ml)}>{t("Student must successfully pass all tests within the course")}</span>
                </div>
                <div className={styles.checkbox}>
                    <Checkbox
                        title={t('Show course scores')}
                        setIsChecked={(value) => setData({...data, new_is_show_course_scores: +value})}
                        isChecked={data?.new_is_show_course_scores}/>
                </div>
               <div className={styles.customize_btn}>
                  <Button onClick={()=> {
                     setActiveTab(SUBMENU_CERTIFICATE)
                  }} title={t('Customize certificate')} isGradient/>
               </div>
            </div>

          {userRoleId === ROLE_ID_SUPER_ADMIN &&
            <SelectEntities
              lastAgencyRef={lastAgencyRef}
              dataList={agenciesList}
              selectedData={selectedAgencies}
              setSelectedData={setSelectedAgencies}
              frontTitle={t('Duplicate in another company')}
              emptyMsg={'There are no companies to duplicate the course, add one!'}
           />
          }

            {/* FOR FUTURE <div className={styles.pdf_block}>
                <Switch
                    title={'Export in PDF'}
                    onChange={() => setData({
                        ...data,
                        new_is_export_in_pdf: +(!data?.new_is_export_in_pdf)
                    })}
                    checked={data?.new_is_export_in_pdf}
                />
                <p className={styles.pdf_block_text}>Give students access to export lessons so they can learn offline.
                    An export button will appear in each lesson.</p>
            </div> */}

         <CourseLanguageStatusModal
            onClose={onCloseStatusModal}
            isOpen={handleStatusModal.isActive}
            item={clickedLanguage}
            refetchData={fetchCourse}
            languages={course?.availableLanguages}
            courseId={courseId}
         />

         <ConfirmModal
            onClose={confirmModal.close}
            isOpen={confirmModal.isActive}
            isRemove
            title={t("Are you sure?")}
            subtitle={t("All translated information will be lost!")}
            onConfirm={deleteLanguageVersion}
            isLoading={isDelitingLanguageContent}
         />
        </div>
    );
};

export default BasicSettingsTab;