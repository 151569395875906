import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import avatarPlaceholder from "../../../assets/images/avatar.svg";
import TableStars from "../TableStars/TableStars";
import React, { useState } from "react";

import styles from "./styles.module.scss";
import cn from "classnames";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";

const renderCellContent = (
   column,
   row,
   maxWordsLength,
   tableFor,
   isProgressTable,
) => {
   if (column === "team_name") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
         <div className={styles.few_items}>
            <CustomTooltip
               id={row[column]}
               text={text}
               maxWidth="250px"
            />
         </div>
      );
   }
   
   if (column === "student") {
      return (
         <Link className={styles.link} target="_blank" to={`/users/profile/${row?.userId}`}>
            <CustomTooltip
               id={row[column]}
               text={row[column]}
               maxWidth="250px"
            />
         </Link>
      );
   }
   if (column === "integration" || column === 'progress') {
      const rowEmpty = row[column] === "-";

      return (
         <div className={styles.integration_column_wrapper}>
            <span>{!rowEmpty ? row[column] : "0"}%</span>
            <div className={styles.line_wrapper}>
               <div
                  style={{
                     width: `${!rowEmpty ? row[column] : 0}%`,
                  }}
                  className={styles.line}
               ></div>
            </div>
         </div>
      );
   }

   if (column === "user" && tableFor === "users") {
      return (
         <div className={styles.user_name_item}>
            <CustomTooltip
               id={row[column]}
               text={row[column]}
               maxWidth="500px"
            />
         </div>
      );
   }

   if (column === "course_name" || column === "course") {
      return (
         <div>
            <div className={cn(isProgressTable ? styles.no_margin  : '')}>
               <CustomTooltip
                  id={row[column]}
                  position="bottom"
                  text={row[column]}
                  maxWidth="500px"
               />
            </div>
         </div>
      );
   } else if (column === "rating_from_users") {
      // Assuming you have a StarRating component that takes a rating prop
      const ratingValue = parseInt(row[column], 10);
      return (
         <div className={styles.rating_wrapper}>
            <span>{ratingValue || ""}</span>
            <TableStars rating={ratingValue} total={5} />
         </div>
      );
   } else if (column === "courses") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
         <div className={cn(styles.few_items, styles.flex)}>
            <CustomTooltip
               id={row[column]}
               position={"top"}
               text={text}
               isWidthMoreThanParent
            />

            {arr?.length > 1 && <span>+{arr?.length}</span>}
         </div>
      );
   } else {
      return (
         <CustomTooltip
            id={row[column]}
            text={row[column]}
            maxWidth="500px"
         />
      );
   }
};

const StatisticsBodyItem = ({
   row,
   columns,
   index,
   onRowClick,
   tableFor,
   maxWordsLength = 20,
   rowClickable,
   isProgressTable
}) => {
    const [isHoverCourse, setIsHoverCourse] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });



   const redirectToClickedEl = () => {
      if (row.id) {
         window.open(`/statistics/user/${row.id}`, '_blank', 'noopener,noreferrer');
      }
   };

    const onMouseOverRow = (event) => {
        const rowElement = event.currentTarget; // текущий <tr>
        const firstCell = rowElement.cells[0]; // первая ячейка строки

        if (firstCell) {
            const cellRect = firstCell.getBoundingClientRect();
            setDropdownPosition({
                top: cellRect.top - cellRect.height - 60 + window.scrollY, // размещаем выше первой ячейки
                left: cellRect.left + cellRect.width / 3  + window.scrollX,
            });

            setIsHoverCourse(true);
        }
    };

    const onMouseLeaveRow = () => {
        setIsHoverCourse(false);
    };

    // render as portal because of table has overflow: hidden.
    const renderDropdownPortal = () => {
        return ReactDOM.createPortal(
            <div
                className={styles.dropdown_wrapper}
                style={{
                    position: "absolute",
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                    zIndex: 1000,
                }}
            >
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={styles.user_profile}
                >
                    <img src={row?.avatarUrl || avatarPlaceholder} alt=""/>
                    <div className={styles.user_name}>
                        <CustomTooltip
                            id={`${row.id}_user_name`}
                            limit={10}
                            text={row.name}
                            maxWidth="500px"
                        />
                    </div>
                    <div className={styles.user_email}>
                        <CustomTooltip
                            id={`${row.id}_user_email`}
                            limit={14}
                            text={row.email}
                            maxWidth="500px"
                        />
                    </div>
                </div>
            </div>,
            document.body
        );
    };

    const isTableForUsers = tableFor === 'users';

    return (
        <tr
            onClick={redirectToClickedEl}
            key={`row-${index}`}
            className={cn(
                index % 2 === 0 ? styles.rowOdd : "",
                tableFor === "teams" ? styles.link : "",
                rowClickable ? styles.clickable : ""
            )}
            onMouseOver={(e) => isTableForUsers && onMouseOverRow(e)}
            onMouseLeave={(e) => isTableForUsers && onMouseLeaveRow(e)}
        >
            {columns.map((column, columnIndex) => (
                <td
                    key={`cell-${index}-${columnIndex}`}
                >
                    <div className={styles.cellContent}
                    >
                    {renderCellContent(
                     column,
                     row,
                     maxWordsLength,
                     tableFor,
                     isProgressTable
                  )}
               </div>
            </td>
         ))}
            {isHoverCourse && isTableForUsers && renderDropdownPortal()}

      </tr>
   );
};

export default StatisticsBodyItem;
