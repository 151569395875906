import { useEffect, useState } from "react";
import useIntersectionObserver from "../../useIntersectionObserver";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { DEFAULT_DEBOUNCE_DELAY } from "../../../constants/statistics";
import DepartmentsAndPositionsApi from "../../../utils/api/DepartmentsAndPositionsApi";

const useGetDepartments = ({ order_by, order, activeFilter }) => {
   const [loading, setLoading] = useState(true);
   const [page, setPage] = useState(1);
   const [data, setData] = useState([]);
   const [searchedValue, setSearchedValue] = useState("");
   const [totalCount, setTotalCount] = useState(0);
   const companyId = useSelector(state => state.sidebar.companyId);

   const fetchData = async (passedPage, limit) => {
      setLoading(true);

      const res = await new DepartmentsAndPositionsApi().getDepartments({
         page: passedPage ? passedPage : page,
         limit: limit,
         searchQuery: searchedValue,
         order_by,
         order,
         department: activeFilter?.department,
         position: activeFilter?.position,
         companyId
      });

      if (res?.success?.data?.department) {
         const data = res?.success?.data?.department;

         const mappedData = data?.map((item) => {
            const theData = res?.success?.data?.extra_data?.find(data => Number(data?.department_id) === Number(item?.id));
            
            return {
                ...item,
                users: theData?.total_users ?? 0, 
            };
        });
        

         if (passedPage) {
            setData(mappedData);
         } else {
            setData((prev) => [...prev, ...mappedData]);
         }

         setTotalCount(res?.success?.totalCount);
      }
      
      // To fix empty array when not existed page passes.
      if(page > res?.success?.totalCount / 10) {
         setPage(1);
         setLoading(false);
         return;
      }

      if (res?.success?.data?.department?.length === 0) {
         setPage(1);
      }

      if (res?.error?.message && page) {
         setData([]);
      }

      if (!res?.success?.data?.department?.length) {
         setData([]);
      }

      setLoading(false);
   };

   const lastItemRef = useIntersectionObserver(() => {
      if (!loading && data?.length < totalCount) {
         if (data?.length === 0) {
            setPage(1);
         } else {
            setPage((prev) => prev + 1);
         }
      }
   }, [loading, totalCount]);

   useEffect(() => {
      if (page > 1 && data?.length <= totalCount) {
         fetchData();
      }
   }, [page]);

   const debouncedFetchData = debounce(
      () => fetchData(1),
      DEFAULT_DEBOUNCE_DELAY
   );

   const refetch = () => {
      setPage(1);
      debouncedFetchData();
   };

   useEffect(() => {
      setPage(1);
      debouncedFetchData();
      return () => debouncedFetchData.cancel();
   }, [searchedValue, order_by, order, activeFilter]);

   return {
      data,
      lastItemRef,
      searchedValue,
      setSearchedValue,
      loading,
      refetch,
      fetchData
   };
};

export default useGetDepartments;
