import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import Input from "../../../../components/Input/Input";
import TextArea from "../../../../components/TextArea/TextArea";
import cn from "classnames";
import LessonApi from "../../../../utils/api/LessonApi";
import ToastMsg from "../../../../components/ToastMsg/ToastMsg";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import DraggingArrows from "../../../../components/DraggingArrows/DraggingArrows";
import axios from "axios";
import SomeAnswerInputs from "./SomeAnswerInputs";
import ReactPlayer from "react-player";
import AnswerStarsOption from "./AnswerStars";
import fileUploadIcon from "../../../../assets/images/upload_white_down.svg";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import ProgressLine from "../../../../components/ProgressLine/ProgressLine";
import FilePlaceholderImg from "../../../../components/FilePlaceholderImg/FilePlaceholderImg";
import VideoQuestion from "./VideoQuestion";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MAX_VIDEO_SIZE, MAX_VIDEO_SIZE_GB} from "../../../../constants/courses";
import { CLIENT_CLOSED_REQUEST_CODE } from "../../../../constants/courses";
import { formatFileSize } from "../../../../utils/coursesHelper";
import { getTypeText, getTypeImage, questionsTypesWithoutExtraAnswers, RATING_TEN, RATING_FIVE, STARS_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, RATE_ANSWER_CODE, COMPARE_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE, ONE_CHOICE_ANSWER_CODE } from "../../../../utils/questionsHelper";
import AnswerSubheader from "./AnswerSubheader";
import AnswerRate from "./AnswerRate";
import AnswerStars from "./AnswerStars";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from "react-redux";
import UserAnswerApi from "../../../../utils/api/UserAnswerApi";
import AnswerSequence from "./AnswerSequence";
import OptionsCompare from "./OptionsCompare";
import cross from '../../../../assets/images/symbols/cross_grey.svg'
import done_icon from "../../../../assets/images/file_icons/done.png";
import error_icon from "../../../../assets/images/file_icons/error.png";
import { useParams } from "react-router-dom";
import useFindTranslationsByString from "../../../../hooks/useFindTranslationsByString";
import { isMobileDevice } from "../../../../utils/usersHelper";

const QuestionItem = ({
   question,
   questions,
   setQuestions,
   questionIndex,
   handleInputsDataChange,
   onDragEnd
}) => {
   const [uploadFileProgress, setUploadFileProgress] = useState(false);
   const [uploadedFile, setUploadedFile] = useState(null);

   const [uploadingFilesData, setUploadingFilesData] = useState([]);
   const [selectedFile, setSelectedFile] = useState(null);

   const t = useFindTranslationsByString();

   const params = useParams();
   const userId = useSelector(state => state.user.info.id);

   const stopUploadingFile = (fileToStop) => {
      setUploadingFilesData(prevData => prevData.filter(item => fileToStop?.title !== item?.title));
    };

   // Content property is actually a title for each question apart from type 9 (video answer)
   // In video answer it's title property since content is for file link there.
   const [inputsData, setInputsData] = useState({
      content: question?.content ? question?.content : "",
      answers: question?.answers ? question?.answers.map(answer=> ({
         ...answer,
         isChosen: question?.type === MULTIPLE_CHOICE_ANSWER_CODE 
                     || question?.type === ONE_CHOICE_ANSWER_CODE 
                     || question?.type === TRUE_FALSE_ANSWER_CODE ? false : true
      })) : [],
      chosenFileType: "",
      isShuffled: false,
      ...(question?.description ? { description: question?.description } : {}),
      ...(question?.comment ? { comment: question?.comment } : {}),
      ...(question?.title ? { title: question?.title } : {}),
      ...(question.rating && { rating: question.rating }),
      ...(question.sequence && { sequence: question.sequence }),
      ...(question.filetype &&
         question?.type !== VIDEO_ANSWER_CODE && {
            filetype: question.filetype,
         }),
      ...(question?.options?.length > 0 && { options: question.options }),
   });

   const onSelectAnswer = (id) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData.answers.map((answer) =>
            answer.id === id
               ? { ...answer, isChosen: !answer.isChosen }
               : answer
         ),
      }));
   };

   const onChangeAnswerText = (text, id) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData?.answers?.map((answer) =>
            answer.id === id ? { ...answer, content: text } : answer
         ),
      }));
   };

   const onSelectCompareAnswer = (ans, rowIdx) => {
      setInputsData((prevInputsData) => {
          const updatedAnswers = prevInputsData.answers?.map((answer, idx) => {
            
              if (answer.matchingOption === rowIdx) {
                  return {
                      ...answer,
                      new_content: ans?.selected_content, 
                      ...(answer.id === ans.id && {
                        selected_content: ans?.selected_content,
                        selected_letter: ans.displayed,
                    }),
                  };
              }

              if (answer.id === ans.id) {
                  return {
                      ...answer,
                      selected_content: ans?.selected_content,
                      selected_letter: ans.displayed,
                  };
              }
  
              return answer;
          });
  
          return {
              ...prevInputsData,
              answers: updatedAnswers,
          };
      });
  };
  
  
   const onChangeWrittenAnswerText = (text) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: ([{ ...prevInputsData.answers[0], content: text }]),
      }));
   };

   const onChooseRate = (rate) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: ([{ ...prevInputsData.answers[0], content: `${rate}` }]),
      }));
   };

   const onSelectAnswerRadioStars = (rating) => {
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         rating: rating,
      }));
   };

   const onChangeTitle = (e) => {
      setInputsData({ ...inputsData, content: e?.target?.value });
   };

   const onChangeVideoQuestionTitle = (e) => {
      setInputsData({ ...inputsData, title: e?.target?.value });
   };

   const onChangeDesc = (e) => {
      setInputsData({ ...inputsData, description: e.target.value });
   };

   useEffect(() => {
      if (handleInputsDataChange) {
         handleInputsDataChange(inputsData, questionIndex);
      }
   }, [inputsData, questionIndex]);

   const handleAutoSubmit = async (file, remove) => {
      if (file.size > MAX_VIDEO_SIZE) {
         setUploadingFilesData(prevData => {
            const updatedData = prevData?.map(item => {
               if (item?.title === file?.name) {
                  return { ...item, errorMsg: 'File size exceeds maximum allowed size.' };
               }
               return item;
            });
            return updatedData;
         });

         return toast(
            <ToastMsg
               text={`File size exceeds the maximum allowed size of ${MAX_VIDEO_SIZE_GB} GB.`}
               isError
            />
         );
      }

      const dataToGetUrl = [
         {
            user_id: userId,
            question_id: question?.id,
            answer_id: inputsData?.answers[0]?.id,
            filename: file.name,
         },
      ];

      const res = await new UserAnswerApi().getPresignedQuestionFilesUploadUrls(
         dataToGetUrl
      );

      if(res?.error?.message) {
         remove();
         return toast(<ToastMsg text={res?.error?.message} isError />);
       }

      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
      const amazonVideoUrl =
         res?.success?.data?.[0]?.success?.data?.file_signed_url;

      const cancelTokenSource = axios.CancelToken.source();

      const resUpload = await axios({
         method: "PUT",
         url: uploadUrl,
         headers: {
            "Content-Type": file.type,
         },
         data: file,
         onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return; 

            const progress = Math.round(
               (progressEvent.loaded / progressEvent.total) * 100
            );

            setUploadingFilesData(prevData => {
               const updatedData = prevData.map(item => {
                  if(!prevData?.some(prevItem => prevItem.title === file?.name)) {
                     cancelTokenSource.cancel(CLIENT_CLOSED_REQUEST_CODE);
                  }

                   if (item.title === file.name) {
                       return { ...item, progress };
                   }
                   return item;
               });

               if(!prevData.length) {
                  cancelTokenSource.cancel(CLIENT_CLOSED_REQUEST_CODE);
               }

               return updatedData;
           });
         },
         cancelToken: cancelTokenSource.token 
      }).catch(err => {
         if(err?.message !== CLIENT_CLOSED_REQUEST_CODE) toast(<ToastMsg text={err?.message || 'Something went wrong'} isError />)
       });

      if (resUpload) {
         setUploadingFilesData(prevData => {
            const updatedData = prevData.map(item => {
                if (item.title === file.name) {
                    return { ...item, url: amazonVideoUrl, progress: 100 };
                }
                return item;
            });
            return updatedData;
        });
      }
   };

   useEffect(()=> {
      if(uploadingFilesData?.length) {
         const urls = uploadingFilesData?.map(f => f?.url)?.join();

         setInputsData((prevInputsData) => {
            const prevUrls = prevInputsData.answers[0]?.content || '';
            if (prevUrls === urls) return prevInputsData; // Avoid redundant updates
            
            return {
               ...prevInputsData,
               answers: [{ ...prevInputsData.answers[0], content: urls }],
            };
         });
      }
   }, [uploadingFilesData])

   const handleChangeStatus = ({ meta, file, remove }, status) => {
      if (status === "done" && !uploadingFilesData?.find(item => item?.title === file?.name)) {
         setUploadingFilesData([...uploadingFilesData, { title: file?.name, ext: file?.type, fileSize: file?.size, file }])

         handleAutoSubmit(file, remove);
      } 
   };   

   const getFileObjectURL = (file) => {
      try {
        // Check if browser supports object URLs and file type is valid
        if (window.URL && window.URL.createObjectURL && file instanceof File) {
          return window.URL.createObjectURL(file);
        }
      } catch (error) {
        console.error("Error creating object URL:", error);
      }
      return "";
    };

   const handleFileSelection = (file) => {
      setSelectedFile(file === selectedFile ? null : file);
    };

    const removeFile = () => {
      setUploadedFile(null);

      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: [{ ...prevInputsData.answers[0], content: 'Default text' }],
      }));

      uploadedFile.remove();
    }    

    const renderAdminViewAnswers = (question) => {
      return (
         <div className={cn(
            styles.answers,
            question?.type === FILE_UPLOAD_ANSWER_CODE || 
            question?.type === VIDEO_ANSWER_CODE || 
            question?.type === WRITTEN_ANSWER_CODE ? styles.hide : ''
         )}>
               <div className={styles.content_block}>
                  <div
                     className={cn(
                        styles.answers_container,
                        question.type === STARS_ANSWER_CODE ? styles.flexed : "",
                     )}
                  >

                     {inputsData.answers?.map((item, key) => (
                        <SomeAnswerInputs
                           key={key}
                           inputsData={inputsData}
                           setInputsData={setInputsData}
                           onChangeAnswerText={onChangeAnswerText}
                           onSelectAnswer={onSelectAnswer}
                           type={question?.type}
                           item={item}
                           answerIdx={key}
                        />
                     ))}
                     
                  <div className={cn(
                              styles.compare_wrapper_el,
                              question?.type !== COMPARE_ANSWER_CODE ? styles.d_none : ''
                           )}>
                              
                     {question?.type === COMPARE_ANSWER_CODE && 
                        <div className={styles.compare_options}>
                           {inputsData.answers?.map((item, key) => (
                              <OptionsCompare
                                 key={key}
                                 inputsData={inputsData}
                                 type={COMPARE_ANSWER_CODE}
                                 item={item}
                                 answerIdx={key}
                              />
                           ))} 
                        </div>
                     }

                     {question?.type === COMPARE_ANSWER_CODE && (
                        <Droppable droppableId={`${question?.id}`}>
                              {(provided, snapshot) => (
                              <div
                               {...provided.droppableProps}
                                 ref={provided.innerRef}
                                 className={cn(
                                    styles.draggable_answers
                                 )}
                               >
                                 {inputsData.answers?.map((item, key) => (
                                    <Draggable key={`${item?.id}`} draggableId={`${item?.id}`} index={key}>
                                    {(provided, snapshot) => (
                                       <div
                                          className={styles.draggable_answer}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                       >
                                             <div
                                                key={key}
                                                className={cn(
                                                      styles.answer, styles.compare_answer,
                                                      snapshot.isDragging ? styles.dragging_answer : ''
                                                )}
                                             >
                                                <div
                                                   className={cn(
                                                      styles.answer_block,
                                                      item?.isChosen && styles.answer_block_isSelected,
                                                   )}
                                                >
                                                   <DraggingArrows isDragging={snapshot.isDragging} />
                                                   <div className={styles.compare_option}>
                                                      {item?.content}
                                                   </div>
                                                </div>
                                             </div>
                                       </div>
                                    )}
                                    </Draggable>
                                 ))}
                                 {provided.placeholder}
                              </div>
                              )}
                           </Droppable>
                        )}
                     </div>
                     
                     {question?.type === SEQUENCE_ANSWER_CODE && (
                        <Droppable droppableId={`${question?.id}`}>
                           {(provided, snapshot) => (
                           <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggable_questions}>
                              {inputsData.answers?.map((item, key) => (
                                 <Draggable 
                                    key={`${item?.id}`} 
                                    draggableId={`${item?.id}`} 
                                    index={key}
                                 >
                                 {(provided, snapshot) => (
                                    <div
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                    >
                                       <AnswerSequence
                                          key={key}
                                          inputsData={inputsData}
                                          setInputsData={setInputsData}
                                          type={SEQUENCE_ANSWER_CODE}
                                          item={item}
                                          answerIdx={key}
                                          isDragging={snapshot.isDragging}
                                       />
                                    </div>
                                 )}
                                 </Draggable>
                              ))}
                              {provided.placeholder}
                           </div>
                           )}
                        </Droppable>
                     )}
                  
                     {question?.type === RATE_ANSWER_CODE && 
                        <AnswerRate 
                           choosedRate={+inputsData?.answers[0]?.content} 
                           onChooseRate={onChooseRate} 
                        /> 
                     }
                     
                     {question?.type === STARS_ANSWER_CODE && 
                        <AnswerStars 
                           total={inputsData?.rating}
                           onChooseStar={onChooseRate}
                           choosedStar={+inputsData?.answers[0]?.content}
                        /> 
                     }
                  </div>
               </div>
            </div>
      )
    } 
   return (
      <div className={styles.wrapper}>
         <DragDropContext onDragEnd={onDragEnd}>
            <div className={cn(styles.question)}>
               <div className={styles.header}>
                  <div className="my_flex_center">
                     <span className={styles.question_num}>
                        {t('Task')} {questionIndex + 1} of {questions.length}:
                     </span>
                  </div>
                  <div className={styles.header_title}>
                     <img
                        className={styles.question_icon}
                        src={getTypeImage(question?.type, true)}
                        alt="icon"
                     />
                     <span className={styles.question_type_text}>
                        {t(getTypeText(question?.type))}
                     </span>
                  </div>
               </div>
               <div className={styles.content_block}>
                  <div className={styles.question_title}>
                     {question?.type === VIDEO_ANSWER_CODE
                        ? question?.title
                        : question?.content}
                  </div>
                  <div className={styles.question_description}>
                     {question?.description}
                  </div>

                  {question?.type === VIDEO_ANSWER_CODE && (
                     <VideoQuestion
                        inputsData={inputsData}
                        setInputsData={setInputsData}
                        question={question}
                     />
                  )}
               </div>
            </div>

         {params?.statisticsUserId && 
            renderAdminViewAnswers(question)
         }
            
         {!params?.statisticsUserId && 
            <div className={styles.answers}>
            <AnswerSubheader questionType={question?.type} questionRate={question?.rating} />

               <div className={styles.content_block}>
                  <div
                     className={cn(
                        styles.answers_container,
                        question.type === STARS_ANSWER_CODE ? styles.flexed : "",
                     )}
                  >

                     {inputsData.answers?.map((item, key) => (
                        <SomeAnswerInputs
                           key={key}
                           inputsData={inputsData}
                           setInputsData={setInputsData}
                           onChangeAnswerText={onChangeAnswerText}
                           onSelectAnswer={onSelectAnswer}
                           type={question?.type}
                           item={item}
                           answerIdx={key}
                        />
                     ))}
                     
                  <div className={cn(
                              styles.compare_wrapper_el,
                              question?.type !== COMPARE_ANSWER_CODE ? styles.d_none : ''
                           )}>
                     {question?.type === COMPARE_ANSWER_CODE && 
                        <div className={styles.compare_options}>
                           {inputsData.answers?.map((item, key) => (
                              <OptionsCompare
                                 key={key}
                                 inputsData={inputsData}
                                 type={COMPARE_ANSWER_CODE}
                                 item={item}
                                 answerIdx={key}
                              />
                           ))}
                        </div>
                     }

                      {question?.type === COMPARE_ANSWER_CODE && 
                        <div className={styles.compare_mobile_selects}>
                           {inputsData.answers?.map((item, key) => (
                              <div key={key} className={styles.select_item}>
                                 <Dropdown
                                   data={
                                       inputsData.answers?.map((ans, i)=> ({
                                             displayed: String.fromCharCode(65 + i),  
                                             index: i,
                                             id: item?.id,
                                             selected_content: inputsData.answers[i]?.content,
                                             matching_option: inputsData.answers[i]?.matchingOption 
                                       }))
                                    }
                                    placeholder={t("Select")}
                                    field={`displayed`}
                                    value={item?.selected_letter}
                                    onChange={(item)=> {
                                       onSelectCompareAnswer(item, key)
                                    }}
                                 />
                              </div>
                           ))}

                        </div> 
                     }

                     {question?.type === COMPARE_ANSWER_CODE && (
                        <Droppable droppableId={`${question?.id}`}>
                              {(provided, snapshot) => (
                              <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggable_answers}>
                                 {inputsData.answers?.map((item, key) => (
                                    <Draggable key={`${item?.id}`} draggableId={`${item?.id}`} index={key}>
                                    {(provided, snapshot) => (
                                       <div
                                          className={styles.draggable_answer}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                       >
                                             <div
                                                key={key}
                                                className={cn(
                                                      styles.answer, styles.compare_answer,
                                                      snapshot.isDragging ? styles.dragging_answer : ''
                                                )}
                                             >
                                                <div
                                                   className={cn(
                                                      styles.answer_block,
                                                      item?.isChosen && styles.answer_block_isSelected,
                                                   )}
                                                >
                                                   <DraggingArrows isDragging={snapshot.isDragging} />
                                                   <div className={styles.compare_option}>
                                                      {item?.content}
                                                   </div>
                                                </div>
                                             </div>
                                       </div>
                                    )}
                                    </Draggable>
                                 ))}
                                 {provided.placeholder}
                              </div>
                              )}
                           </Droppable>
                        )}
                     </div>

                     
                     {question?.type === COMPARE_ANSWER_CODE && 
                        <div className={styles.compare_mobile}>
                          {inputsData.answers?.map((item, key) => (
                              <div key={key} className={styles.compare_mobile_ans_items}>
                                 <span className={styles.letters}>{String.fromCharCode(97 + key) + ")"}</span>
                                 <div>{item?.content}</div>
                              </div>
                          ))}
                        </div> 
                     }
                     

                     {question?.type === SEQUENCE_ANSWER_CODE && (
                        <Droppable droppableId={`${question?.id}`}>
                           {(provided, snapshot) => (
                           <div {...provided.droppableProps} ref={provided.innerRef} className={styles.draggable_questions}>
                              {inputsData.answers?.map((item, key) => (
                                 <Draggable 
                                    key={`${item?.id}`} 
                                    draggableId={`${item?.id}`} 
                                    index={key}
                                 >
                                 {(provided, snapshot) => (
                                    <div
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                    >
                                       <AnswerSequence
                                          key={key}
                                          inputsData={inputsData}
                                          setInputsData={setInputsData}
                                          type={SEQUENCE_ANSWER_CODE}
                                          item={item}
                                          answerIdx={key}
                                          isDragging={snapshot.isDragging}
                                       />
                                    </div>
                                 )}
                                 </Draggable>
                              ))}
                              {provided.placeholder}
                           </div>
                           )}
                        </Droppable>
                     )}
                  

                     {question?.type === WRITTEN_ANSWER_CODE && 
                        <TextArea
                           isResize
                           minRows={10}
                           placeholder={t("Type your answer")}
                           value={inputsData?.answers[0]?.content}
                           onChangeValue={(e)=> onChangeWrittenAnswerText(e.target.value)}
                        />
                     }

                     {question?.type === RATE_ANSWER_CODE && 
                        <AnswerRate 
                           choosedRate={+inputsData?.answers[0]?.content} 
                           onChooseRate={onChooseRate} 
                        /> 
                     }
                     
                     {question?.type === STARS_ANSWER_CODE && 
                        <AnswerStars 
                           total={inputsData?.rating}
                           onChooseStar={onChooseRate}
                           choosedStar={+inputsData?.answers[0]?.content}
                        /> 
                     }

                     {(question?.type === FILE_UPLOAD_ANSWER_CODE ||
                      question?.type === VIDEO_ANSWER_CODE) && 
                        <div key={question?.id} className={styles.file_upload_wrapper}>
                           <Dropzone
                              onChangeStatus={handleChangeStatus}
                              multiple={true}
                              accept={question?.type === VIDEO_ANSWER_CODE ? "video/*" : "*"}
                              inputContent={
                              <span className={styles.label_with_image}>
                                    <img src={fileUploadIcon} alt=""/>
                                   <span>{t("Upload file")}</span>
                              </span>
                           }
                              inputWithFilesContent={
                                <span className={styles.label_with_image}>
                                    <img src={fileUploadIcon} alt=""/>
                                   <span>{t("Upload file")}</span>
                                </span>
                              }
                              classNames={{
                                 dropzone: styles.custom_dropzone,
                                 dropzoneActive: styles.custom_dropzone_active,
                                 inputLabel: styles.custom_input_label,
                                 fileName: styles.custom_filename,
                              }}
                           />
                        </div>
                     }
                     {/* FILE UPLOAD AND VIDEO UPLOAD QUESTIONS */}
                     {!!uploadingFilesData?.length && (
                        <div className={styles.list}>
                           {uploadingFilesData?.map((item, idx) => (
                              <div onClick={()=> handleFileSelection(item?.title)} key={idx} className={cn(styles.list_item)}>
                                 <div className={styles.file_placeholder_wrapper}>
                                    <FilePlaceholderImg file={item}/>
                                 </div>
                                 <div className={styles.files_display}>
                                    <div className={styles.filename}>
                                       <CustomTooltip
                                          id={`${item?.title}name`}
                                          text={item?.title || 'no name'}
                                          limit={30}
                                          maxWidth={"300px"}
                                          delay={500}
                                       />
                                    </div>

                                    {item?.ext?.startsWith("video/") && selectedFile === item.title ? (
                                       <video 
                                          onClick={(e)=> e.stopPropagation()}
                                          controls>
                                          <source
                                             src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                                             type={item.ext}
                                          />
                                       </video>
                                    ) : item?.ext?.startsWith("audio/")  && selectedFile === item.title ? (
                                       <audio  
                                          onClick={(e)=> e.stopPropagation()}
                                          controls>
                                          <source
                                             src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                                             type={item.ext}
                                          />
                                       </audio>
                                    ) : item?.ext?.startsWith("image/") && selectedFile === item.title ? (
                                       <img
                                          src={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)}
                                          alt={`File ${idx}`}
                                       />
                                    ) : selectedFile === item.title ? (
                                       <a onClick={(e)=> e.stopPropagation()} href={item?.uploadedFileLink ? item?.uploadedFileLink : getFileObjectURL(item?.file)} target={'_blank'}>{item?.title || '-'}</a>
                                    ) : (
                                       ""
                                    )}
                                 </div>
                                 {item?.progress !== 100 && !item?.errorMsg && (
                                    <ProgressLine
                                       dashIsNotNeeded
                                       progress={item?.progress}
                                    />
                                 )}

                                 {item?.fileSize && (
                                    <div className={styles.file_size}>
                                       {formatFileSize(item?.fileSize)}
                                    </div>
                                 )}

                                 {item?.progress === 100 && (
                                    <div className={cn(styles.done, 'noselect')}>
                                       <img src={done_icon} alt="" />
                                       <div>{t('Download done')}</div>
                                    </div>
                                 )}

                                 {item?.errorMsg && (
                                    <div className={cn(styles.done, 'noselect')}>
                                       <img src={error_icon} alt="" />
                                       <div>{item?.errorMsg}</div>
                                    </div>
                                 )}

                                 <img
                                    className={cn(styles.cross, 'noselect')}
                                    src={cross}
                                    onClick={() => stopUploadingFile(item)}
                                 ></img>
                              </div>
                           ))}
                        </div>
                     )}
                  </div>
               </div>
            </div>
            }
         </DragDropContext>
      </div>
   );
};

export default React.memo(QuestionItem);
