import ReactDOM from "react-dom";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import styles from "./styles.module.scss";
import cn from "classnames";
import { useParams } from "react-router-dom";
import React, { useState, useRef } from "react";
import CourseBlockDropdown from "../../../components/CourseBlockDropdown/CourseBlockDropdown";

const StatisticsBodyItem = ({
  row,
  columns,
  index,
  onRowClick,
  tableFor,
  maxWordsLength = 20,
  rowClickable,
}) => {
  const params = useParams();
  const [isHoverCourse, setIsHoverCourse] = useState(false);
  const [activeRow, setActiveRow] = useState({});
  const [activeColumnIndex, setActiveColumnIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const cellRef = useRef(null);

  const redirectToClickedEl = () => {
    if (row?.courseId) {
      window.open(`/courses/course/${row.courseId}/${params.id}`, "_blank");
    }
  };

  const renderCellContent = (column, row, maxWordsLength) => {
    if (column === "team_name") {
      if (row[column]?.length === 0) return "-";
      const arr = row[column]?.split(",");
      const text = arr?.map((name) => name?.trim())?.join(", ") || row[column];

      return (
        <div className={styles.few_items}>
          <CustomTooltip
            id={row[column]}
            limit={maxWordsLength}
            text={text}
            maxWidth="250px"
          />
        </div>
      );
    }
    if (column === "progress") {
      const rowEmpty = row[column] === "-";

      return (
        <div className={styles.integration_column_wrapper}>
          <span>{!rowEmpty ? row[column] : "0"}%</span>
          <div className={styles.line_wrapper}>
            <div
              style={{
                width: `${!rowEmpty ? row[column] : 0}%`,
              }}
              className={cn(
                styles.line,
                +row[column] === 100 ? styles.line_green : "",
              )}
            ></div>
          </div>
        </div>
      );
    }

    if (column === "course_name") {
      return (
        <div>
            <CustomTooltip
              id={row[column]}
              limit={maxWordsLength}
              text={row[column]}
              maxWidth="500px"
            />
        </div>
      );
    } else {
      return (
        <CustomTooltip
          id={row[column]}
          limit={maxWordsLength}
          text={row[column]}
          maxWidth="500px"
        />
      );
    }
  };

  const onMouseOverCourse = (data, columnIndex, event) => {
    setIsHoverCourse(true);
    setActiveRow({
      id: data?.courseId,
      image: data?.courseImageUrl || data?.courseImage,
      title: data?.courseName,
      description: data?.courseDescription,
    });
    setActiveColumnIndex(columnIndex);

    // getting coordinates of cell
    const cellRect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: cellRect.bottom - cellRect.height / 2 + window.scrollY, // учитываем прокрутку страницы
      left: cellRect.left + window.scrollX,
    });
  };
  const onMouseLeave = () => {
    setIsHoverCourse(false);
    setActiveRow({});
    setActiveColumnIndex(null);
  };

  // render as portal because of table has overflow: hidden.
  const renderDropdownPortal = () => {
    return ReactDOM.createPortal(
      <div
        className={styles.dropdown_wrapper}
        style={{
          position: "absolute",
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          zIndex: 1000,
        }}
      >
        <div className={styles.course_dropdown}>
          <CourseBlockDropdown course={activeRow} />
        </div>
      </div>,
      document.body,
    );
  };

  return (
    <tr
      onClick={redirectToClickedEl}
      key={`row-${index}`}
      className={cn(
        index % 2 === 0 ? styles.rowOdd : "",
        tableFor === "teams" ? styles.link : "",
        rowClickable ? styles.clickable : "",
      )}
    >
      {columns.map((column, columnIndex) => (
        <td
          key={`cell-${index}-${columnIndex}`}
          onMouseLeave={onMouseLeave}
          onMouseOver={(event) => onMouseOverCourse(row, columnIndex, event)}
          className={cn(
            isHoverCourse && row?.courseId === activeRow?.id
              ? styles.active
              : "",
          )}
          ref={cellRef}
        >
          <div className={styles.cellContent}>
            {renderCellContent(column, row, maxWordsLength)}
          </div>

          {
            isHoverCourse &&
              row?.courseId === activeRow?.id &&
              activeColumnIndex === columnIndex &&
              renderDropdownPortal() // Используем портал для рендеринга dropdown
          }
        </td>
      ))}
    </tr>
  );
};

export default StatisticsBodyItem;
