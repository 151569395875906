import React, { useEffect, useRef, useState } from "react";
import { UPLOAD_LARGE_LOGO_TEXT, UPLOAD_SMALL_LOGO_TEXT, USERS_AND_ROLES_COLUMNS } from "../../constants/agencies";
import { useNavigate, useParams } from "react-router-dom";
import useAutoScroll from "../../hooks/useAutoScroll";
import useHandleModal from "../../hooks/dom/useHandleModal";
import AgencyApi from "../../utils/api/AgencyApi";
import TranslationApi from "../../utils/api/TranslationApi";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';

import IconUploadModal from "./UploadModals/IconUploadModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import CompanyLanguageStatusModal from "../../components/Modals/CompanyLanguageStatusModal/CompanyLanguageStatusModal";
import AgencySettingsSubheader from "./AgencySettingsSubheader/AgencySettingsSubheader";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import Button from "../../components/Button/Button";

import AgencySettingsSidebar from "./AgencySettingsSidebar/AgencySettingsSidebar";
import BasicBlock from "./BasicBlock/BasicBlock";
import LogoBlock from "./LogoBlock/LogoBlock";
import ComingSoonBlock from "./ComingSoonBlock/ComingSoonBlock";
import SecurityBlock from "./SecurityBlock/SecurityBlock";
import IconEditModal from "./UploadModals/IconEditModal";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import BranchApi from "../../utils/api/BranchApi";
import { setCompanyIcon, setCompanyName } from "../../store/actions/sidebar";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import UsersAndRolesTable from "./UsersAndRolesTable/UsersAndRolesTable";
import UsersSubheader from "./UsersSubheader/UsersSubheader";
import CompanyUsersAndTeamsModal from "../../components/Modals/CompanyUsersAndTeamsModal/CompanyUsersAndTeamsModal";

import styles from "./styles.module.scss";
import cn from 'classnames';
import { DEFAULT_DATA_LIMIT, ROLE_ID_STUDENT, ROLE_ID_SUPER_ADMIN, mappedExcludedUsers } from "../../constants/user";
import { ALL_LANGUAGES_LIST, DEFAULT_LANG_LOCALE } from "../../constants/languages";
import statusActiveImg from "../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from '../../assets/images/paused_status.svg'
import arrow from "../../assets/images/symbols/Arrow - Down.svg";
import deleteIcon from '../../assets/images/symbols/delete.svg';
import settings_img from '../../assets/images/menu/settings.svg';

import UserApi from "../../utils/api/UserApi";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { DEFAULT_DEBOUNCE_DELAY } from "../../constants/statistics";
import TeamApi from "../../utils/api/TeamApi";
import { mapUsersWithNewLinks, returnTemporaryCompanyLink, returnTemporaryLink, returnTemporaryLinks } from "../../utils/usersHelper";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useGetAllTeams from "../../hooks/api/useGetAllTeams";
import { TEAM_EXTRA_GROUP_TABLE, COMPANY_EXTRA_GROUP_ONE } from "../../utils/api/serialization_groups";
import useFindTranslations from "../../hooks/useFindTranlsations";
import useUserRole from "../../hooks/useUserRole";

export default function AgencySettingsPage() {
   const navigate = useNavigate();
   const params = useParams();
   const dispatch = useDispatch();

   const userId = useSelector(state => state?.user?.info?.id);
   const userInfo = useSelector(state => state?.user?.info);
   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const currentCompanyBranchId = useSelector(state => state.sidebar.currentCompanyBranchId);
   const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

   const t = useFindTranslations();

   const companyUsersAndTeamsModal = useHandleModal();
   const deleteUserModal = useHandleModal();

   const [searchedValue, setSearchedValue] = useState();
   const [selectedUserList, setSelectedUserList] = useState([]);
   const [selectedTeamList, setSelectedTeamList] = useState([]);
   const [changeLoading, setChangeLoading] = useState(false);

   const [excludedTeams, setExcludedTeams] = useState([]);
   
   const [excludedUsers, setExcludedUsers] = useState([]);
   const [excludedUsersPage, setExcludedUsersPage] = useState(1);
   const [totalExcludedUser, setTotalExcludedUser] = useState(1);
   const [excludedUsersLoading, setExcludedUsersLoading] = useState(false);
   const [excludedUsersSearch, setExcludedUsersSearch] = useState('');

   const [usersOfCompany, setUsersOfCompany] = useState([]);
   const [usersOfCompanyPage, setUsersOfCompanyPage] = useState(1);
   const [usersOfCompanyTotal, setUsersOfCompanyTotal] = useState(0);

   const [currentModalUser, setCurrentModalUser] = useState({});
   const [currentAgency, setCurrentAgency] = useState({});
   const [lightCurrentAgency, setLightCurrentAgency] = useState({});
   const [agencyFavicon, setAgencyFavicon] = useState(null);
   const [agencyLargeIcon, setAgencyLargeIcon] = useState(null);
   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
   const [agencyLoading, setAgencyLoading] = useState(true);
   const [isDeleteLoading, setIsDeleteLoading] = useState(false);
   const [usersOfCompanyLoading, setUsersOfCompanyLoading] = useState(true);
   const [activeTab, setActiveTab] = useState('settings');
   const [isDoubleCheckForRealCompanyNeeded, setIsDoubleCheckForRealCompanyNeeded] = useState(false);

   // Departments and positions and not excluded companies filters
   const [selectedDepartment, setSelectedDepartment] = useState('');
   const [selectedPosition, setSelectedPosition] = useState('');
   const [selectedCompany, setSelectedCompany] = useState('');

   // State for showing icons on the screen
   const [renderedFavicon, setRenderedFavicon] = useState(null);
   const [renderedLargeIcon, setRenderedLargeIcon] = useState(null);

   const [clickedLanguage, setClickedLanguage] = useState(null);
   const [isDelitingLanguageContent, setIsDelitingLanguageContent] = useState(false);

   const handleStatusModal = useHandleModal();
   const confirmDeleteLangModal = useHandleModal();

   const onCloseStatusModal = () => handleStatusModal.close();

   const onOpenStatusModal = (item) => {
      setClickedLanguage(item);
      handleStatusModal.open();
   };

   const onOpenConfirmModal = (item) => {
      confirmDeleteLangModal?.open();
     setClickedLanguage(item);
   }

   const [basicData, setBasicData] = useState({
      agencyName: "",
      agencyDesc: "",
      agencyEmail: "",
      selectedTimeZone: "",
      privacyChecked: 0,
      allowRegistrationChecked: 0
    });
   
   const [securityConditions, setSecurityConditions] = useState({
      content_and_context_menu_disabled: 0,
      clickboard_disabled: 0,
      provide_access_by_domain: 0,
      strong_password: 0,
      provide_access_to_ip: 0,
      two_factor_auth: 0,
   });

   const deleteLanguageVersion = async () => {
      setIsDelitingLanguageContent(true);

      const data = [{
         company_id: params?.id,
         locale: clickedLanguage?.code
      }]

      const res = await new TranslationApi().deleteCompanyTranslations(data);

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message}/>)
      }

      if(res?.error?.message) {
         toast(<ToastMsg isError text={res?.error?.message}/>)
      }

      confirmDeleteLangModal.close();

      setIsDelitingLanguageContent(false);
    }

   const openDeleteUserFromCompanyModal = (user) => {
      setCurrentModalUser(user);
      deleteUserModal.open();
   } 

   const deleteUserFromCompany = async () => {
      setIsDeleteLoading(true);
      let msg = '';

      const rolesToDeleteInCompanyBranch = currentModalUser?.roles?.filter(role => role?.branch?.company?.id === currentAgency?.id);

      for(let i = 0; i < rolesToDeleteInCompanyBranch?.length; i ++) {
         const res = await new BranchApi().deleteUserRoleInBranch(currentAgency?.branches[0]?.id, currentModalUser?.id, rolesToDeleteInCompanyBranch?.[i]?.role?.id);

         if(res?.success?.message) {
            msg = res?.success?.message
         }
   
         if(res?.error?.message) {
            let msg = res?.error?.message?.includes('users should always have at least one role') ? "Users cannot be removed from a company as they must always be associated with at least one company and have an assigned role" : res?.error?.message;

            toast(<ToastMsg text={msg} isError />);
         }
      }

      if(msg.length) {
         setUsersOfCompany((prevUsers) => prevUsers.filter(user => user.id !== currentModalUser?.id));
         setUsersOfCompanyTotal(usersOfCompanyTotal - 1);
         
         toast(<ToastMsg text={msg} />);

         deleteUserModal.close();
      }

      setIsDeleteLoading(false);
   }

   const [termsChecked, setTermsChecked] = useState(false);

   const faviconUploadModal = useHandleModal();
   const largeIconUploadModal = useHandleModal();
   const faviconEditModal = useHandleModal();
   const largeIconEditModal = useHandleModal();
   const confirmModal = useHandleModal();

   const {
      activeElement,
      setActiveElement,
      handleListItemClick,
      basicBlockRef,
      logoBlockRef,
      // domainBlockRef,
      // sendEmailBlockRef,
      // integrationBlockRef,
      endBlockRef,
      sectionRef,
   } = useAutoScroll();

   const addOptionalPropsForCreateAgency = () => {
      const obj = {
         name: basicData.agencyName,
         ...(basicData.agencyDesc.length && { description: basicData.agencyDesc }),
         ...(basicData.agencyEmail.length && { email: basicData.agencyEmail }),
         ...(basicData.selectedTimeZone.length && { time_zone: basicData.selectedTimeZone }),
         privacy: basicData.privacyChecked,
         allow_registration: basicData.allowRegistrationChecked,
         content_and_context_menu_disabled: securityConditions.content_and_context_menu_disabled,
         clickboard_disabled: securityConditions.clickboard_disabled,
         provide_access_by_domain: securityConditions.provide_access_by_domain,
         strong_password: securityConditions.strong_password,
         provide_access_to_ip: securityConditions.provide_access_to_ip,
         two_factor_auth: securityConditions.two_factor_auth,
      };

      return obj
   }

   const addOptionalPropsForUpdateAgency = () => {
      // If we meet the same email or name we get an error 400
      // We include that condition to prevent adding props in the obj at all
      const hasNewName = currentAgency.name !== basicData.agencyName;
      const hasNewEmail = basicData.agencyEmail.length && currentAgency.email !== basicData.agencyEmail

      // Update curent agency state for next invoke to meet condition above
      setCurrentAgency((prevAgency) => ({
         ...prevAgency,
         name: basicData.agencyName,
         email: basicData.agencyEmail,
       }));

      const obj = {
         ...(hasNewName && { new_name: basicData.agencyName }),
         ...(hasNewEmail && { new_email: basicData.agencyEmail }),
         ...(basicData.agencyDesc.length && { new_description: basicData.agencyDesc }),
         ...(basicData.selectedTimeZone.length && { new_time_zone: basicData.selectedTimeZone }),
         new_privacy: basicData.privacyChecked ? 1 : 0,
         new_allow_registration: basicData.allowRegistrationChecked ? 1 : 0,
         new_content_and_context_menu_disabled: securityConditions.content_and_context_menu_disabled,
         new_clickboard_disabled: securityConditions.clickboard_disabled,
         new_provide_access_by_domain: securityConditions.provide_access_by_domain,
         new_strong_password: securityConditions.strong_password,
         new_provide_access_to_ip: securityConditions.provide_access_to_ip,
         new_two_factor_auth: securityConditions.two_factor_auth,
      };

      return obj
   }

   const createDefaultBranch = async(companyId) => {
      const res = await new BranchApi().createBranch({
         name: 'Default branch',
         company_id: `${companyId}`
      })

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError/>)
      } 
   }

   const createAgencyHandler = async () => {
      // Needed to prevent sending empty strings and objects
      const agencyDataToSend = addOptionalPropsForCreateAgency();
      const resustOfCreateCompany = await new AgencyApi().createAgency(agencyDataToSend);

      if (resustOfCreateCompany?.success?.data) {
         const companyId = +resustOfCreateCompany.success.data.company?.id

         createDefaultBranch(+resustOfCreateCompany.success.data.company?.id);
       
         navigate(`/companies/settings/${companyId}`);
         setIsDoubleCheckForRealCompanyNeeded(true);
         sendFavicon(companyId);
         sendLargeIcon(companyId)
         
         return toast(<ToastMsg text={resustOfCreateCompany?.success?.message}/>)
      }

      if (resustOfCreateCompany?.error?.message) {
         return toast(<ToastMsg text={resustOfCreateCompany?.error?.message} isError/>)
      } 
   }

   const updateAgencyHandler = async () => {
      // Needed for "edit spacific agency"
      const agencyDataToUpdate = addOptionalPropsForUpdateAgency();

      const resustOfUpdateAgency = await new AgencyApi()
                     .editAgencySetting(params.id, agencyDataToUpdate);


      if (resustOfUpdateAgency?.error?.message) {
         return toast(<ToastMsg text={resustOfUpdateAgency?.error?.message} isError/>)
      } 
      
      if (resustOfUpdateAgency?.success?.message) {
         if(+currentCompanyId === resustOfUpdateAgency?.success?.data?.company?.id) {
            dispatch(setCompanyName(basicData?.agencyName))
         }
         return toast(<ToastMsg text={resustOfUpdateAgency?.success?.message}/>)
      } 
   }

   const sendFavicon = async (companyId) => {
      if(!agencyFavicon) return;
      const formData = new FormData();
      formData.append("new_company_icon_logo", agencyFavicon);

      const res = await new AgencyApi()
                  .postAgencyIconLogo(companyId, formData);

      if(res?.success?.data?.company?.companyLogo?.iconLogo && 
         +currentCompanyId === res?.success?.data?.company?.id
         ) {

         const updatedCompanyIcon = await returnTemporaryCompanyLink(userInfo?.id, res?.success?.data?.company);

         dispatch(setCompanyIcon(updatedCompanyIcon?.[0]?.fileUrl));
      }

      if(params.id !== ':id' && res?.success) openSecondStepSmallLogo();

      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />)
      }
   }

   const sendLargeIcon = async (companyId) => {
      if(!agencyLargeIcon) return;
      const formData = new FormData();
      formData.append("new_company_large_logotype", agencyLargeIcon);
      const res = await new AgencyApi()
                     .postAgencyLargeIcon(companyId, formData);

      if(params.id !== ':id' && res?.success) openSecondStepLargeLogo();

      if(res?.error?.message) { 
         return toast(<ToastMsg text={res?.error?.message}/>)
      }
   }   

   const onSave = async (e) => {
      setChangeLoading(true);

      e.preventDefault();
      if(!termsChecked) {
         endBlockRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
         return toast(<ToastMsg text={'Terms of use is required'} isError />)
      } 

      if(!basicData.agencyName.trim().length) {
         return toast(<ToastMsg text={'Name of agency cannot be empty.'} isError />)
      }

      // params.id === ':id' means that this is page for creation of a new agency
      // else for update
      if(params.id === ':id') {
         await createAgencyHandler();
      } else {
        await updateAgencyHandler()
      }

      setChangeLoading(false);
   };

   const openSecondStepSmallLogo = () => {
      faviconUploadModal.close();
      faviconEditModal.open();
   }

   const openSecondStepLargeLogo = () => {
      largeIconUploadModal.close();
      largeIconEditModal.open();
   }

   const setCurrentAgencyData = (agency) => {
      const { companySecurity: security } = agency;

      setCurrentAgency(agency);

      setBasicData({
         ...basicData,
         agencyName: agency?.name ? agency.name : '',
         agencyEmail: agency?.email ? agency.email : '',
         agencyDesc: agency?.description ? agency.description : '',
         allowRegistrationChecked: agency?.allowRegistration || 0,
         privacyChecked: agency?.privacy || 0,
         selectedTimeZone: agency?.timeZone || ''
       });

      setSecurityConditions((prevConditions) => ({
         ...prevConditions,
         content_and_context_menu_disabled: security?.contentAndContextMenuDisabled ? 1 : 0,
         clickboard_disabled: security?.clickboardDisabled ? 1 : 0,
         provide_access_by_domain: security?.provideAccessByDomain ? 1 : 0,
         strong_password: security?.strongPassword ? 1 : 0,
         provide_access_to_ip: security?.provideAccessToIP ? 1 : 0,
         two_factor_auth: security?.twoFactorAuth ? 1 : 0,
      }));
   }

   const agencyManager = useGetAgencies(userId);

   const lastAgencyRef = useIntersectionObserver(
      () => {
        if (!agencyManager.isAgenciesLoading && agencyManager.agenciesList?.length < agencyManager.totalAgencies) {
          if(agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
          } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]
    );

   const fetchCurrentAgency = async () => {
      setAgencyLoading(true);
      const res = await new AgencyApi()
                     .getAgency(params.id, COMPANY_EXTRA_GROUP_ONE)

      const agency = res?.success?.data;
   
      if(agency) {
         setCurrentAgencyData(agency)
      }

      setAgencyLoading(false);
   }

   const fetchLightCurrentAgency = async () => {
      const res = await new AgencyApi()
                     .getAgency(params.id)

      const agency = res?.success?.data;
   
      if(agency) {
         setLightCurrentAgency(agency)
      }
   }

   const resetAllFields = () => {
      setBasicData({agencyName: "", agencyDesc: "", agencyEmail: "", selectedTimeZone: "",privacyChecked: 0, allowRegistrationChecked: 0});
       setSecurityConditions({ content_and_context_menu_disabled: 0, clickboard_disabled: 0, provide_access_by_domain: 0, strong_password: 0, provide_access_to_ip: 0, two_factor_auth: 0 });
    }

  const onCancel = () => {
      // Return everything how it was in the beginning
      navigate('/companies')
      confirmModal.close();
  }

   useEffect(() => {
      if (basicData.agencyName.length) {
         setIsButtonDisabled(false);
      } else {
         setIsButtonDisabled(true);
      }
   }, [basicData.agencyName]);

   useEffect(() => {
      if(params.id !== ':id') {
         fetchCurrentAgency();
         fetchLightCurrentAgency();
         setTermsChecked(true);
      }

      return () => {
         resetAllFields();
      }
   }, []);

   const getUserOfCompany = async (page, limit = null) => {
      setUsersOfCompanyLoading(true);

      const res = await new UserApi().getUsers(page ? page : usersOfCompanyPage, limit ? limit : DEFAULT_DATA_LIMIT, params.id, searchedValue);

      if(res?.success?.data) {
         const data = res?.success?.data?.map(user => ({
            id: user?.id,
            name: !user?.firstName && !user?.lastName ? '-' : `${user?.firstName || ''} ${user?.lastName || ''}`,
            email: user?.email || '-',
            roles: user?.userBranchRoles
         }))

         if(page) {
            setUsersOfCompany(data);
         } else {
            setUsersOfCompany((prev)=> [...prev, ...data]);
         }
         
         setUsersOfCompanyTotal(res?.success?.totalCount)
      }

      if(res?.success?.data?.length === 0) {
         usersOfCompanyPage(1);
      }
      if(res?.error) {
         setUsersOfCompany([]);
      }

     setUsersOfCompanyLoading(false);
   }

   const lastUserOfCompanyRef = useIntersectionObserver(
      () => {
        if (!usersOfCompanyLoading && usersOfCompany?.length < usersOfCompanyTotal) {
          if(usersOfCompany?.length === 0) {
            setUsersOfCompanyPage(1);
          } else {
            setUsersOfCompanyPage((prev) => prev + 1);
          }
        }
      },
      [usersOfCompanyLoading, usersOfCompanyTotal]
    );

   useEffect(()=> {
      if(usersOfCompanyPage > 1 && usersOfCompany?.length <= usersOfCompanyTotal && params.id !== ':id') {
         getUserOfCompany();
      }
   }, [usersOfCompanyPage])

   const debouncedFetchUsersOfCompanyData = debounce(()=> getUserOfCompany(1), DEFAULT_DEBOUNCE_DELAY); 

   useEffect(()=> {
      if(params.id !== ':id') {
         setUsersOfCompanyPage(1);
         debouncedFetchUsersOfCompanyData();
      }
    
      return () => debouncedFetchUsersOfCompanyData.cancel();
   }, [searchedValue])

   const excludedTeamsManager = useGetAllTeams(currentAgency?.id, TEAM_EXTRA_GROUP_TABLE);

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!excludedTeamsManager.isTeamsLoading && excludedTeamsManager.teamsList?.length < excludedTeamsManager.totalTeams) {
          if(excludedTeamsManager.teamsList?.length === 0) {
            excludedTeamsManager.setCurrentPage(1);
          } else {
            excludedTeamsManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [excludedTeamsManager.isTeamsLoading, excludedTeamsManager.totalTeams]
    );

   // We get all users that are not in company we pass in the request/
   const fetchExcludedUsers = async (page) => {
         if(!currentAgency?.id) return;

         const modifiedNotExcludedCompany = selectedCompany?.name !== 'All' ? selectedCompany?.id : '';

         setExcludedUsersLoading(true);
         const res = await new UserApi()
                        .getExcludedUsers(page ? page : excludedUsersPage, DEFAULT_DATA_LIMIT, currentAgency?.id, selectedPosition?.id, selectedDepartment?.id, modifiedNotExcludedCompany, excludedUsersSearch)
   
         if(res?.success?.data) {
            const links = await returnTemporaryLinks(res?.success?.data?.filter(user => user?.avatar));

            const usersWithUpdatedLinks = res?.success?.data?.map((user) => mapUsersWithNewLinks(user, links));

            if(page) {
               setExcludedUsers(usersWithUpdatedLinks);
            } else {
               setExcludedUsers((prev)=> [...prev, ...usersWithUpdatedLinks]);
            }
            setTotalExcludedUser(res?.success?.totalCount)
         }

         if(res?.success?.data?.length === 0) {
            usersOfCompanyPage(1);
         }
   
         if(res?.error?.message && page) {
            setExcludedUsers([]);
         }

         setExcludedUsersLoading(false);
      }
   
      const lastExcludedUsersRef = useIntersectionObserver(
         () => {
           if (!excludedUsersLoading && excludedUsers?.length < totalExcludedUser) {
             if(excludedUsers?.length === 0) {
               setExcludedUsersPage(1);
             } else {
               setExcludedUsersPage((prev) => prev + 1);
             }
           }
         },
         [excludedUsersLoading, totalExcludedUser]
       );
   
      useEffect(()=> {
         if(excludedUsersPage > 1 && excludedUsers?.length <= totalExcludedUser && params.id !== ':id') {
            fetchExcludedUsers();
         }
      }, [excludedUsersPage])
   
      const debouncedFetchExcludedUsers = debounce(()=> fetchExcludedUsers(1), DEFAULT_DEBOUNCE_DELAY); 
   
      useEffect(()=> {
         setExcludedUsersPage(1);
         debouncedFetchExcludedUsers();
         return () => debouncedFetchExcludedUsers.cancel();
      }, [excludedUsersSearch, currentAgency, selectedDepartment, selectedPosition, selectedCompany])

      const addUsersToCompany = async () => {

         const allUsersFromTeams = selectedTeamList.reduce((allUsers, team) => {
            return allUsers.concat(team.users);
         }, []);

         const allSelectedUsers = Array.from(new Set([...selectedUserList, ...allUsersFromTeams]));

         const data = allSelectedUsers.map(user => ({
            user_id: user?.id,
            role_id: user?.selectedRoleId || ROLE_ID_STUDENT
         }))
         
         const res = await new AgencyApi().addUsersToAgency(currentAgency?.id, data);

         if(res?.success?.message) {
            toast(<ToastMsg text={res?.success?.message} />)
            getUserOfCompany(1);
            setUsersOfCompanyPage(1);
         }
         if(res?.error?.message) {
            toast(<ToastMsg text={res?.error?.message} isError/>)
         }
      }

   const changeRoleInCompany = (clickedId, roleId) => {
      setSelectedUserList((prevUsers) =>
            prevUsers.map((user) =>
            user.id === clickedId
               ? {
                  ...user,
                     roleId: roleId,
                  }
               : user
            )
         );
    }

  const csvData = [
      USERS_AND_ROLES_COLUMNS,
      ...usersOfCompany?.map(({ name, email, role }) => [
         name, email,
      ])
   ];

   const excludedUsersMapped = excludedUsers?.map((user)=> mappedExcludedUsers(user, currentAgency?.id));

   if(params.id !== ':id' && !currentAgency?.id && !agencyLoading && !isDoubleCheckForRealCompanyNeeded) {
      return  <MainLayout>
                  <EmptyBlock text={t?.company}/>
              </MainLayout>
   } 

   const langsList = ALL_LANGUAGES_LIST?.filter(lang => lang?.code !== DEFAULT_LANG_LOCALE?.code);

   const updatedLangsList = langsList?.map(lang => {
     const existsInAvailableLanguages = lightCurrentAgency?.availableLanguages?.includes(lang?.code);
     return existsInAvailableLanguages ? { ...lang, status: 1 } : {...lang, status: 0};
   });


   const defineIcon = (status) => {
      if (status === 1) {
         return statusActiveImg;
       } else if (status === 0) {
         return statusPausedImg;
       }
   }

   const hasRightsToLocalication = userInfo?.permissions?.some(p => p?.id === 442);

   return (
      <MainLayout>
         <main className={styles.settings_page}>
            <ConfirmModal
               onConfirm={onCancel}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t?.areYouSure}
            />

            <AgencySettingsSubheader
               onSave={onSave}
               confirmModal={confirmModal}
               isButtonDisabled={isButtonDisabled || changeLoading}
               changeLoading={changeLoading}
               t={t}
            />

            <AgencySettingsSidebar
               activeElement={activeElement}
               onMenuItemClick={handleListItemClick}
               setActiveElement={setActiveElement}
               t={t}
            />

             <section ref={sectionRef} className={cn(styles.settings_body, 
               activeElement === 'users' && params.id !== ':id' ? styles.hide_section : ''
             )}>
                  <BasicBlock
                     blockRef={basicBlockRef}
                     setAgencyData={setBasicData}
                     agencyData={basicData}
                     openAccessModal={companyUsersAndTeamsModal.open}
                     t={t}
                  />

                  <LogoBlock
                     favicon={renderedFavicon}
                     setFavicon={setRenderedFavicon}
                     largeIcon={renderedLargeIcon}
                     setLargeIcon={setRenderedLargeIcon}
                     blockRef={logoBlockRef}
                     setAgencyFavicon={setAgencyFavicon}
                     setAgencyLargeIcon={setAgencyLargeIcon}
                     faviconUploadModal={faviconUploadModal}
                     largeIconUploadModal={largeIconUploadModal}
                     t={t}
                  />

                  <SecurityBlock
                     securityConditions={securityConditions}
                     setSecurityConditions={setSecurityConditions} 
                     termsChecked={termsChecked}
                     setTermsChecked={setTermsChecked}
                     blockRef={endBlockRef} 
                     t={t}
                  />

                  {params.id !== ':id' && 
                  
                  <div className={styles.block}>
                        <div className={styles.langs_header}>
                           <span className={styles.subtitle}>{t?.languages}</span>

                        {
                           hasRightsToLocalication && 
                           <button 
                              className={styles.langs_settings} 
                              onClick={() => {
                                 window.open(`/languages/${params?.id}`, '_blank');
                              }}
                            >
                              <img src={settings_img} alt="" />
                              <span className={styles.subtitle}>{t?.settings}</span>
                           </button>
                        }

                        </div>
                        <div className={styles.default_lang}>
                           {`${DEFAULT_LANG_LOCALE?.name}`}
                           <span>{` (${t?.main})`}</span>
                        </div>
                     <div className={styles.langs_list}>
                        {updatedLangsList?.map(lang => 
                           <div key={lang?.id} className={cn(styles.langs_list_item)}>
                              <span>{lang?.name}</span>
                              <div className={styles.lang_right}>
                                 <div
                                    className={styles.actions}
                                    onClick={() => onOpenStatusModal(lang)}
                                 >
                                    <img src={defineIcon(lang?.status)} alt="icon" />
                                    <img src={arrow} alt="" />
                                 </div> 
                                 <Button 
                                    image={deleteIcon} 
                                    title={t?.delete} 
                                    onClick={()=> onOpenConfirmModal(lang)} 
                                    small isBlack
                                 />
                              </div>
                           </div>
                        )}
                     </div>
                  </div>
                  }

            </section>

           {activeElement === 'users' && params.id !== ':id' &&
               <section className={styles.users_and_roles_section}>
                 <UsersSubheader
                   csvData={csvData} 
                   getUserOfCompany={getUserOfCompany}
                   searchedValue={searchedValue}
                   setSearchedValue={setSearchedValue}
                   openAccessModal={companyUsersAndTeamsModal.open}
                   isDataLoading={usersOfCompanyLoading}
                   t={t}
                 />
                 <div className={styles.table_wrapper}>
                     <UsersAndRolesTable 
                        data={usersOfCompany}
                        openDeleteUserFromCompanyModal={openDeleteUserFromCompanyModal}
                        loading={usersOfCompanyLoading}
                        columns={USERS_AND_ROLES_COLUMNS}
                        lastElementRef={lastUserOfCompanyRef}
                        t={t}
                     />
                 </div>
               </section>
           }
         </main>

         <CompanyUsersAndTeamsModal
                onClose={companyUsersAndTeamsModal.close}
                isOpen={companyUsersAndTeamsModal.isActive}
                lastExcludedUsersRef={lastExcludedUsersRef}
                excludedUsersSearch={excludedUsersSearch}
                setExcludedUsersSearch={setExcludedUsersSearch}
                setSelectedUsers={setSelectedUserList}
                selectedUsers={selectedUserList}
                usersList={excludedUsersMapped}
                onConfirm={addUsersToCompany}
                teamManager={excludedTeamsManager}
                setSelectedTeamList={setSelectedTeamList}
                selectedTeamList={selectedTeamList}
                changeRoleInCompany={changeRoleInCompany}
                companyId={currentAgency?.id}
                companyName={currentAgency?.name}
                selectedDepartment={selectedDepartment}
                setSelectedDepartment={setSelectedDepartment}
                selectedPosition={selectedPosition}
                setSelectedPosition={setSelectedPosition}
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany}
                allCompaniesApartFromCurrent={agencyManager?.agenciesList?.filter?.(company => company?.id !== +params?.id)}
                lastAgencyRef={lastAgencyRef}
                lastTeamRef={lastTeamRef}
            />

          <ConfirmModal
               isRemove
               confirmButtonText={t?.delete}
               onConfirm={deleteUserFromCompany}
               onClose={deleteUserModal.close}
               isOpen={deleteUserModal.isActive}
               isLoading={isDeleteLoading}
               title={t?.areYouSure}
            /> 

         <IconUploadModal 
            icon={agencyFavicon}   
            isFavicon={true}
            setIconFile={setAgencyFavicon}
            setRenderedIconFile={setRenderedFavicon}
            isActive={faviconUploadModal.isActive} 
            onSaveIcon={()=> params.id !== ':id' ? sendFavicon(params.id) : openSecondStepSmallLogo()}
            onClose={faviconUploadModal.close} 
            desc={UPLOAD_SMALL_LOGO_TEXT}
            title={t?.uploadIconLogotype} 
         />

         <IconUploadModal 
            icon={agencyLargeIcon}   
            isFavicon={false}
            setIconFile={setAgencyLargeIcon} 
            setRenderedIconFile={setRenderedLargeIcon}
            onSaveIcon={()=> params.id !== ':id' ? sendLargeIcon(params.id) : openSecondStepLargeLogo()}
            isActive={largeIconUploadModal.isActive}
            onClose={largeIconUploadModal.close}
            desc={UPLOAD_LARGE_LOGO_TEXT} 
            title={t?.uploadLargeLogotype} 
         />

         <IconEditModal 
            isActive={faviconEditModal.isActive} 
            onClose={faviconEditModal.close}
            renderedIcon={renderedFavicon} 
            icon={agencyFavicon} 
            desc={UPLOAD_SMALL_LOGO_TEXT}
            title={t?.uploadIconLogotype}
         />

         <IconEditModal 
            isActive={largeIconEditModal.isActive} 
            onClose={largeIconEditModal.close}
            renderedIcon={renderedLargeIcon}
            desc={UPLOAD_LARGE_LOGO_TEXT} 
            icon={agencyLargeIcon}
            title={t?.uploadLargeLogotype}
         />

         <CompanyLanguageStatusModal
            onClose={onCloseStatusModal}
            isOpen={handleStatusModal.isActive}
            item={clickedLanguage}
            refetchData={fetchLightCurrentAgency} 
            languages={lightCurrentAgency?.availableLanguages}
            companyId={params?.id}
         />

         <ConfirmModal
            onClose={confirmDeleteLangModal.close}
            isOpen={confirmDeleteLangModal.isActive}
            isRemove
            title={t?.areYouSure}
            subtitle={t?.allTranslatedInformationWillBeLost}
            onConfirm={deleteLanguageVersion}
            isLoading={isDelitingLanguageContent}
         />
      </MainLayout>
   );
}
