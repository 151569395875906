import React, { useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout/ModalLayout";
import styles from "./styles.module.scss";
import Button from "../../Button/Button";
import plus from "../../../assets/images/plus.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import errorIcon from "../../../assets/images/deleted_status.svg";
import successIcon from "../../../assets/images/success_icon.svg";
import Input from "../../Input/Input";
import UserApi from "../../../utils/api/UserApi";
import { toast } from "react-toastify";
import ToastMsg from "../../ToastMsg/ToastMsg";
import { ROLE_ID_STUDENT } from "../../../constants/user";
import PaginatedDropdown from "../../PaginatedDropdown/PaginatedDropdown";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import RenameModal from "../RenameModal/RenameModal";
import cn from 'classnames';
import { isValidEmail } from "../../../utils";
import Papa from 'papaparse';
import { generateRandomPassword } from "../../../utils/usersHelper";
import useFindTranslations from "../../../hooks/useFindTranlsations";
import useGetDepartments from "../../../hooks/api/departmentsAndPostitions/useGetDepartments";
import useGetPositions from "../../../hooks/api/departmentsAndPostitions/useGetPositions";

const CreateUserModalTest = ({
   onClose,
   isOpen,
   refetchData,
   addUserToList,
}) => {
   const initialState = [
      {
         name: "",
         surname: "",
         email: "",
         position: "",
         department: "",
      },
   ];

   const [users, setUsers] = useState(initialState);
   const [isError, setIsError] = useState(false);
   const [emailError, setEmailError] = useState(null);
   const [currentRegistratedUsersCount, setCurrentRegistratedUsersCount] = useState(0);
   const [newPosition, setNewPosition] = useState('');
   const [editedUserIdx, setEditedUserIdx] = useState(null);

   const companyId = useSelector((state)=> state.sidebar.companyId)

   const t = useFindTranslations();
   
   const resetErrors = () => {
      setIsError(false);
      setEmailError(null);
   }

   const handleAddUser = () => {
      resetErrors();
      const newUser = {
         name: "",
         surname: "",
         email: "",
         position: "",
         department: "",
      };
      setUsers([...users, newUser]);
   };

   const currentCompanyBranchId = useSelector(
      (state) => state.sidebar.currentCompanyBranchId
   );
   const [isCreateLoading, setIsCreateLoading] = useState(false);
   const newDepartmentModal = useHandleModal();
   const newPositionModal = useHandleModal();

   const onOpenPositionModal = (userIdx) => {
      newPositionModal.open();
      setEditedUserIdx(userIdx)
   }

   const onOpenDepartmentModal = (userIdx) => {
      newDepartmentModal.open();
      setEditedUserIdx(userIdx)
   }

   const handleDeleteUser = (index) => {
      resetErrors();
      const updatedUsers = [...users];
      updatedUsers.splice(index, 1);
      setUsers(updatedUsers);
   };

   const {
      data: departments,
      lastItemRef: lastFilterDepartmentRef,
      searchedValue: filterSearchedValueDep,
      setSearchedValue: setFilterSearchedValueDep,
   } = useGetDepartments({
      companyId: companyId,
   });

   const leaveNotValidUserInput = (notValidEmails, validEmails) => {
      resetErrors();
    
      setUsers((prevUsers) => {
         const newUsers = prevUsers.map((user) => {
           const isNotValid = notValidEmails.includes(user?.email);
           const isCreated = validEmails?.includes(user?.email);
       
           return {
             ...user,
             noValid: user?.noValid ? user?.noValid : isNotValid,
             created: user?.created ? user?.created : isCreated
           };
         });
     
         return newUsers;
       });
    };

   const validateUserData = (user) => {
      if (!user?.name || user.name.trim().length === 0) {
         return 'Name is required.';
      }

      if (!user?.email || user.email.trim().length === 0) {
         return 'Email is required.';
      }

      if (!isValidEmail(user.email.trim())) {
         return 'Email is not correct.';
      }

      return null;
   };

   const generateRandomString = (length) => {
      const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };
    
   const generateUserData = (user) => {
      const randomPassword = generateRandomPassword()

      const userData = {
         email: user?.email?.trim(),
         password: randomPassword,
         confirm_password: randomPassword,
         first_name: user.name,
         ...(user?.surname?.length ? { last_name: user.surname } : {}),
         ...(user?.position?.id ? { position: user.position?.id } : {}),
         ...(user?.department?.id ? { department: user.department?.id } : {}),
         ...(!user?.position?.id && user?.position?.name?.length > 0 ? { position: user.position?.name } : {}),
         ...(!user?.department?.id && user?.department?.name?.length > 0 ? { department: user.department?.name } : {}),
         status: 1,
      };
   
      return userData;
   };

const handleSendInvite = async () => {
  const chunkSize = 25;
  const usersM = users?.filter(user => !user?.created)
  const totalChunks = Math.ceil(usersM.length / chunkSize);
  let aggregatedErrors = [];
  let aggregatedSuccessMsgs = [];
  let initialValidationError = '';

  const notValidUsersEmails = [];

  for (let i = 0; i < usersM.length; i++) {
      const validationError = validateUserData(usersM[i]);
      if (validationError) {
          initialValidationError = validationError;
          notValidUsersEmails.push(usersM[i]?.email);

         if(notValidUsersEmails.length) {
            leaveNotValidUserInput(notValidUsersEmails);
         } 
          break; 
      }
  }

  if (initialValidationError) {
      return toast(<ToastMsg isError text={initialValidationError} />);
  }

  try {
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * chunkSize;
      const end = start + chunkSize;
      const userChunk = usersM.slice(start, end);
      setIsCreateLoading(true);

      const dataToGenerateToken = {
        role_id: ROLE_ID_STUDENT,
        branch_id: currentCompanyBranchId || 1, 
      };

      const chunkUserData = userChunk.map(user => {
        return generateUserData(user);
      }).filter(Boolean); 

      if (chunkUserData.length > 0) {
        try {
          const resToken = await new UserApi().generateManyRegistrationTokens([dataToGenerateToken]);
          const token = resToken?.success?.data?.results?.[0]?.success?.data?.registration_token;

          const userDataToSend = chunkUserData.map(data => ({
            ...data,
            registration_token: token,
          }));

          const resStudent = await new UserApi().registerManyUsers({ data: userDataToSend });

         const errMsg = handleRegistrationResponse(resStudent, chunkUserData);

          if(totalChunks > 1) {
            setCurrentRegistratedUsersCount((chunkIndex + 1) * chunkSize);
          }

          if(chunkIndex === totalChunks - 1 && !errMsg) {
            if(totalChunks === 1) toast(<ToastMsg text={`${usersM?.length > 1 ? 'Users have' : 'User has'}  been created`} />);

            if(totalChunks > 1) toast(<ToastMsg text={"Users have been created"} />);

            setIsCreateLoading(false);
            onClose();
            setUsers(initialState);
            setCurrentRegistratedUsersCount(0)
          }

        } catch (error) {
          console.error(error?.message || 'Error generating token or registering users');
        }
      }
    }
  } catch (error) {
    // Handle any uncaught errors here
    console.error("Unexpected error:", error);
    setIsCreateLoading(false);
    return toast(<ToastMsg isError text={"Unexpected error occurred"} />);
  } finally {
   setIsCreateLoading(false);
  }

};
    
const handleRegistrationResponse = (resStudent, chunkUserData) => {
   let lastErrorMsg = '';
   let lastSuccessMsg = '';

   const resSuccessUserData = [];
   const notValidUsersEmails = [];
   const validUsersEmails = [];

   for (let i = 0; i < resStudent?.success?.data.length; i++) {
      const item = resStudent?.success?.data[i];

      if (item?.error?.message) {
         lastErrorMsg = item?.error?.message;
         notValidUsersEmails.push(chunkUserData[i]?.email);
      }

      if (item?.success?.message) {
         lastSuccessMsg = item?.success?.message;
         validUsersEmails.push(chunkUserData[i]?.email);
      }

      if (item?.success?.message && addUserToList) {
         resSuccessUserData.push(item?.success?.data?.user);
      }
   }

   if(resStudent?.error?.message) {
      lastErrorMsg = resStudent?.error?.message;
   }

   if(!resStudent) {
      lastErrorMsg = 'Network error';
   }

   leaveNotValidUserInput(notValidUsersEmails, validUsersEmails);

  if(addUserToList) addUserToList(resSuccessUserData?.map(user => ({
      ...user,
      role: "Student"
   })));

   if (lastErrorMsg.length) {
      setIsCreateLoading(false);
      if (lastSuccessMsg) {
         toast(<ToastMsg text={lastSuccessMsg} />);
      }
      toast(<ToastMsg text={lastErrorMsg} isError />);
      return lastErrorMsg;
   }

   refetchData();
};

   const handleUserInputChange = (index, field, value) => {
      resetErrors();
      const updatedUsers = [...users];
      updatedUsers[index][field] = value;
      setUsers(updatedUsers);
   };

   const onCloseModal = () => {
      resetErrors();
      onClose();
      setCurrentRegistratedUsersCount(0);
      setUsers(initialState);
   };

   const parseCSV = (csvData) => {
      const parsedData = Papa.parse(csvData, { header: true, skipEmptyLines: true });
      return parsedData.data;
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
   
      if (file && file.type === "text/csv") {
         const reader = new FileReader();
   
         reader.onload = (event) => {
            const contents = event.target.result;
   
            try {
               const parsedUsers = parseCSV(contents);
   
               if (!Array.isArray(parsedUsers) || !parsedUsers.every(user => user?.name)) {
                  toast(<ToastMsg text={"Invalid CSV format"} isError />);
                  setUsers(initialState);
                  
                  return;
               }
   
               const mapUsers = parsedUsers.map(user => ({
                  name: user?.name || '',
                  surname: user?.surname || '',
                  email: user?.email || '',
                  position: { name: user?.position } || '',
                  department: { name: user?.department } || '',
               }));
   
               setUsers(mapUsers);
            } catch (error) {
               console.error("Error parsing CSV:", error);
               toast(<ToastMsg text={"Error parsing CSV file"} isError />);
               setUsers(initialState);
            }
         };
   
         reader.readAsText(file);
      } else {
         toast(<ToastMsg text={"Only CSV files are allowed"} isError />);
      }
   };

   return (
      <ModalLayout
         onClose={isCreateLoading ? ()=> {} : onCloseModal}
         isOpen={isOpen}
         maxHeight={"500px"}
         maxWidth={"1200px"}
         customPadding={"5px"}
         customBgColor={"#212224"}
         noBlur
      >
         <div className={styles.create_user_wrapper}>
            <div className={styles.create_subheader}>
               <p className={styles.title}>{t?.addStudents}</p>
               <div className={styles.subheader_btns}>
                  <div className={styles.subheader_icon_btn}>
                     <label htmlFor="file-upload" className={styles.uploadLabel}>
                        <img src={uploadIcon} alt="upload icon" />
                        <span>{t?.uploadFromCSV}</span>
                     </label>
                     <input
                        id="file-upload"
                        type="file"
                        accept="text/csv"
                        className={styles.fileInput}
                        onChange={handleFileUpload}
                     />
                  </div>
               </div>
            </div>
            <div className={styles.create_middle}>
               <div className={styles.users_list}>
                  {users?.map((user, index) => (
                     <div key={index} className={styles.input_line}>
                        {user?.noValid && !user?.created &&
                           <img className={styles.errorIcon} src={errorIcon}/>
                        }
                        {user?.created && 
                           <img className={styles.successIcon} src={successIcon}/>
                        }
                         <span className={styles.user_num}>{index + 1}</span>
                        <div className={styles.user_inputs_wrapper}>
                           <div className={cn(
                              styles.user_inputs_item,
                              user?.name?.length === 0 && isError ? styles.error : ''
                           )}>
                              <Input
                                 value={user.name}
                                 placeholder={t?.name}
                                 onChangeValue={(e) =>
                                    handleUserInputChange(
                                       index,
                                       "name",
                                       e.target.value
                                    )
                                 }
                              />
                           </div>
                           <div className={styles.user_inputs_item}>
                              <Input
                                 value={user.surname}
                                 placeholder={t?.surname}
                                 onChangeValue={(e) =>
                                    handleUserInputChange(
                                       index,
                                       "surname",
                                       e.target.value
                                    )
                                 }
                              />
                           </div>
                           <div className={cn(
                              styles.user_inputs_item,
                              isError && user?.email?.length === 0 || emailError === '' || emailError === user?.email ? styles.error : '',
                              user?.noValid && !user?.created ? styles.error : ''
                           )}>
                              <Input
                                 value={user.email}
                                 placeholder={t?.email}
                                 onChangeValue={(e) =>
                                    handleUserInputChange(
                                       index,
                                       "email",
                                       e.target.value
                                    )
                                 }
                              />
                           </div>


                           <div className={styles.user_inputs_item}>
                              <PaginatedDropdown
                                 data={departments}
                                 field={"name"}
                                 value={user.department?.name}
                                 placeholder={t?.departmentOptionally}
                                 searchNeeded
                                 onChange={(value) =>
                                 {
                                    handleUserInputChange(
                                       index,
                                       "department",
                                       value
                                    )
                                    handleUserInputChange(
                                       index,
                                       "position",
                                       null
                                    )
                                 }
                                 }
                                 maxHeight={"220px"}
                                 searchValue={filterSearchedValueDep}
                                 setSearchValue={setFilterSearchedValueDep}
                                 lastItemRef={lastFilterDepartmentRef}
                                 crossNeeded
                              />
                           </div>

                           <div className={styles.user_inputs_item}>
                              <PaginatedDropdown
                                 data={user.department?.positions}
                                 disabled={!user.department}
                                 field={"name"}
                                 value={user.position?.name}
                                 maxHeight={'220px'}
                                 placeholder={t?.positionOptionally}
                                 searchNeeded
                                 onChange={(value) =>
                                    handleUserInputChange(
                                       index,
                                       "position",
                                       value
                                    )
                                 }
                                 crossNeeded
                              />
                           </div>

                        </div>

                        <button
                           className={cn(styles.delete_user_btn, users?.length === 1 ? 'hide_and_leave_space' : ''  )}
                           onClick={() => handleDeleteUser(index)}
                        >
                           <img
                              className={styles.delete_user_inputs}
                              src={plus}
                              alt=""
                           />
                        </button>
                     </div>
                  ))}
               </div>
               <button className={styles.plus_btn} onClick={handleAddUser}>
                  <img className={styles.plus} src={plus} alt="plus" />
                 {t?.addStudent}
               </button>
            </div>
           
            <div className={styles.btns_wrapper}>
            {!!currentRegistratedUsersCount &&
             <div className={styles.regist_info}>
               {t?.returnTranslation('First')} {currentRegistratedUsersCount} users from the list have been processed! {isCreateLoading && `${t?.returnTranslation('Continue')}...`}
             </div>
            }
               <div className={styles.btns}>
                  <Button disabled={isCreateLoading} isBlack title={t?.cancel} onClick={onCloseModal} />
                  <Button
                     disabled={isCreateLoading}
                     title={t?.returnTranslation('Create users')}
                     onClick={handleSendInvite}
                  />
               </div>
            </div>

            <div className="default_loader_wrapper">
               {isCreateLoading && <Loader size={"small"} />}
            </div>
         </div>
      </ModalLayout>
   );
};

export default CreateUserModalTest;
