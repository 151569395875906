import React, { useRef } from "react";
import searchImg from "../../../assets/images/symbols/Search.svg";
import csvImg from "../../../assets/images/symbols/scv.svg";
import deleteImg from "../../../assets/images/symbols/delete.svg";
import blockImg from "../../../assets/images/Lock.svg";
import unblockImg from "../../../assets/images/unlock_white.svg";
import plus from "../../../assets/images/symbols/plus.svg";

import Button from "../../../components/Button/Button";
import SelectAllDropdown from "../../../components/SelectAllDropdown/SelectAllDropdown";

import styles from "./styles.module.scss";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import CreateStudentsModal from "../../../components/Modals/CreateStudentsModal/CreateStudentsModal";
import { CSVLink } from "react-csv";
import CreateSpecialUserModal from "../../../components/Modals/CreateSpecialUserModal/CreateSpecialUserModal";

import { SELECT_ALL_ITEMS } from "../../../constants/courses";
import { BIGGER_DATA_LIMIT } from "../../../constants/user";

const UsersSubheader = ({
   isAdmins,
   isBlocked,
   searchedValue,
   setSearchedValue,
   refetchData,
   csvData,
   addUserToList,
   fetchUsers,
   isDataLoading,
   selectMode,
   selectModeBtnText,
   openConfirmChangeStatusesModal,
   t,
   changeSelectAllMode,
   selectedUsers,
   setSelectedUsers
}) => {

   const csvLinkRef = useRef(null);

   const createStudentModal = useHandleModal();
   const createSpecialUserModal = useHandleModal();

   return (
      <div className={styles.subheader}>
         <div className={styles.line}></div>
         <div className={styles.selectAllDropdown}>
            <SelectAllDropdown
               data={SELECT_ALL_ITEMS}
               selectedItems={selectedUsers}
               setSelectedItems={setSelectedUsers}
               onChange={changeSelectAllMode}
               isActive={selectMode?.length}
            />
         </div>

         <div className={styles.search_wrapper}>
            <div className={styles.search}>
               <img src={searchImg} alt={""} />
               <input
                  placeholder={t?.search}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
               />
            </div>
             <Button
                 title={t?.exportToCSV}
                 image={csvImg}
                 isBlack
                 className={styles.actions_button}
                 disabled={isDataLoading}
                 maxWidth={"250px"}
                 onClick={() => fetchUsers(1, BIGGER_DATA_LIMIT).then(() => csvLinkRef.current.link.click())}
             />
                 <CSVLink ref={csvLinkRef} style={{display: "none"}} data={csvData}/>

            {!isAdmins && !isBlocked && (
               <Button
                   className={styles.actions_button}
                  title={t?.addStudent}
                  image={plus}
                  maxWidth={"220px"}
                  onClick={createStudentModal.open}
               />
            )}

            {isAdmins && (
               <Button
                  className={styles.actions_button}
                  title={t?.addSpecialUser}
                  image={plus}
                  maxWidth={"220px"}
                  onClick={createSpecialUserModal.open}
               />
            )}

         {selectMode?.length > 0 && (
            <div className={styles.status_btns}>
               <Button
                  title={t.returnTranslation(selectModeBtnText)}
                  image={selectModeBtnText === 'Unblock' ? unblockImg : blockImg}
                  isBlack
                  disabled={isDataLoading}
                  maxWidth={"180px"}
                  className={styles.actions_button}
                  onClick={()=> openConfirmChangeStatusesModal(selectModeBtnText)}
               />

               {selectModeBtnText === 'Unblock' && 
                  <Button
                     title={t.returnTranslation('Delete')}
                     image={deleteImg}
                     className={styles.actions_button}
                     isBlack
                     disabled={isDataLoading}
                     maxWidth={"180px"}
                     onClick={()=> openConfirmChangeStatusesModal('Delete')}
                  />
               }
            </div>
         )}
         </div>

         <CreateStudentsModal
            onClose={createStudentModal.close}
            isOpen={createStudentModal.isActive}
            refetchData={refetchData}
            addUserToList={addUserToList}
         />

         <CreateSpecialUserModal
            onClose={createSpecialUserModal.close}
            isOpen={createSpecialUserModal.isActive}
            refetchData={refetchData}
            addUserToList={addUserToList}
         />
      </div>
   );
};

export default UsersSubheader;
