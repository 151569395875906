import { ROLE_ID_GUEST, ROLE_ID_STUDENT } from "../constants/user";
import UserApi from "./api/UserApi";
import defaultAvatar from "../assets/images/avatar.svg";

export const isMobileDevice = () => {
   return /Mobi|Android/i.test(navigator.userAgent); 
   // return  window.matchMedia("(max-width: 769px)").matches;
 };

 export const sanitizeCsvRow = (row) => {
   const sanitizedRow = {};
   for (const [key, value] of Object.entries(row)) {
     sanitizedRow[key] = typeof value === 'string'
       ? value
           .trim()
           .replace(/[\t\n\r]+/g, ' ') 
           .replace(/"/g, '""')
       : value;
   }
   return sanitizedRow;
 }
 
export const toggleMentorRights = async (isAdd, mentorId) => {
   const data = {
      permission_data: [
         { permission_id: 130, add: isAdd },
         { permission_id: 132, add: isAdd },
         { permission_id: 133, add: isAdd },
      ],
   };

   const res = await new UserApi().updateUserPermissions(mentorId, data);
};

export const generateRandomPassword = (length = 12) => {
   const characters = 
   'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_';
   let password = '';
   for (let i = 0; i < length; i++) {
         const randomIndex = Math.floor(Math.random() * characters.length);
         password += characters[randomIndex];
   }
      return password;
}
  

export const sortRoles = (a, b) => {
   const roleOrder = {
       'super_admin': 1,
       'admin': 2,
       'curator': 3,
       'mentor': 5,
       'student': 6
   };

   const getRoleOrder = role => {
       for (const key in roleOrder) {
           if (role.includes(key)) {
               return roleOrder[key];
           }
       }
       return 4; // Default order for other roles
   };

   const roleAOrder = getRoleOrder(a?.role?.name.toLowerCase());
   const roleBOrder = getRoleOrder(b?.role?.name.toLowerCase());

   return roleAOrder - roleBOrder;
}

export const returnTemporaryLink = async(user) => {
   const res = await new UserApi().replaceS3Links([{
      user_id: user?.id,
      filename: user?.avatar
   }]);
   
   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const returnCurrentUserRole = (user, companyId) => {
   const userRole = user?.userBranchRoles?.filter(role => role?.branch?.company?.id === companyId)?.sort(sortRoles);

   return userRole?.[0]?.role?.name;
 }

 export const mapUsersWithFilteredRoles = (user, companyId) => ({
   ...user,
   name: `${user?.firstName || '-'} ${user?.lastName || ''}`,
   userBranchRoles: user?.userBranchRoles?.filter(role => role?.branch?.company?.id === +companyId)?.sort(sortRoles) || [],
 })

 export const returnTemporaryLinks = async(users) => {
   if(users?.length === 0) return [];

   const res = await new UserApi().replaceS3Links(users?.map(user => ({
      user_id: user?.id,
      filename: user?.avatar
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
 }

 export const mapUsersWithNewLinks = (user, links) => {
   const matchingLink = links?.find(link => link?.fileName === user?.avatar);

   if (matchingLink) {
      return {
         ...user,
         avatar: matchingLink.fileUrl,
         logo: matchingLink.fileUrl
      };
   } else {
      return {
         ...user,
         avatar: defaultAvatar,
         logo: defaultAvatar
      };
   }
}

// COMPANIES

export const returnTemporaryCompanyLink = async(userId, company) => {
   const res = await new UserApi().replaceS3Links([{
      user_id: userId,
      filename: company?.companyLogo?.iconLogo
   }]);

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
}

export const returnTemporaryCompaniesLinks = async(userId, companies) => {
   const res = await new UserApi().replaceS3Links(companies?.map(company => ({
      user_id: userId,
      filename: company?.companyLogo?.iconLogo
   })));

   if(res?.success?.data) {
      return res?.success?.data?.map(item => ({
         fileUrl: item?.success?.data?.file_signed_url,
         fileName: item?.success?.data?.filename
      }));
   }
}

export const mapAgenciesWithNewLinks = (agency, links) => {
   const matchingLink = links?.find(link => link?.fileName === agency?.companyLogo?.iconLogo);

   if (matchingLink) {
      return {
         ...agency,
         companyLogo: {
            ...agency?.companyLogo,
            iconLogo: matchingLink.fileUrl
         },
         email: agency?.email ? agency?.email : "-",
         logo: matchingLink.fileUrl,
      };
   } else {
      return agency;
   }
}