import { useState } from "react";
import useHandleModal from "./dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../components/ToastMsg/ToastMsg";
import CourseApi from "../utils/api/CourseApi";
import { COURSE_STATUS_ACTIVE } from "../constants/courses";
import UserApi from "../utils/api/UserApi";
import { useNavigate } from 'react-router-dom';

const useChangeCourse = (
   refetchCourses,
   userId
) => {
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   const [isOpen, setIsOpen] = useState(false);

   const confirmModalToogleShowStatus = useHandleModal();
   const confirmModal = useHandleModal();
   const confirmHideModal = useHandleModal();
   const confirmModalRestore = useHandleModal();
   const retakeModal = useHandleModal();
   const confirmDeleteModal = useHandleModal();
   const confirmPausedModal = useHandleModal();

   const navigate = useNavigate();

   const [clickedCourse, setClickedCourse] = useState({});

   const onClose = () => setIsOpen(false);

   const onConfirmOpen = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      // confirm change status to deleted modal
      setClickedCourse(course);
      onClose();
      confirmModal?.open();
   };
  
   const onConfirmOpenStatus = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      setClickedCourse(course);
      onClose();
      confirmModalToogleShowStatus.open();
   };

   const onConfirmOpenDelete = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      setClickedCourse(course);
      onClose();
      confirmDeleteModal.open();
   };

   const onConfirmOpenRestore = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
      setClickedCourse(course);
      confirmModalRestore.open();
   };

   const onConfirmOpenHide = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
      setClickedCourse(course);
      confirmHideModal.open();
   };

   const onRetakeModalOpen = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
      setClickedCourse(course);
      retakeModal.open();
   };

   const onConfirmPausedOpen = (course, e) => {
      e.preventDefault();
      e.stopPropagation();
      onClose();
      setClickedCourse(course);
      confirmPausedModal.open();
   };

   const onRedirectClickedCourse = (blank) => {
      if(blank) {
         window.open(`/courses/course/${clickedCourse?.id}`, "_blank")
         return;
      }
      navigate(`/courses/course/${clickedCourse?.id}`)
   }

   const onCourseChangeStatus = async (status) => {
      setIsChangeLoading(true);

      const res = await new CourseApi().changeCourseStatus(clickedCourse?.id, {
         new_status: status,
      });

      confirmModal.close();
      confirmHideModal.close();

      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchCourses();
      }
   };

   const changeCourseStatusToActive = async (modal) => {
      setIsChangeLoading(true);
      const res = await new CourseApi().changeCourseStatus(clickedCourse?.id, {
         new_status: COURSE_STATUS_ACTIVE,
      });

      setIsChangeLoading(false);

      modal.close();

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchCourses();
      }
   };

   const onRetakeCourse = async (e) => {
      e?.stopPropagation()
      const res = await new UserApi().deleteProgressInCourse(
         clickedCourse?.id,
         userId
      );

      if (res?.success) {
         onRedirectClickedCourse(true)
         refetchCourses();
         toast(<ToastMsg text={"You can pass the course again!"} />);
      }

      if (res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      retakeModal.close();
   };

   const deleteCourse = async () => {
      setIsChangeLoading(true);
      const res = await new CourseApi().deleteCourse(clickedCourse?.id);

      confirmDeleteModal.close();

      setIsChangeLoading(false);

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         refetchCourses();
      }
   };

   return {
      confirmModalToogleShowStatus,
      confirmModal,
      confirmHideModal,
      confirmModalRestore,
      confirmDeleteModal,
      retakeModal,
      isChangeLoading,
      setIsChangeLoading,
      onCourseChangeStatus,
      onClose,
      onConfirmOpenStatus,
      changeCourseStatusToActive,
      onConfirmOpenRestore,
      onConfirmOpenHide,
      onRetakeCourse,
      onRetakeModalOpen,
      deleteCourse,
      onConfirmOpenDelete,
      onConfirmOpen,
      confirmPausedModal,
      onRedirectClickedCourse,
      onConfirmPausedOpen
   };
};

export default useChangeCourse;
