import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styles from "./styles.module.scss";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";

ChartJS.register(ArcElement, Tooltip, Legend);

const HalfCircle = ({ progress = 0, fontSize, color = "#fff" }) => {
   const t = useFindTranslationsByString();

   const data = {
      datasets: [
         {
            data: [progress, 100 - progress],
            backgroundColor: ["rgba(176, 121, 240, 1)", "rgba(62, 62, 65, 1)"],
            borderWidth: 0,
         },
      ],
   };

   const options = {
      rotation: -90, // Start at the top
      circumference: 180, 
      cutout: "80%",
      layout: {
         padding: {
            top: 80,
         },
      },
      plugins: {
         legend: { display: false }, 
         tooltip: { enabled: false },
      },
   };

   return (
      <div
         style={{
            width: `100%`,
            height: `200px`,
            position: "relative",
            margin: "auto",
            textAlign: "center",
         }}
         className={styles.wrapper}
      >
         <Doughnut data={data} options={options} />

         <div className={styles.text} style={{ fontSize: fontSize || "16px", color }}>
            <span>{progress ? Math.round(progress) : 0}%</span>
            <div className={styles.gray}>{t("Integration")}</div>
         </div>
      </div>
   );
};

export default HalfCircle;