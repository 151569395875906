import React, {useState} from 'react';
import styles from './styles.module.scss'
import Switch from "../../../components/Switch/Switch";
import img from "../../../assets/images/course_thumbnail.png";
import plus from '../../../assets/images/symbols/plus_purple.svg'
import usersImg from '../../../assets/images/select_img_white.svg'
import CopyInput from "../../../components/CopyInput/CopyInput";
import {COURSE_SETTINGS_SUBMENU, SUBMENU_BASIC_SETTINGS, TAGS} from "../../../constants/courses";
import cn from "classnames";
import useFindTranslationsByString from '../../../hooks/useFindTranslationsByString';

const SettingsBlock = ({setActiveTab, activeTab, course, data, setData, tagsInputRef, courseImage, courseProgressData }) => {
    const courseLink = `${window.location.origin}/courses/course/${course?.id}/default_lang/${course?.defaultLanguage || 'en_US'}/${course?.availableLanguages?.length > 1}`;

    const t = useFindTranslationsByString();

    const handleTagRemove = (index) => {
      const updatedTagsArray = [...data.new_tags]; 
      updatedTagsArray.splice(index, 1);
      setData({ ...data, new_tags: updatedTagsArray }); 
    };

    const onClickTab = (id) => setActiveTab(id);

    const handleAddTagClick = () => {
       if (activeTab !== SUBMENU_BASIC_SETTINGS) onClickTab(SUBMENU_BASIC_SETTINGS);
       setTimeout(()=> {
         if (tagsInputRef.current) tagsInputRef.current.focus();
       }, 0)
    };

    return (
        <div className={styles.settingsBlock}>
            <div className={styles.header}>
                <div>
                    <p className={styles.header_title}>{t("Publish")}</p>
                    <p className={styles.header_text}>{t("Publish or hide the course")}</p>
                </div>
                <Switch
                    checked={data?.new_status === 2 ? false : true}
                    onChange={() => setData({...data, new_status: +(data?.new_status === 2 ? 1 : 2)})}
                />
            </div>

            <div className={styles.content}>
                {/* <img src={courseImage ? courseImage : img} alt={''} className={styles.image}/> */}

                <p className={styles.block_title}>{t("Title")}</p>
                <p className={styles.title}>{course?.name || ''}</p>

                <p className={styles.block_title}>{t("Course tags")}</p>
                <div className={styles.tags}>
                    <div className={styles.tags_overlay}/>
                    <div className={styles.tags_list}>
                        {data?.new_tags?.map((item, key) => <div key={key}>
                           <span onClick={() => handleTagRemove(key)}>&#x2715;</span> {item}
                        </div>)}
                    </div>
                    <div className={styles.tags_plus} onClick={handleAddTagClick}><img src={plus} alt={''}/></div>
                </div>

                <p className={styles.block_title}>{t("Link to the course")}</p>

               {course?.id && 
                  <CopyInput value={courseLink} titleAfterCopied={t('Copied')}/>
               }

                <div className={styles.progress_block}>
                  <div className={styles.progress_top}>
                     <img src={usersImg} alt="" />
                     <p className={styles.title}>{t("User activity")}</p>
                  </div>
                  <div className={styles.progress_content}>
                     <div className={styles.progress_cotent__left}>
                        <span className={cn(styles.progress_title, styles.completed)}>{t("Completed")}:</span>
                        <span className={cn(styles.progress_title, styles.ongoing)}>{t("Ongoing")}:</span>
                     </div>
                     <div className={styles.progress_cotent__right}>
                        <div className={styles.progress_value_item}>
                           <span className={styles.progress_value}>
                             {courseProgressData?.completed} 
                           </span>
                           <span className={styles.total}> / {courseProgressData?.total}</span>
                        </div>
                        <div className={styles.progress_value_item}>
                           <span className={styles.progress_value}>{courseProgressData?.ongoing}</span>
                           <span className={styles.total}> / {courseProgressData?.total}</span>
                        </div>
                     </div>
                  </div>
                </div>

                <div className={styles.tabs}>
                    {COURSE_SETTINGS_SUBMENU?.map((item, key) =>
                        <div key={key}
                             className={cn(styles.tabs_item, activeTab === item?.value && styles.tabs_item_active)}
                             onClick={() => onClickTab(item?.value)}>
                            <img src={activeTab === item?.value ? item?.activeImage : item?.image} alt={''}/>
                            {t(item?.title)}
                        </div>)}
                </div>

            </div>

        </div>
    );
};

export default SettingsBlock;