import dotsWhite from "../../../assets/images/symbols/three_dots_white.svg";
import tests from "../../../assets/images/icon_task1.svg";
import testsActive from "../../../assets/images/icon_task.svg";
import lessonImg from "../../../assets/images/icon_play.svg";
import eye from "../../../assets/images/symbols/eye.svg";
import cn from "classnames";

import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import LessonApi from "../../../utils/api/LessonApi";
import { LESSON_TYPE_WITHOUT_TESTING_NUMBER, LESSON_TYPE_WITH_TESTING_NUMBER } from "../../../constants/courses";
import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import DraggingArrows from "../../../components/DraggingArrows/DraggingArrows";
import { encodeSanitizedInputValue, getRandomLetter } from "../../../utils/coursesHelper";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import useConfirm from "../../../hooks/useConfirm";
import CourseApi from "../../../utils/api/CourseApi";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const LessonItem = ({
   onClickElement,
   activeElement,
   lesson,
   moduleId,
   fetchData,
   questionsDataFromInputs,
   isLessonDragging,
   questions,
   lessonIndex,
   moduleIndex,
   isDeleteMode,
   onSelectFilters,
   selectedLessons,
   openIsDeleteMode,
   editLanguage,
   defaultCourseLanguage,
   defaultLessons,
   course,
   isNotDefaultLanguage
}) => {
   const params = useParams();
   const lessonDotsRef = useRef(null);
   const lessonDropdownRef = useRef(null);
   const lessonNameInputRef = useRef(null);

   const [renameInput, setRenameInput] = useState(lesson?.name || '');
   const [isLessonStatusHidden, setIsLessonStatusHidden] = useState(null);
   const [isLessonOptionsOpen, setIsLessonOptionsOpen] = useState(false);
   const [isRename, setIsRename] = useState(false);

   const t = useFindTranslationsByString();

   useEffect(()=> {
      setRenameInput(lesson?.name)
   }, [lesson])

   const confirmModal = useHandleModal();

   const { handleLinkClick, redirectToPage, confirmModal: confirmRedirectModal } = useConfirm();

   const onCloseLessonOptions = () => setIsLessonOptionsOpen(false);
   const onToggleLessonOptions = () => setIsLessonOptionsOpen(!isLessonOptionsOpen);

   useOutsideClick(lessonDropdownRef, onCloseLessonOptions, lessonDotsRef);

   const deleteLesson = async () => {
      try {
         const res = await new LessonApi().deleteLesson(activeElement.id);
         if (res?.success) {
            fetchData(editLanguage?.code);
            onClickElement(null);

            await new CourseApi().updateUsersProgressInCourse(params?.id);
         }
         if (res?.error) {
            return toast(<ToastMsg text={res?.error?.message} isError />);
         }
      } catch (error) {
         console.error("Error:", error);
      }
   };

   const renameLesson = () => {
      setIsRename(true);
      lessonNameInputRef?.current?.focus();
      onCloseLessonOptions();
   }

   const updateLessonName = async () => {
      if(renameInput?.length === 0) return;
      if(renameInput === lesson?.name) return;

      const res = await new LessonApi().updateLesson(lesson?.id, {
         new_name: renameInput?.trim(),
         new_language: editLanguage?.code
      });

      if(res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
         onClickElement(res?.success?.data?.lesson);
         fetchData(editLanguage?.code);
      }

      if(res?.error?.message) {
         setRenameInput('');
         return toast(<ToastMsg text={res?.error?.message} isError/>);
      }
   }

   const toggleLessonStatus = async () => {
      const res = await new LessonApi().updateLesson(lesson?.id, {
            new_status: isLessonStatusHidden ? 1 : 0
         });

      if(res?.success?.message) {
          setIsLessonStatusHidden(!isLessonStatusHidden)
          toast(<ToastMsg text={res?.success?.message} />);
          await fetchData()
      }

      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError/>);
      }
   }

   const showRenameInput = () => {
      setIsRename(true);
   }

   const onFocusOutName = () => {
      setIsRename(false);
      updateLessonName();
   }

   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        setIsRename(false);
        updateLessonName();
      }
    };

    const getOneLesson = async (id) => {
      const res = await new LessonApi().getOneLesson(id);
        if (res?.success?.data) {
            return res?.success?.data?.content
        }
    }

   const copyLesson = async (lesson) => {
      const newName = lesson?.name + `(${getRandomLetter()})`;
      onCloseLessonOptions();

      const lessonObjToSend = {
         name: newName,
         module_id: moduleId,
         type: lesson?.type,
         default_language: defaultCourseLanguage,
         description: lesson?.description
      };

      if (lesson?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER) {
         lessonObjToSend.content = await getOneLesson(lesson?.id);
         if(lesson?.availableDateRange) lessonObjToSend.available_date_range = lesson?.availableDateRange;
         lessonObjToSend.is_protected = lesson?.isProtected ? 1 : 0;
      }

      if (lesson?.type === LESSON_TYPE_WITH_TESTING_NUMBER) {
         lessonObjToSend.passing_score = lesson?.passingScore;
         lessonObjToSend.is_shuffle_answers = lesson?.isShuffleAnswers ? 1 : 0;
         if(lesson?.passAttempts) lessonObjToSend.pass_attempts = lesson?.passAttempts;
         if(lesson?.timeAllowed) lessonObjToSend.time_allowed = lesson?.timeAllowed;
         lessonObjToSend.is_hard = lesson?.isHard ? 1 : 0;
         lessonObjToSend.is_show_test_result = lesson?.isShowTestResult ? 1 : 0;
         lessonObjToSend.is_show_test_score = lesson?.isShowTestScore ? 1 : 0;
         if(lesson?.welcomeMessage) lessonObjToSend.welcome_message = lesson?.welcomeMessage;
         if(lesson?.congratulatoryMessage) lessonObjToSend.congratulatory_message = lesson?.congratulatoryMessage;
      }

      const createRes = await new LessonApi().createLesson(lessonObjToSend);

      if (createRes?.success?.data) {
         await fetchData(editLanguage?.code);
      }

      if (createRes?.error?.message) {
         return toast(<ToastMsg text={createRes?.error?.message} isError />);
      }

      if (lesson?.type === LESSON_TYPE_WITH_TESTING_NUMBER) {
         const modifiedQuestions = questionsDataFromInputs.map((question, idx) => {
            const {
              chosenFileType,
              isShuffled,
              ...questionItemWithoutFileType
            } = question;

            return {
              ...questionItemWithoutFileType,
              content: encodeSanitizedInputValue(question?.content),
              description: encodeSanitizedInputValue(question?.description),
              ...(question?.title?.length ? {title: encodeSanitizedInputValue(question?.title)} : {}),
              lesson_id: createRes?.success?.data?.lesson?.id,
              type: questions[idx]?.type,
              answers: question?.answers.map(
                ({ id, matchingOption, sequence, question, isCorrect, defaultLanguage, ...rest }) => ({
                  ...rest,
                  ...(matchingOption !== undefined && matchingOption !== null
                    ? { matching_option: matchingOption }
                    : {}),
                  is_correct: isCorrect ? 1 : 0,
                })
              ),
            };
          });

         await new LessonApi().processQuestions(
            modifiedQuestions
         );

         new CourseApi().updateUsersProgressInCourse(params?.id);
      }

      toast(<ToastMsg text={"Lesson has been coppied!"} />);
   };

   useEffect(()=> {
      if(isRename) {
         onFocusOutName();
      }
   }, [activeElement?.id])

   useEffect(()=> {
      setIsLessonStatusHidden(lesson?.status === 0)
   }, [])

   const lessonImgCondition = lesson?.type === 0 ? lessonImg : (activeElement?.id === lesson?.id ? testsActive : tests);

   return (
     <div
       key={lesson.id}
       onClick={() =>
         activeElement?.id !== lesson?.id && onClickElement(lesson)
       }
       className={cn(
         styles.element,
         activeElement?.id === lesson?.id ? styles.element_active : "",
         isLessonDragging ? styles.dragging : "",
       )}
     >
       <div
         onDoubleClick={showRenameInput}
         className={cn(
           styles.element_title,
           lesson?.name === defaultLessons?.[lessonIndex]?.name &&
             editLanguage?.code !== course?.defaultLanguage
             ? styles.prevInput
             : "",
         )}
       >
          {!isNotDefaultLanguage &&  (<div className={styles.draggingArrows}>
           <DraggingArrows
               isDragging={isLessonDragging}
               hideByDefault={true} className={styles.draggingArrowsItem}
             />

         </div>)}
         {isDeleteMode && (
           <div
             onDoubleClick={(e) => e.stopPropagation()}
             onClick={(e) => e.stopPropagation()}
             className={styles.lesson_checkbox}
           >
             <input
               id={`${lesson?.id}lesson`}
               type="checkbox"
               onChange={(e) => onSelectFilters(e, lesson)}
               checked={selectedLessons?.some(
                 (sItem) => sItem?.id === lesson?.id,
               )}
             />
             <label htmlFor={`${lesson?.id}lesson`}></label>
           </div>
         )}
         {!isDeleteMode && (
           <img
             src={lessonImgCondition}
             alt={""}
             width={24}
             height={24}
             className={styles.lesson_img}
           />
         )}

         <span className={styles.number}>
           {moduleIndex + 1}.{lessonIndex + 1}{" "}
         </span>
         <CustomTooltip
           limit={activeElement?.id === lesson?.id ? 9 : 15}
           maxWidth={"210px"}
           isWidthMoreThanParent
           delay={500}
           text={renameInput.length ? renameInput : lesson?.name}
           id={`lesson_name_${lesson?.id}`}
         />

         {isRename && (
           <input
             key={lesson?.id}
             type="text"
             className={styles.name_input}
             ref={lessonNameInputRef}
             onBlur={onFocusOutName}
             autoFocus={true}
             onKeyDown={handleKeyDown}
             value={renameInput}
             onChange={(e) => setRenameInput(e.target.value)}
           />
         )}
       </div>
       {lesson?.id === activeElement?.id && (
         <div className={styles.element_actions}>
           {!isNotDefaultLanguage &&
             <div onClick={toggleLessonStatus} className={styles.hide_wrapper}>
               {isLessonStatusHidden && (
                 <span className={styles.hiden_status}></span>
               )}
                 <img
                   className={cn(styles.lessson_status_icon)}
                   src={eye}
                   alt={""}
                 />
             </div>
           }

           <img
             ref={lessonDotsRef}
             onClick={onToggleLessonOptions}
             className={styles.lessons_dots}
             src={dotsWhite}
             alt={""}
           />

           {isLessonOptionsOpen && (
             <div ref={lessonDropdownRef} className={styles.dropdown}>
               <p
                 className={styles.options}
                 onClick={(e) =>
                   handleLinkClick(e, `/courses/lesson_settings/${lesson?.id}/${course?.id}/default_lang/${defaultCourseLanguage}/${editLanguage?.id}/${course?.availableLanguages?.length > 0}`)
                 }
               >
                 {t("Settings")}
               </p>
               {!isNotDefaultLanguage && (
                 <p
                   className={styles.options}
                   onClick={() => copyLesson(lesson)}
                 >
                   {lesson?.type === LESSON_TYPE_WITHOUT_TESTING_NUMBER
                     ? t("Copy lesson")
                     : t("Copy")}
                 </p>
               )}

               <p className={styles.options} onClick={renameLesson}>
                 {t("Rename")}
               </p>
               {!isNotDefaultLanguage && (
                 <p
                   className={styles.options}
                   onClick={() => {
                     confirmModal.open();
                     onCloseLessonOptions();
                   }}
                 >
                   {t("Delete")}
                 </p>
               )}
             </div>
           )}

           <ConfirmModal
             isRemove
             onConfirm={deleteLesson}
             onClose={confirmModal.close}
             isOpen={confirmModal.isActive}
             confirmButtonText={t("Delete lesson")}
             title={t("Are you sure?")}
             subtitle={
               "It will be deleted from all existed language versions of the course."
             }
           />

           <ConfirmModal
             confirmButtonText={t("Confirm")}
             isOpen={confirmRedirectModal.isActive}
             onClose={confirmRedirectModal.close}
             onConfirm={redirectToPage}
             maxHeight={"310px"}
             title={t("Are you sure?")}
             subtitle={t("Uploading files will be stopped")}
           />
         </div>
       )}
     </div>
   );
};

export default LessonItem;
