import React, { useEffect, useMemo, useState } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import TranslationApi from "../../utils/api/TranslationApi";
import AgencyApi from "../../utils/api/AgencyApi";
import { useSelector, useDispatch} from "react-redux";
import { useParams } from 'react-router-dom';
import { ALL_LANGUAGES_LIST, DEFAULT_LANG_LOCALE, initialDomain, defaultColumn, returnTranslation } from "../../constants/languages";
import LanguagesTable from "./LanguagesTable/LanguagesTable";
import Loader from "../../components/Loader/Loader";
import LanguagesSubheader from "./LanguagesSubheader/LanguagesSubheader";
import LanguagesSidebar from "./LanguagesSidebar/LanguagesSidebar";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import Papa from "papaparse";
import { setCompanyLanguages, setInfoLoading } from "../../store/actions/sidebar";
import useFindTranslations from "../../hooks/useFindTranlsations";

const LanguagesPage = () => {
   const currentCompanyId = useSelector((state) => state.sidebar.companyId);
   const dispatch = useDispatch();

   const params = useParams();
   const companyIdFromCompanySettingsPage = params?.company_id || null;

   const companyId = companyIdFromCompanySettingsPage ? companyIdFromCompanySettingsPage : currentCompanyId

   const [domainTab, setDomainTab] = useState(initialDomain);
   const [company, setCompany] = useState(null);

   const t = useFindTranslations();

   const [selectedLanguage, setSelectedLanguage] = useState("");
   const [selectedLanguages, setSelectedLanguages] = useState([]);
   const [defaultTranslations, setDefaultTranslations] = useState([]);
   const [allTranslations, setAllTranslations] = useState([]);
   const [newTranslations, setNewTranslations] = useState([]);
   const [mappedTranslationsWithRightPairs, setMappedTranslationsWithRightPairs] = useState([]);

   const [isLoading, setIsLoading] = useState(true);
   const [copyText, setCopyText] = useState(false);
   const [isAddLangLoading, setIsAddLangLoading] = useState(false);
   const [isUpdateLoading, setIsUpdateLoading] = useState(false);

   const [tableColumns, setTableColumns] = useState([defaultColumn]);

   const getDefaultTranslations = async () => {
      setIsLoading(true);

      const res = await new TranslationApi().getTranslations({
         companyId,
         locale: DEFAULT_LANG_LOCALE.code,
         domain: domainTab?.label,
      });

      if (res?.success?.data) {
         setDefaultTranslations(res?.success?.data);
      }

      setIsLoading(false);
   };

   const getAllTranslations = async () => {
      setIsLoading(true);

      const res = await new TranslationApi().getTranslations({
         companyId,
         domain: domainTab?.label,
         excluded_locale: DEFAULT_LANG_LOCALE?.code
      });

      if (res?.success?.data) {
         const transformed = res?.success?.data?.map((lang) => ({
            ...lang,
            [`translatedValue_${lang?.locale}`]: lang?.translatedValue,
         }));

         setAllTranslations(transformed);
      }

      setIsLoading(false);
   };

   const updateAvailableLanguages = (company) => {
      const newAvailableLangs = company?.availableLanguages?.filter(
         (lang) => lang !== defaultColumn?.code
      );

      const filteredLanguages =
         newAvailableLangs?.length > 0
            ? ALL_LANGUAGES_LIST.filter((lang) =>
                 newAvailableLangs?.includes(lang.code)
              )
            : [];

      const mappedLanguages = filteredLanguages?.map((lang) => ({
         ...lang,
         label: `translatedValue_${lang?.code}`,
      }));

      setTableColumns([defaultColumn, ...mappedLanguages]);
      setSelectedLanguages(mappedLanguages);
   };

   const fetchCompany = async () => {
      const res = await new AgencyApi().getAgency(companyId);

      const company = res?.success?.data;

      if (company) {
         setCompany(company);
      }

      updateAvailableLanguages(company);
   };

   const [progress, setProgress] = useState(0);
   
   const onSave = async () => {
      setIsUpdateLoading(true);
      setProgress(0);

      const TRANSLATION_CHUNK_SIZE = 50;
      const chunks = [];
      
      for (let i = 0; i < newTranslations.length; i += TRANSLATION_CHUNK_SIZE) {
        const chunk = newTranslations.slice(i, i + TRANSLATION_CHUNK_SIZE);
        chunks.push(chunk);
      }
    
      const responses = [];
      let i = 0;
      for (const chunk of chunks) {
         i++;

        const data = {
          translations: chunk.map((item) => ({
            locale: item?.locale,
            domain: item?.domain,
            original_value: item?.original_value,
            translated_value: item?.translated_language,
          })),
          company_id: companyId,
        };
    
        try {
          // Send the chunk to the server
          const res = await new TranslationApi().setTranslations(data);
          responses.push(res);
           // Update progress
          const newProgress = ((i + 1) / chunks?.length) * 100;
          setProgress(newProgress?.toFixed());
        } catch (error) {
          console.error("Error sending translation chunk:", error);
          toast(<ToastMsg isError text="An error occurred while saving translations." />);
        }
      }
    
      // Process responses after all chunks have been sent
      responses.forEach((res) => {
        if (res?.success?.message) {
          toast(<ToastMsg text={res?.success?.message} />);
        }
    
        if (res?.error?.message) {
          toast(<ToastMsg isError text={res?.error?.message} />);
        }
      });
    
      setNewTranslations([]);
      setIsUpdateLoading(false);
    };
    

   const addAvailableLanguages = async (langs) => {
      dispatch(setInfoLoading(true));
      setIsAddLangLoading(true);
      const data = {
         new_available_languages: langs
           ?.map((lang) => lang?.code)
           .concat('en_US') 
           .filter((value, index, self) => self.indexOf(value) === index)
       };
       
      const res = await new AgencyApi().editAgencySetting(companyId, data);

      if (res?.success?.data?.company) {
         updateAvailableLanguages(res?.success?.data?.company);
         setCompany({
            ...company,
            availableLanguages: res?.success?.data?.company?.availableLanguages,
         });

          toast(<ToastMsg text={res?.success?.message} />);

        if(!companyIdFromCompanySettingsPage) dispatch(setCompanyLanguages(res?.success?.data?.company?.availableLanguages));
      }


      if (res?.error?.message) {
          toast(<ToastMsg text={res?.error?.message} isError />);
      }

      setIsAddLangLoading(false);
      dispatch(setInfoLoading(false));
   };

   const removeAvailableLanguage = async (lang) => {
      dispatch(setInfoLoading(true));

      const availableLangs = selectedLanguages?.filter(
         (l) => l.code !== lang?.code
      );

      const data = {
         new_available_languages: availableLangs
           ?.map((lang) => lang?.code)
           .concat('en_US') 
           .filter((value, index, self) => self.indexOf(value) === index) 
       };
       

      const res = await new AgencyApi().editAgencySetting(companyId, data);

      if (res?.success) {
         fetchCompany();
         getDefaultTranslations();
         getAllTranslations();

         dispatch(setCompanyLanguages(res?.success?.data?.company?.availableLanguages));

          toast(<ToastMsg text={res?.success?.message} />);
      }

      if (res?.error?.message) {
          toast(<ToastMsg text={res?.error?.message} isError />);
      }

      dispatch(setInfoLoading(false));
   };

   useEffect(() => {
      if (companyId) {
         getDefaultTranslations();
         getAllTranslations();
         fetchCompany();
      }
   }, [companyId, domainTab]);

   const onSelectLanguage = (item) => {
      setSelectedLanguage(item);

      const newAvailableLangs = company?.availableLanguages?.filter(
         (lang) => lang !== defaultColumn?.code
      );

      const filteredLanguages =
         newAvailableLangs?.length > 0
            ? ALL_LANGUAGES_LIST.filter((lang) =>
                 newAvailableLangs?.includes(lang.code)
              )
            : [];

      const mappedLanguages = filteredLanguages?.map((lang) => ({
         ...lang,
         label: `translatedValue_${lang?.code}`,
      }));

      if (item?.label === "all") {
         setTableColumns([defaultColumn, ...mappedLanguages]);
      } else {
         setTableColumns([defaultColumn, item]);
      }
   };

   const copyTable = () => {
      const elTable = document.querySelector("table");
      const tempTextArea = document.createElement("textarea");

      let tableText = "";
      if (elTable) {
         tableText = Array.from(elTable.querySelectorAll("tr"))
            .map((row) =>
               Array.from(row.querySelectorAll("td, th"))
                  .map((cell) => cell.textContent.trim())
                  .join("\t")
            )
            .join("\n");
      }

      tempTextArea.value = tableText;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      document.execCommand("copy");
      document.body.removeChild(tempTextArea);

      setCopyText(true);
      setTimeout(() => {
         setCopyText(false);
      }, 2000);
   };

   const onSelectedLanguagesChange = (item) => {
      if (selectedLanguages.some((lang) => lang.id === item.id)) {
         const newLangsWithRemoved = selectedLanguages.filter(
            (lang) => lang.id !== item.id
         );

         addAvailableLanguages(newLangsWithRemoved);
      } else {
         const newLangs = [...selectedLanguages, item];
         addAvailableLanguages(newLangs);
      }
   };

   useEffect(() => {
      const items = defaultTranslations?.map((defaultTrans) => {
         const updatedTrans = { ...defaultTrans };

         allTranslations?.forEach((aTrans) => {
            if (aTrans?.originalValue === defaultTrans?.translatedValue) {
               updatedTrans[`translatedValue_${aTrans?.locale}`] =
                  aTrans?.translatedValue || null;
            }
         });

         return updatedTrans;
      });

      setMappedTranslationsWithRightPairs(items);
   }, [defaultTranslations, allTranslations ]);

   const parseCSV = (csvData) => {
      const parsedData = Papa.parse(csvData, {
         header: true,
         skipEmptyLines: true,
      });
      return parsedData.data;
   };

   const handleFileUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      if(file) {
         e.target.value = '';
      }

      reader.onload = (event) => {
        const contents = event.target.result;
        const parsedData = parseCSV(contents);
         
        const updatedTranslations = [...newTranslations];
        const updatedData = [...mappedTranslationsWithRightPairs];
    
        const defaultColumn = tableColumns?.[0] || {};
    
        parsedData.forEach((item) => {
          Object.keys(item).forEach((key) => {

            // Find the corresponding column for the current key, excluding the default column
            if (key !== defaultColumn?.name) { // Skip default column
              const foundColumn = tableColumns?.find((tColumn) => tColumn?.name === key);

              if (foundColumn) {
                const localeCode = foundColumn?.code;
                const columnName = foundColumn?.name;
    
                if (localeCode && item[columnName] !== undefined) {
                  const originalText = item[defaultColumn?.name]; 
               
                  const existingMapping = mappedTranslationsWithRightPairs.find(
                    (mapping) =>
                      mapping.originalValue?.trim() === originalText?.trim() &&
                      item[key] !== mapping[`translatedValue_${localeCode}`] &&
                      item[key]?.length
                  );

                  if (existingMapping) {
                    const updatedTranslation = {
                      domain: existingMapping.domain,
                      locale: localeCode,
                      original_value: existingMapping?.originalValue,
                      translated_language: item[key], 
                    };
    
                    const existingTranslationIndex = updatedTranslations.findIndex(
                      (t) =>
                        t.original_value === existingMapping.original_value &&
                        t.locale === localeCode 
                    );
    
                    if (existingTranslationIndex >= 0) {
                      updatedTranslations[existingTranslationIndex] = updatedTranslation;
                    } else {
                      updatedTranslations.push(updatedTranslation);
                    }
    
                    updatedData.forEach((dataItem) => {
                      if (
                        dataItem.originalValue?.trim() === originalText?.trim() &&
                        dataItem[`translatedValue_${localeCode}`]?.trim() !== originalText?.trim()
                      ) {
                        dataItem[`translatedValue_${localeCode}`] = item[key]; 
                      }
                    });
                  }

                }
              }
            }
          });
        });

        setMappedTranslationsWithRightPairs(updatedData);
        setNewTranslations(updatedTranslations);
      };
    
      reader?.readAsText(file);
    };
    
   return (
      <MainLayout>
         <div className={styles.settings}>
            <LanguagesSidebar
               removeAvailableLanguage={removeAvailableLanguage}
               companyId={companyId}
               setDomainTab={setDomainTab}
               domainTab={domainTab}
               selectedLanguages={selectedLanguages}
               onSelectedLanguagesChange={onSelectedLanguagesChange}
               isAddLangLoading={isAddLangLoading}
               t={t}
            />

            <div className={styles.table_wrapper}>
               <LanguagesSubheader
                  activeFilter={selectedLanguage}
                  setActiveFilter={onSelectLanguage}
                  copyTable={copyTable}
                  onSave={onSave}
                  copyText={copyText}
                  availableLanguages={[
                     { name: t?.all, label: "all"},
                     ...selectedLanguages,
                  ]}
                  newTranslations={newTranslations}
                  isUpdateLoading={isUpdateLoading}
                  handleFileUpload={handleFileUpload}
                  t={t}
                  progress={progress}
               />

               <LanguagesTable
                  columns={tableColumns}
                  data={mappedTranslationsWithRightPairs}
                  setData={setMappedTranslationsWithRightPairs}
                  isLoading={isLoading}
                  setNewTranslations={setNewTranslations}
                  newTranslations={newTranslations}
                  allTranslations={allTranslations}
               />

               {isLoading && (
                  <div className="default_loader_wrapper">
                     <Loader size="small" />
                  </div>
               )}
            </div>
         </div>
      </MainLayout>
   );
};

export default LanguagesPage;
