import { useRef, useState } from "react";
import styles from "./styles.module.scss";
import book from "../../assets/images/symbols/book.svg";
import person from "../../assets/images/symbols/user_icon--white.svg";
import { ReactComponent as TempFolderIcon } from "../../assets/images/folder_icons/icons_for_icon_picker-20.svg";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/dom/useOutsideClick";
import {
  FOLDER_STATUS_DELETED,
  FOLDER_STATUS_HIDEN,
} from "../../constants/courses";
import { FOLDER_ICONS_LIST } from "../../constants/folders";
import { useSelector } from "react-redux";
import { PART_OF_MENTOR_NAME, ROLE_ID_STUDENT } from "../../constants/user";
import cn from "classnames";
import CustomStaticTooltip from "../CustomStaticTooltip/CustomStaticTooltip";
import { v4 as uuidv4 } from "uuid";
import useUserRole from "../../hooks/useUserRole";
import { DEFAULT_TEXT, returnTranslation } from "../../constants/languages";
import DotsButton from "../DotsButton/DotsButton";

const FolderBlock = ({
  folder,
  activeFilter,
  isDragging = false,
  onConfirmDeleteOpen,
  onConfirmHideOpen,
  onConfirmOpenRestore,
  onConfirmOpenDelete,
  onConfirmOpenChangeHidenStatus,
  lastFolderRef,
  onRedirectToEditFolderPage,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dotsRef = useRef();
  const dropdownRef = useRef();

  const asStudent = useSelector((state) => state.sidebar.isUserAsStudent);
  const userInfo = useSelector((state) => state.user.info);
  const translations = useSelector((state) => state.sidebar.translations);

  const currentCompanyId = useSelector((state) => state.sidebar.companyId);
  const { userRoleId, userRoleName } = useUserRole(currentCompanyId);

  const onCloseSettings = () => setIsOpen(false);

  useOutsideClick(dropdownRef, onCloseSettings, dotsRef);

  const navigate = useNavigate();

  const navigateToFolderPage = () => {
    if (isDragging) return;
    navigate(`/courses/folder/${folder?.id}`);
  };

  const onToggleSettings = (e) => {
    e.stopPropagation();
    setIsOpen(prev=> !prev);
  };
  const isDeletedStatus = folder?.status === FOLDER_STATUS_DELETED;

  const returnChosenIcon = () => {
    const matchingIcon = FOLDER_ICONS_LIST.find(
      (item) => item?.name === folder?.folder_icon,
    );

    return matchingIcon ? (
      <matchingIcon.Icon fill={isDeletedStatus ? "white" : folder?.hexColor} />
    ) : (
      <TempFolderIcon  fill={isDeletedStatus ? "white" : folder?.hexColor} />
    );
  };

  const hideText = returnTranslation(translations, DEFAULT_TEXT.common.hide);
  const deleteText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.delete,
  );
  const restoreText = returnTranslation(
    translations,
    DEFAULT_TEXT.common.restore,
  );
  const editText = returnTranslation(translations, DEFAULT_TEXT.common.edit);
  const changeToVisible = returnTranslation(
    translations,
    DEFAULT_TEXT.common.changeToVisible,
  );

  const coursesInsideFolder = returnTranslation(
    translations,
    DEFAULT_TEXT.courses_and_folders.coursesInsideFolder,
  );
  const usersAccessCoursesFolder = returnTranslation(
    translations,
    DEFAULT_TEXT.courses_and_folders.usersAccessCoursesFolder,
  );

  const hasPermissionToEditFolder = userInfo?.permissions?.some(p => p?.id === 313);

  return (
    <div
      ref={lastFolderRef}
      onClick={navigateToFolderPage}
      className={cn(
        styles.folderBlock,
        "noselect",
        folder?.status === FOLDER_STATUS_HIDEN && styles.hidden_layout,
        isDeletedStatus && styles.deleted_layout,
      )}
      style={
        isDeletedStatus
          ? {}
          : { background: `${folder?.folder}` }
      }
    >
      <div className={styles.topBlock}>
        {!!folder?.isNew && <p className={styles.new}>NEW</p>}

        {userRoleId !== ROLE_ID_STUDENT &&
          !userRoleName?.includes(PART_OF_MENTOR_NAME) &&
          userRoleId &&
          !asStudent && (
            <DotsButton
              dotsRef={dotsRef}
              isOpen={isOpen}
              onToggle={onToggleSettings}
            />
          )}

        {isOpen && (
          <div ref={dropdownRef} className={styles.dropdown}>
            {activeFilter === "hidden" && (
              <p onClick={(e) => onConfirmOpenChangeHidenStatus(e, folder)}>
                {changeToVisible}
              </p>
            )}

            {activeFilter === "deleted" && (
              <>
                <p onClick={(e) => onConfirmOpenRestore(e, folder)}>
                  {restoreText}
                </p>
              </>
            )}

            {activeFilter !== "deleted" && (
              <p onClick={(e) => onConfirmDeleteOpen(e, folder)}>
                {deleteText}
              </p>
            )}

            {activeFilter !== "hidden" && (
              <p onClick={(e) => onConfirmHideOpen(e, folder)}>{hideText}</p>
            )}

            <p onClick={(e) => onRedirectToEditFolderPage(e, folder)}>
              {editText}
            </p>
          </div>
        )}
      </div>

      <div className={styles.folder_icon}>{returnChosenIcon()}</div>

      <p className={styles.title}>
        {folder?.title && folder.title.length > 21
          ? `${folder.title.slice(0, 21)}...`
          : folder?.title}

        {folder?.title && folder.title.length > 21 && (
          <span className={styles.hiddenTitle}>{folder.title}</span>
        )}
      </p>

      <div className={styles.bottomBlock}>
        <div>
          <img src={book} alt={""} />
          <CustomStaticTooltip
            hint={coursesInsideFolder}
            text={folder?.coursesCount || 0}
            id={`${folder?.id}${uuidv4()}`}
          />
        </div>

        <div>
          <img src={person} alt={"preson"} />
          <CustomStaticTooltip
            hint={usersAccessCoursesFolder}
            text={folder?.readers || 0}
            id={`${folder?.id}${uuidv4()}`}
          />
        </div>
      </div>
    </div>
  );
};

export default FolderBlock;
