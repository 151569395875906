import React, { useState } from "react";
import styles from "./styles.module.scss";
import { AGENCY_SETTINGS_SUBMENU } from "../../../constants/agencies";
import { useParams } from "react-router-dom";
import { ROLE_ID_SUPER_ADMIN } from "../../../constants/user";
import { useSelector } from "react-redux";

const AgencySettingsSidebar = ({ onMenuItemClick, activeElement, setActiveElement, t }) => {
   const userRoles = useSelector(state => state?.user?.info?.userBranchRoles);
   const userInfo = useSelector(state => state?.user?.info);

   const [submenuVisible, setSubmenuVisible] = useState(true);
   const params = useParams();

   const toggleSubmenuVisibility = () => {
      setSubmenuVisible(!submenuVisible);
   };

   const hasRightToAddUsersToCompany = userInfo?.permissions?.some(p => p?.id === 254);

   return (
      <aside className={styles.page_navigation}>
         <ul className={styles.page_menu}>
            <li className={`${styles.page_menu_item} ${styles.active}`}>
               <button
                  className={`${styles.icon_before} ${
                     styles.icon_before_settings
                  } ${styles.dropdown_button} ${
                     submenuVisible ? styles.active : ""
                  }`}
                  onClick={toggleSubmenuVisibility}
               >
                  {t?.settings}
               </button>
               <ul
                  className={`${styles.page_submenu} ${
                     submenuVisible ? "" : styles.page_menu_item_hide
                  }`}
               >
                  {AGENCY_SETTINGS_SUBMENU.map((item) => (
                     <li key={item.id} className={styles.page_submenu_item}>
                        <a
                           className={`${
                              styles[item.el === activeElement ? "active" : ""]
                           } ${styles.page_submenu_link}`}
                           href="#"
                           onClick={(e) => onMenuItemClick(e, item)}
                        >
                           {t?.returnTranslation(item.text)}
                        </a>
                     </li>
                  ))}
               </ul>
            </li>

            {params.id !== ':id' 
                  && userRoles?.length > 0 
                  && (userRoles?.some(role => role?.role?.id === ROLE_ID_SUPER_ADMIN) || hasRightToAddUsersToCompany) &&
             <ul className={styles.page_menu}>
               <li className={`${styles.page_menu_item} ${styles.active}`}>
                  <button
                     className={`${styles.icon_before} ${
                        styles.icon_before_users
                     } 
                     ${styles.dropdown_button}
                     ${activeElement === 'users' ? styles.active : ""}`}
                     onClick={()=> setActiveElement('users')}
                  >
                     {t?.returnTranslation("Users and roles")}
                  </button>
               </li>
            </ul>
            }
           
            {/* 
            // FOR FUTURE
            <li className={styles.page_menu_item}>
               <button
                  className={`${styles.dropdown_button} ${styles.icon_before} ${styles.icon_before_visual}`}
               >
                  Visual
               </button>
            </li>
            <li className={`${styles.page_menu_item} ${styles.has_submenu}`}>
               <button
                  className={`${styles.dropdown_button} ${styles.icon_before} ${styles.icon_before_languages}`}
               >
                  Languages
               </button>
            </li> */}
         </ul>
      </aside>
   );
};

export default AgencySettingsSidebar;
