import styles from "./styles.module.scss";
import cn from "classnames";

const CompareAnswerMobile = ({
   answer,
   question,
   answerIdx,
   correctImg,
   iconWrong,
   compareImg,
   initialAns
}) => {


  const initAnsIndex = question?.initialAns?.findIndex((ans) => ans?.content === answer.content);

   return (
      <div
         className={cn(
            styles.sequence_right_answers,
            styles.compare_answer_mobile
         )}
      >
         <div className={styles.nums}>{answerIdx + 1}.</div>
         <div className={styles.compare_right_wrapper}>
            <div className={styles.compare_left_side}>
               {question?.options[answerIdx]}{" "}
            </div>
            <div
               className={cn(
                  styles.compare_right_side,
                  styles.compare_right_side_mobile,
                  answer.status === 1
                     ? styles.correct_answer
                     : styles.wrong_answer
               )}
            >
               <img className={styles.compareIcon} src={compareImg} alt="" />
               <div> {String.fromCharCode(97 + initAnsIndex) + ")"} </div>
               <img
                  className={styles.resultIcon}
                  src={answer.status === 1 ? correctImg : iconWrong}
                  alt=""
               />
            </div>
         </div>
      </div>
   );
};

export default CompareAnswerMobile;
