import React, { useEffect, useState } from "react";
import useSortableAndFuzzyData from "../../../hooks/useSortableAndFuzzyData";
import dots from '../../../assets/images/symbols/three_dots_white.svg'

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from "./styles.module.scss";
import Roles from "../../../utils/api/Roles";
import { ROLE_ID_STUDENT, REQUESTED_STATUS_REJECT, REQUESTED_STATUS_ACCEPT, USER_STATUS_BANNED } from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import useOptions from "../../../hooks/useOptions";
import TeamBodyItem from "./TeamBodyItem";
import TeamApi from "../../../utils/api/TeamApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const TeamTable = ({ columns, data, searchedValue, tableFor, refetchUsers, isUsersloading, isAccess, onRemoveAccess, redirectToUserProfileAdminView, lastItemRef }) => {
    const { handleSort } = useSortableAndFuzzyData(data, columns);

    const [userIdTodeleteFromPlatform, setUserIdTodeleteFromPlatform] = useState(null);
    const [userIdTodeleteFromTeam, setUserIdTodeleteFromTeam] = useState(null);
    const [isChangeLoading, setIsChangeLoading] = useState(false);

    const t = useFindTranslationsByString();

    const confirmModal = useHandleModal();
    const confirmModalUser = useHandleModal();
    const params = useParams();

    const openConfirmModal = (userId) => {
      setUserIdTodeleteFromPlatform(userId);
      confirmModal.open();
    }

    const onOpenDeleteUserFromTeamModal = (id) => {
      confirmModalUser.open();
      setUserIdTodeleteFromTeam(id);
    }

    const onDeleteFromTeam = async (userId) => {
      setIsChangeLoading(true);
      const res = await new TeamApi().removeUserFromTeam(params?.id, userId);

      if(res?.success?.message) {
         refetchUsers();
      }

      setIsChangeLoading(false);
      confirmModalUser.close();
      if(res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />)
      }
    }

    const onDeleteFromPlatform = async () => {
      onDeleteFromTeam(userIdTodeleteFromPlatform);
      const res = await new UserApi().updateUserStatus(userIdTodeleteFromPlatform, {
         new_status: USER_STATUS_BANNED
      });
      confirmModal.close();
    }

   if(!data?.length && searchedValue?.length > 0) {
      return <EmptyBlock text={'data'}/>;
   }

   return (
      <div className={styles.table_container}>
         <table className={styles.table}>
            <thead className={styles.thead}>
               <tr>
                  {columns.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={styles.header}
                     >
                        <span>{t(column.replace(/_/g, ' '))}</span>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>
                     </th>
                  ))}
                 <th></th> 
               </tr>
            </thead>
            <tbody className={styles.tbody}>
            <TransitionGroup component={null}>
               {data?.map((row, index) => (
                  <CSSTransition key={`row-${index}`} classNames="fade" timeout={100}>
                     <>
                        <TeamBodyItem 
                           row={row} 
                           data={data}
                           columns={columns} 
                           index={index}
                           onDeleteFromPlatform={openConfirmModal}
                           onDeleteFromTeam={onOpenDeleteUserFromTeamModal}
                           onRemoveAccess={onRemoveAccess}
                           isAccess={isAccess} 
                           lastItemRef={lastItemRef}
                           redirectToUserProfileAdminView={redirectToUserProfileAdminView}
                           t={t}
                        />
                     </>
                  </CSSTransition>
               ))}
               </TransitionGroup>

            </tbody>
         </table>

         {data?.length === 0 && !isUsersloading && !searchedValue?.length && (
            <div>
               <EmptyBlock  text= {t('There are no data')}/>
            </div>
         )}
        
        <ConfirmModal
               confirmButtonText={t('Block user')}
               onConfirm={onDeleteFromPlatform}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t("Are you sure?")}
            />
         <ConfirmModal
            confirmButtonText={t("Remove")}
            onConfirm={()=> onDeleteFromTeam(userIdTodeleteFromTeam)}
            onClose={confirmModalUser.close}
            isOpen={confirmModalUser.isActive}
            title={t("Are you sure?")}
            subtitle={`*${t("Access for courses of team won't be removed from user")}.`}
            isLoading={isChangeLoading}
         />
      </div>
   );
};

export default TeamTable;
