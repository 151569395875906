import React from "react";

import cn from "classnames";
import styles from "./styles.module.scss";
import SearchInput from "../../../components/SearchInput/SearchInput";
import StatisticsProgressFilter from "../StatisticsProgressFilter/StatisticsProgressFilter";

const StatisticsSubheader = ({
   leftText,
   leftSubText,
   searchedValue,
   setSearchedValue,
   activeProgressFilter,
   setActiveProgressFilter,
   onProgressConfirm,
   progressRange
}) => {
   return (
      <div className={cn(styles.table_subheader)}>
         <div className={styles.left}>
            <p className={styles.subtitle}>{leftText}</p>
            <p className={styles.gray}>{leftSubText}</p>
         </div>

         <div className={styles.right}>
            <SearchInput
               setSearchedValue={setSearchedValue}
               searchedValue={searchedValue}
               withBorder
            />
            <StatisticsProgressFilter progressRange={progressRange} onConfirm={onProgressConfirm}/>
         </div>
      </div>
   );
};

export default StatisticsSubheader;
