import React, { useEffect, useRef, useState } from "react";
import arrowLeft from "../../../assets/images/arrow_left.svg";
import hat from "../../../assets/images/symbols/hat.svg";
import dots from "../../../assets/images/symbols/three_dots.svg";
import plus from "../../../assets/images/symbols/plus.svg";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import ModuleApi from "../../../utils/api/ModuleApi";
import LessonApi from "../../../utils/api/LessonApi";
import Input from "../../../components/Input/Input";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import Loader from "../../../components/Loader/Loader";
import ModuleItem from "../ModuleItem/ModuleItem";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import CourseApi from "../../../utils/api/CourseApi";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import ChangeCourseLanguageModal from "../../../components/Modals/ChangeCourseLanguageModal/ChangeCourseLanguageModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import styles from "./styles.module.scss";
import { COURSE_STATUS_DELETED } from "../../../constants/courses";
import { COURSE_LANGUAGES_LIST } from "../../../constants/languages";
import { useSelector } from "react-redux";
import useUserRole from "../../../hooks/useUserRole";
import useConfirm from "../../../hooks/useConfirm";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const SubmenuBlock = ({
   onClickModule,
   activeModule,
   onClickElement,
   activeElement,
   course,
   defaultCourse,
   modulesWithCount,
   questionsDataFromInputs,
   fetchData,
   loading,
   amountOfTestsAndLessons,
   modules,
   defaultModules,
   questions,
   setModules,
   fetchCourse,
   createModule,
   isLessonsSidebarOpen,
   changeEditLanguage,
   editLanguage,
   changeLanguageModal,
   defaultCourseLanguage,
   fetchDefaultModulesData,
   isNotDefaultLanguage
}) => {
   const params = useParams();
   const navigate = useNavigate();

   const t = useFindTranslationsByString();

   const dropdownRef = useRef();
   const dotsRef = useRef();
   const langsRef = useRef();

   const confirmMoveToOtherModuleModal = useHandleModal();
   const confirmDeleteManyLessons = useHandleModal();

   const [isCourseOptionOpen, setIsCourseOptionOpen] = useState(false);
   const [clickedSaveCourseTitle, setClickedSaveCourseTitle] = useState(false);
   const [isDeleteMode, setIsDeleteMode] = useState(false);
   const [selectedLessons, setSelectedLessons] = useState([]);
   
   const [isAddElement, setIsAddElement] = useState(false);
   const [elementName, setElementName] = useState("");
   const [courseNewName, setCourseNewName] = useState("");
   const [isRenameCourse, setIsRenameCourse] = useState(false);
   const [draggedToOtherModuleLesson, setDraggedToOtherModuleLesson] = useState(null);

   const clickedLanguageName = COURSE_LANGUAGES_LIST?.find(lang => lang?.code === editLanguage?.code)?.name;

   useEffect(()=> {
      if(course?.name) setCourseNewName(course?.name || "")
   }, [course])

   const currentCompanyId = useSelector(state => state.sidebar.companyId);
   const { userRoleId } = useUserRole(currentCompanyId);

   const onToggle = () => setIsCourseOptionOpen(!isCourseOptionOpen);
   const onClose = () => setIsCourseOptionOpen(false);
   const courseLink = `${window.location.origin}/courses/course/${params?.id}/default_lang/${defaultCourseLanguage}/${params?.is_few_langs}`;

   const openIsDeleteMode = () => setIsDeleteMode(true);
   const closeIsDeleteMode = () => setIsDeleteMode(false);

   useOutsideClick(dropdownRef, onClose, dotsRef);
   
   const onConfirmOpen = () => confirmModal.open();
   const onOpenConfrimDeleteManyLessons = () => confirmDeleteManyLessons.open();

   const confirmModal = useHandleModal();

   const { dispatch, setConfirmModalNeeded, handleLinkClick, redirectToPage, confirmModal: confirmRedirectModal } = useConfirm();

   const onCourseDelete = async () => {
      const res = await new CourseApi().editCourseSetting(params?.id, {
         new_status: COURSE_STATUS_DELETED
      });

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }

      if (res?.success?.message) {
         dispatch(setConfirmModalNeeded(false));
         toast(<ToastMsg text={res?.success?.message} />);
         navigate(`/courses/`);
      }
   };

   const deleteManyLessons = async() => {
      const data = {
         id: selectedLessons?.map(lesson => lesson?.id)
      }

      if(selectedLessons?.length === 0) {
         return toast(<ToastMsg text={'Choose at leat one lesson'} isError />)
      }

      const res = await new LessonApi().deleteManyLessons(data);

      closeIsDeleteMode();

      if (res?.success) {
         fetchData();
         onClickElement(null);

         toast(<ToastMsg text={res?.success?.message} />)
      }

      if(res?.error?.message) {
         toast(<ToastMsg text={res?.error?.message} isError />);
      }

      confirmDeleteManyLessons.close();
      setSelectedLessons([])
   }

   const onCopyCourseLink = () => {
      navigator.clipboard.writeText(courseLink);
      onClose();
      toast(<ToastMsg text={"Course link copied to clipboard!"} />);
   };

   const onViewAsStudent = (e) => {
      e.preventDefault();
      const newTab = window.open(courseLink, '_blank');
      newTab.focus();
   };

   const onRenameCourse = () => {
      setIsRenameCourse(true);
      onClose();
   };

   const updateCourseName = async (lang, needChechPrevName = true) => {
      if(!courseNewName?.length) return;
      if(courseNewName === course?.name && needChechPrevName) return;

      if(courseNewName === defaultCourse?.name) {
         setCourseNewName(course?.name);
         setClickedSaveCourseTitle(true);
         return toast(<ToastMsg text={"Name should not be the same as in main language"} isError />);
      }
      
      const res = await new CourseApi().editCourseSetting(params?.id, {
         new_name: courseNewName.trim(),
         ...(lang ? { new_language: lang } : {})
      });

      if (res?.success?.message) {
         fetchCourse(false, lang);
         toast(<ToastMsg text={`${res?.success?.message} (${clickedLanguageName} version)`} />);
         changeLanguageModal.close();
         setClickedSaveCourseTitle(false);
      }

      if (res?.error) {
         setCourseNewName('');
         return toast(<ToastMsg text={res?.error?.message} isError />);
      }
   };

   const courseNameChange = () => {
      setIsRenameCourse(false);
      updateCourseName(editLanguage?.code || null);
   };

   const handleKeyDown = (event) => {
      if (event.key === "Enter") {
         courseNameChange();
      }
   };

   const handleKeyDownCreateModule = (event) => {
      if (event.key === 'Enter') {
       if (isAddElement) {
         setIsAddElement(!isAddElement);
         setElementName('');
         if (isAddElement) createModule(elementName);
        }
      }
    };

   const showChangeNameInput = () => {
      setIsRenameCourse(true);
   };

   const updateModulesSequence = async (reorderedModules) => {
      const reorderedModulesData = reorderedModules.map((item, index) => {
         // Assing new sequence for each module
         return  {
            module_id: item.id,
            new_sequence: index + 1
         }
      })

      const dataToSend = {
         modules: reorderedModulesData
      }

     const res = await new ModuleApi().changeModulesSequence(dataToSend);

     if (res?.error?.message) {
      return toast(<ToastMsg text={res?.error?.message} isError />);
     }

      if (res?.success?.message) {
         toast(<ToastMsg text={res?.success?.message} />);
      }
   }

   const updateLessonsSequence = async (openedModule) => {
      const reorderedLessonsData = openedModule?.lessons.map((item, index) => {
         // Assing new sequence for each lessson
         return  {
            lesson_id: item.id,
            new_sequence: index + 1
         }
      })

      const dataToSend = {
         lessons: reorderedLessonsData
      }

      await new LessonApi().changeLessonsSequence(dataToSend);
   }

   const moveLesson = async (lessonId, moduleId) => {
      const res = await new LessonApi().updateLesson(lessonId, {
         new_module_id: moduleId
      })

      await new LessonApi().changeLessonsSequence({
         lessons: [{lesson_id: lessonId, new_sequence: 1}]
      });

      if (res?.error?.message) {
         return toast(<ToastMsg text={res?.error?.message} />)
      }
   }

   const onMoveLessonToOtherModule = () => {
      if (draggedToOtherModuleLesson) {
         const { source, destination } = draggedToOtherModuleLesson;

         const lessonSourceIndex = source.index;
         const lessonDestinationIndex = destination.index;

         const moduleSourceIndex = modules.findIndex(
            (module) => module.id === +source.droppableId
          );
    
          const moduleDestinationIndex = modules.findIndex(
            (module) => module.id === +destination.droppableId
          );
    
          const newSourceLessons = [...modules[moduleSourceIndex].lessons];
          const newDestinationLessons =
            source.droppableId !== destination.droppableId
              ? [...modules[moduleDestinationIndex].lessons]
              : newSourceLessons;
      
          const [deletedLesson] = newSourceLessons.splice(lessonSourceIndex, 1);
          newDestinationLessons.splice(lessonDestinationIndex, 0, deletedLesson);
      
          const newModules = [...modules];
      
          newModules[moduleSourceIndex] = {
            ...modules[moduleSourceIndex],
            lessons: newSourceLessons,
          };
    
          newModules[moduleDestinationIndex] = {
            ...modules[moduleDestinationIndex],
            lessons: newDestinationLessons,
          };

          const lessonSourceLesson = modules[moduleSourceIndex]?.lessons?.find(
            (lesson, idx) => idx === +source.index
          );
      
         moveLesson(lessonSourceLesson?.id, destination.droppableId); 
         onClickModule(+destination.droppableId);
         setModules(newModules);
         setDraggedToOtherModuleLesson(null);
   
         confirmMoveToOtherModuleModal.close();
       }
   }

   const onDragLessonsEnd = (source, destination) => {
      const lessonSourceIndex = source.index;
      const lessonDestinationIndex = destination.index;
  
      const moduleSourceIndex = modules.findIndex(
        (module) => module.id === +source.droppableId
      );

      const moduleDestinationIndex = modules.findIndex(
        (module) => module.id === +destination.droppableId
      );

      if(moduleSourceIndex !== moduleDestinationIndex) {
         setDraggedToOtherModuleLesson({ source, destination });
         confirmMoveToOtherModuleModal.open();
         return;
      }
  
      const newSourceLessons = [...modules[moduleSourceIndex].lessons];
      const newDestinationLessons =
        source.droppableId !== destination.droppableId
          ? [...modules[moduleDestinationIndex].lessons]
          : newSourceLessons;
  
      const [deletedLesson] = newSourceLessons.splice(lessonSourceIndex, 1);
      newDestinationLessons.splice(lessonDestinationIndex, 0, deletedLesson);
  
      const newModules = [...modules];
  
      newModules[moduleSourceIndex] = {
        ...modules[moduleSourceIndex],
        lessons: newSourceLessons,
      };

      newModules[moduleDestinationIndex] = {
        ...modules[moduleDestinationIndex],
        lessons: newDestinationLessons,
      };
  
      updateLessonsSequence(newModules[moduleSourceIndex]); 
      setModules(newModules);
   }

   const onDragEnd = (result) => {
      const { source, destination, type } = result;
   
      // Check if the draggable was dropped outside a droppable
      if (!destination) return;
      if (
         source.droppableId === destination.droppableId &&
         source.index === destination.index
      ) return;

      // Means that we are dragging modules
      if(type === 'group') {
         const reorderedModules = [...modules];
         const sourceIndex = source.index;
         const destinationIndex = destination.index;

         const [removedModule] = reorderedModules.splice(sourceIndex, 1); // remove element in which we wanted to change order
         reorderedModules.splice(destinationIndex, 0, removedModule); // add back the element with new index

         updateModulesSequence(reorderedModules); 
         return setModules(reorderedModules);
      }

      // Here we are dragging lessons
      onDragLessonsEnd(source, destination);
   };

    const cancelModuleCreation = () => {
      setElementName('');
      setIsAddElement(false);
    }

   const onSelectFilters = (e, lesson) => {
      e.stopPropagation();
      if (selectedLessons?.find(item => item?.id === lesson?.id)) setSelectedLessons(selectedLessons?.filter(item => item?.id !== lesson?.id))
      else setSelectedLessons([...selectedLessons, lesson])
  }

  const isLanguagesExists = !!COURSE_LANGUAGES_LIST?.length;

  const availableLanguages = COURSE_LANGUAGES_LIST?.filter(lang => 
      course?.availableLanguages?.includes(lang?.code)
  );

  const sortedLanguages = COURSE_LANGUAGES_LIST?.slice().sort((a, b) => {
      if (a?.code === course?.defaultLanguage) return -1;
      if (b?.code === course?.defaultLanguage) return 1;

      const aIsAvailable = availableLanguages?.some(availableLang => availableLang?.code === a?.code);
      const bIsAvailable = availableLanguages?.some(availableLang => availableLang?.code === b?.code);

      if (aIsAvailable === bIsAvailable) return 0;
      return aIsAvailable ? -1 : 1;
   });

   const scrollLanguages = (direction) => {
      if (langsRef.current) {
          const scrollAmount = 200; 
          langsRef.current.scrollBy({
              left: direction === 'left' ? -scrollAmount : scrollAmount,
              behavior: 'smooth', 
          });
      }
  };
   
   return (
      <DragDropContext onDragEnd={onDragEnd}>
         <div className={cn(styles.submenuBlock, !isLessonsSidebarOpen ? styles.closed_sidebar : '' )}>
            <div className={cn(styles.header)}>
               {isLanguagesExists && 
                  <div className={styles.langs_wrapper}>
                    <img onClick={() => scrollLanguages('left')} className={cn(styles.arrow, styles.arrow_left)} src={arrowLeft} alt="" />

                  <div ref={langsRef} className={styles.languages}>
                     <div  className={styles.langs_list}>
                        {sortedLanguages?.map(lang => 
                           <button 
                              onClick={()=> changeEditLanguage(lang)} 
                              className={cn(
                                    styles.lang_btn, editLanguage?.code === lang?.code ? styles.active_lang : '',
                                    availableLanguages?.some(availableLang => availableLang?.code === lang?.code) ? styles.available_lang : ''
                                 )} 
                              key={lang?.code}>
                              {lang?.displayed_code} 
                           </button>
                        )}
                     </div>
                  </div>
                  <img onClick={() => scrollLanguages('right')} className={cn(styles.arrow, styles.arrow_right)} src={arrowLeft} alt="" />

                  </div>
               }
               <div className={styles.header_bottom}>
               <div className={styles.header_left}>
                     <div className={cn(
                         styles.course_name, 
                         course?.name === defaultCourse?.name && editLanguage?.code !== course?.defaultLanguage ? styles.prevInput : ''
                     )} onDoubleClick={showChangeNameInput}>
                        <CustomTooltip 
                           limit={17} 
                           position={'bottom'}
                           extraMarginLeft
                           text={courseNewName.length ? courseNewName : course?.name} 
                           id={"course_name"}
                        />
                     </div>

                     {isRenameCourse && (
                        <input
                           className={styles.course_name_input}
                           value={courseNewName}
                           onBlur={courseNameChange}
                           onKeyDown={handleKeyDown}
                           onChange={(e) => setCourseNewName(e.target.value)}
                           autoFocus
                        />
                     )}
                  </div>

                  <div className={styles.header_right}>
                     <img src={hat} alt={""} />
                     <span>{amountOfTestsAndLessons}</span>
                     <div className={styles.dots} onClick={onToggle} ref={dotsRef}>
                        <img src={dots} alt={""} />
                        {loading && (
                           <div className={styles.loader_wrapper}>
                              <Loader size={'small'}/>
                           </div>
                        )}
                     </div>

                     {isCourseOptionOpen && (
                        <div ref={dropdownRef} className={styles.dropdown}>
                           <p onClick={onViewAsStudent}>{t("View as student")}</p>
                           <p onClick={onCopyCourseLink}>{t("Copy course link")}</p>
                           <p onClick={onRenameCourse}>{t("Rename")}</p>
                           <p onClick={(e)=> handleLinkClick(e, `/courses/settings/${params?.id}`)}>{t("Settings")}</p>
                          {!isNotDefaultLanguage && 
                             <p onClick={onConfirmOpen}>{t("Delete")}</p>
                          } 
                        </div>
                     )}
                  </div>
               </div>
            </div>

            {isDeleteMode && 
               <div className={cn(styles.delete_block, isLanguagesExists ? styles.languages_exist_extra_margin_delete : '')}>
                     <div className={styles.confirm_delete}>
                        <div className={styles.selected} onClick={closeIsDeleteMode}>
                              <span>Selected:</span>
                              <span>{selectedLessons?.length}</span>
                        </div>
                        <div className={styles.delete_btns}>
                           <button className={styles.cancel} onClick={()=> {
                              closeIsDeleteMode();
                              setSelectedLessons([]);
                           }}>{t("Cancel")}</button>
                           <button className={styles.delete} onClick={onOpenConfrimDeleteManyLessons}>{t("Delete")}</button>
                        </div>
                     </div>
               </div>
               }

            <div className={cn(styles.list, isDeleteMode ? styles.delete_mode  : '', isLanguagesExists ? styles.languages_exist_extra_margin : '')}>
                  <Droppable droppableId="droppableModules" type="group">
                     {(provided) => (
                        <div className={styles.width_full} {...provided.droppableProps} ref={provided.innerRef}>
                           {modules?.map((module, index) => (
                              <Draggable
                                 key={module.id}
                                 draggableId={`${module.id}`}
                                 index={index}
                                 isDragDisabled={isNotDefaultLanguage}
                              >
                                 {(provided, snapshot) => (
                                    <div
                                       className={`${styles.draggable_modules} ${snapshot.isDragging ? styles.modules_dragging : ''}`}
                                       ref={provided.innerRef}
                                       {...provided.draggableProps}
                                       {...provided.dragHandleProps}
                                    >
                                       <ModuleItem
                                          modules={modules}
                                          modulesLoading={loading}
                                          course={course}
                                          defaultModules={defaultModules}
                                          defaultCourseLanguage={defaultCourseLanguage}
                                          questions={questions}
                                          key={module.id}
                                          module={module}
                                          index={index}
                                          onClickModule={onClickModule}
                                          onClickElement={onClickElement}
                                          activeModule={activeModule}
                                          activeElement={activeElement}
                                          fetchData={fetchData}
                                          isModuleDragging={snapshot.isDragging}
                                          questionsDataFromInputs={questionsDataFromInputs}
                                          isDeleteMode={isDeleteMode}
                                          onSelectFilters={onSelectFilters}
                                          selectedLessons={selectedLessons}
                                          openIsDeleteMode={openIsDeleteMode}
                                          editLanguage={editLanguage}
                                          fetchDefaultModulesData={fetchDefaultModulesData}
                                          isNotDefaultLanguage={isNotDefaultLanguage}
                                       />
                                    </div>
                                 )}
                              </Draggable>
                           ))}
                           {provided.placeholder}
                        </div>
                     )}
                   </Droppable>

               {isAddElement && (
                  <Input
                     placeholder={"Module name"}
                     value={elementName}
                     onChangeValue={(e) => setElementName(e.target.value)}
                     onKeyDown={handleKeyDownCreateModule}
                     autoFocus
                  />
               )}

               {!isNotDefaultLanguage && 
                        <div className={styles.create_module_wrapper}>
                            <div
                               onClick={() => {
                                  setIsAddElement(!isAddElement);
                                  setElementName('');
                                  if (isAddElement) createModule(elementName);
                               }}
                               className={cn(styles.add, isAddElement ? styles.confirm_module_creation : '')}
                            >
          
                            {!isAddElement ? (
                                  <>
                                     <img src={plus} alt={""} />
                                     {t("Add module")}
                                  </>
                               ) : (
                                  <span>{t("Confirm")}</span>
                               )}
                            </div>
          
                            {isAddElement && 
                               <div
                                  className={cn(
                                        styles.add,
                                        styles.cancel_module_creation,
                                     )}
                                  onClick={cancelModuleCreation}
                               >
                                  {t("Cancel")}
                               </div>
                            }
                     </div>
               }
            </div>

            <ConfirmModal
               isRemove
               onConfirm={onCourseDelete}
               onClose={confirmModal.close}
               isOpen={confirmModal.isActive}
               title={t("Are you sure?")}
            />
            <ConfirmModal
               onConfirm={onMoveLessonToOtherModule}
               onClose={confirmMoveToOtherModuleModal.close}
               isOpen={confirmMoveToOtherModuleModal.isActive}
               title={t("Are you sure?")}
            />
            <ConfirmModal
               onConfirm={deleteManyLessons}
               onClose={confirmDeleteManyLessons.close}
               isOpen={confirmDeleteManyLessons.isActive}
               isRemove
               title={t("Are you sure?")}
            />
            <ConfirmModal
               confirmButtonText={'Confirm'}
               onClose={confirmRedirectModal.close}
               isOpen={confirmRedirectModal.isActive}
               onConfirm={redirectToPage}
               maxHeight={'310px'}
               title={t("Are you sure?")}
               subtitle={t('Uploading files will be stopped')}
            />

            <ChangeCourseLanguageModal
               confirmButtonText={t('Confirm')}
               onConfirm={()=> {
                  updateCourseName(editLanguage?.code || null, false)
               }}
               onClose={() => {
                  changeLanguageModal.close();
                  setClickedSaveCourseTitle(false);
               }}
               isOpen={changeLanguageModal.isActive}
               title={
                  `${clickedLanguageName} ${t("version of the course")}`
               }
               subtitle={`${t("Don't forget to change all the content of the course to the selected language. If the version is not activated in the course settings yet you can activate it any moment there")}.`}
               inputLabel={
                  `${t("Course title in")} ${clickedLanguageName}`
               }
               inputValue={courseNewName}
               setInputValue={setCourseNewName}
               isPrevInput={courseNewName === course?.name}
               isError={courseNewName === course?.name && clickedSaveCourseTitle}
            />
         </div>

      </DragDropContext>
   );
};

export default React.memo(SubmenuBlock);
