import React from "react";
import { Chart } from "react-google-charts";
import styles from "./styles.module.scss";

const options = {
   pieHole: 0.83,
   is3D: false,
   backgroundColor: "transparent",
   textStyle: {
      color: "#fff",
   },
   colors: ["#44DD65", "#0B88F8", "#3E3E41", "#FF5959"],
   pieSliceBorderColor: "transparent",
   legend: "none",
   pieSliceText: "none",
   chartArea: {
      width: "85%",
      height: "85%",
   },
   pieSliceTextStyle: null,
   tooltip: {
      text: "percentage",
      fontSize: 16,
   },
   // enableInteractivity: true,
   selectionMode: "none",
   animation: {
      startup: true,
      easing: "linear",
      duration: 1500,
   },
};

const PieChartStatistics = ({ chartData, score, textInside }) => {
   return (
      <div className={styles.chart_container}>
         <Chart
            chartType="PieChart"
            width="140px"
            height="160px"
            data={chartData}
            options={options}
         />

         {!!score && (
            <span className={styles.correct_percent}>
               <span>{(+score)?.toFixed() || "0"}</span>
               <span className={styles.gray}>{textInside}</span>
            </span>
         )}
         {!score && (
            <span className={styles.correct_percent}>
               <span>{"0"}</span>
               <span className={styles.gray}>{textInside}</span>
            </span>
         )}

      </div>
   );
};

export default PieChartStatistics;
